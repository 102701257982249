import { MyProfilePage } from "../views/my-profile";

const ProfileRoutes = [
  {
    path: "/profile",
    name: "Mi Perfil",
    Component: () => <MyProfilePage />,
  },
] as const;

export { ProfileRoutes };
