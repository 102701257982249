import { PageContainer, PageTitle } from "../../../../shared/components/styled";
import { EventsTable } from "../../components/events-table";
import { useEventsSocketCtx } from "../../context/events-socket-ctx";

export const DashboardPage = () => {
  const { events = [], isLoadingEvents } = useEventsSocketCtx();

  return (
    <PageContainer>
      <PageTitle>Dashboard</PageTitle>
      <EventsTable events={events} isLoading={isLoadingEvents} />
    </PageContainer>
  );
};
