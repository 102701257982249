import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useImperativeHandle, useEffect } from "react";
import { MyInput } from "../../../../shared/components/inputs";
import { useOperatorDetailsCtx } from "../../context/operator-details-ctx";
import * as S from "./OperatorInfoForm.styles";
import {
  UpdateOperatorFormProps,
  UpdateOperatorFormShape,
} from "../../schemas";
import {
  getDateWithInputFormat,
  getNumberCountryCode,
} from "../../../../shared/helpers";

import { If } from "../../../../shared/utilities/If";
import { PhoneInput } from "../../../../shared/components/inputs/PhoneInput";

type Keys = keyof UpdateOperatorFormProps;

export const OperatorInfoForm = React.forwardRef(function OperatorInfoForm(
  _,
  ref
) {
  const { isEditMode, operator } = useOperatorDetailsCtx();

  const methods = useForm<UpdateOperatorFormProps>({
    resolver: yupResolver(UpdateOperatorFormShape),
    mode: "onChange",
    defaultValues: {
      email: operator?.user_email,
      rfc: operator?.operator_rfc,
      curp: operator?.operator_curp,
      full_phone: operator?.user_phone,
      fullname: operator?.user_fullname,
      surname: operator?.user_surname ? operator?.user_surname : undefined,
      complete_name: `${operator?.user_fullname} ${operator?.user_surname}`,
      phone:
        operator?.user_phone && getNumberCountryCode(operator.user_phone).phone,
      country_code:
        operator?.user_phone && getNumberCountryCode(operator.user_phone).code,
      birthday: (operator?.operator_birthday &&
        getDateWithInputFormat(operator.operator_birthday)) as any,
    },
  });

  useImperativeHandle(ref, () => ({
    validateForm: async () => {
      const isValid = await methods.trigger();
      const values = methods.getValues();
      return { isValid, values };
    },
  }));

  useEffect(() => {
    isEditMode || methods.reset();
  }, [isEditMode, methods]);

  return (
    <S.OperatorInfoFormWrapper methods={methods} isEditMode={isEditMode}>
      <If showIf={!isEditMode}>
        <MyInput<Keys> name="complete_name" label="Nombre completo" readOnly />
        <MyInput<Keys>
          name="full_phone"
          label="Número de teléfono"
          readOnly={!isEditMode}
          autoFocus
        />
      </If>
      <If showIf={isEditMode}>
        <MyInput<Keys>
          name="fullname"
          label="Nombres"
          readOnly={!isEditMode}
          autoFocus
        />
        <MyInput<Keys>
          name="surname"
          label="Apellidos"
          readOnly={!isEditMode}
          autoFocus
        />
        <PhoneInput<Keys>
          autoFocus
          gap="1.5rem"
          name="phone"
          label="Teléfono"
          auto_focus_select
          auto_select={false}
          className="phone-input"
          readOnly={!isEditMode}
        />
      </If>
      <MyInput<Keys>
        name="birthday"
        label="Fecha de nacimiento"
        type="date"
        readOnly={!isEditMode}
        autoFocus
      />
      <MyInput<Keys>
        name="email"
        label="Correo electrónico"
        readOnly={!isEditMode}
        autoFocus
      />
      <If showIf={isEditMode}>
        <MyInput<Keys>
          name="curp"
          label="CURP"
          readOnly={!isEditMode}
          autoFocus
        />
        <MyInput<Keys>
          name="rfc"
          label="RFC"
          readOnly={!isEditMode}
          autoFocus
        />
      </If>
    </S.OperatorInfoFormWrapper>
  );
});
