import { BsStopwatch } from "react-icons/bs";
import { FiStar } from "react-icons/fi";
import { TbActivityHeartbeat } from "react-icons/tb";

export const fakeTimes = [
  {
    title: "Tiempo de atención promedio",
    value: "4:20",
    icon: <BsStopwatch />,
  },
  {
    title: "Tiempo de respuesta en emergencias médicas",
    value: "2:40",
    icon: <BsStopwatch />,
  },
  {
    title: "Tiempo de respuesta en emergencias de seguridad",
    value: "3:19",
    icon: <BsStopwatch />,
  },
  {
    title: "Eventos atendidos",
    value: "10",
    icon: <TbActivityHeartbeat />,
  },
  {
    title: "Evaluación",
    value: "4.9",
    icon: <FiStar />,
  },
];

export const fakeEvents = [
  { value: 10, color: "red" },
  { value: 20, color: "blue" },
  { value: 30, color: "green" },
  { value: 20, color: "yellow" },
  { value: 10, color: "orange" },
  { value: 10, color: "purple" },
];

export const eventColors = [
  "red",
  "blue",
  "green",
  "yellow",
  "orange",
  "purple",
];
