import * as T from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { TablePagination } from "../../../../shared/components/ui/table-title";
import { Supplier } from "../../../../shared/types";
import { TableWithPaginationProps } from "../../../../shared/types/paginate";
import { For } from "../../../../shared/utilities/For";
import { ProvidersTableRow } from "./ProvidersTableRow";

const titles = [
  "Contacto Principal",
  "RFC",
  "Número de teléfono",
  "Correo electrónico",
  "Acciones",
];

const columns = "repeat(4, 1fr) .5fr";

interface Props extends TableWithPaginationProps {
  providers: Supplier[];
}

export const ProvidersTable = ({
  providers,
  isLoading,
  pageCount,
  onPageChange,
}: Props) => {
  const pagination = (
    <TablePagination
      pageCount={pageCount}
      onPageChange={onPageChange}
      title="Listado de proveedores"
    />
  );

  return (
    <GlobalContentBox title={pagination} isLoading={isLoading}>
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={titles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>

        <T.TableBody gridColumns={columns}>
          <For
            each={providers}
            render={(provider) => <ProvidersTableRow provider={provider} />}
          />
        </T.TableBody>
      </T.Table>
    </GlobalContentBox>
  );
};
