import { Box } from "../../../../../shared/components/styled";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";

interface Props {
  title: string;
  isOpen: boolean;
  isLoading: boolean;
  serviceName: string;
  closeModal: () => void;
  onCancelEvent: () => void;
}

export const CancelEventModal = ({
  closeModal,
  onCancelEvent,
  isOpen,
  title,
  isLoading,
  serviceName,
}: Props) => {
  return (
    <BaseModal
      title={title}
      isOpen={isOpen}
      onRequestClose={closeModal}
      onlyCloseOnButton={isLoading}
    >
      <Box flex justifyCenter>
        ¿Desea cancelar la solicitud de {serviceName}?
      </Box>
      <PageButtons
        show
        isLoading={isLoading}
        onCancel={closeModal}
        cancelText="No, volver"
        onProceed={onCancelEvent}
        proceedText="Si, cancelar"
      />
    </BaseModal>
  );
};
