import styled from "styled-components";

export const ImageWrapper = styled.section`
  width: 3rem;
  height: 3rem;

  padding: 0.3rem;
  border-radius: 50%;
  background-color: #000;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.section`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;
