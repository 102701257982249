import { Coverage } from "../../modules/events/models/interfaces";
import {
  LatLngLiteral,
  Polygon,
} from "../../modules/providers/pages/provider-coverage";

export const validatePolygonPaths = (
  polygon: Polygon,
  coverages: Coverage[]
) => {
  const paths = polygon.getPath().getArray();
  const coords = paths.map((coord) => {
    const [insideCoverage] = coverages.filter((coverage) => {
      const center = {
        lat: +coverage.coverage_lat,
        lng: +coverage.coverage_lng,
      };
      const distance =
        window.google?.maps.geometry.spherical.computeDistanceBetween?.(
          coord,
          center
        );
      return distance <= coverage.coverage_radio;
    });
    return insideCoverage ? { lat: coord.lat(), lng: coord.lng() } : null;
  }) as unknown as LatLngLiteral[];

  const isNotValid = coords.some((coord) => coord === null);

  return { isNotValid, coords };
};
