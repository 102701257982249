import * as T from "../../../../shared/components/styled/Table";
import { getDateWithFormat } from "../../../../shared/helpers";
import { ClientAffiliate } from "../../services";

interface Props {
  affiliate: ClientAffiliate;
}

export const AdminClientAffiliatesRow = ({
  affiliate: { customer, product },
}: Props) => {
  return (
    <T.TableRow>
      <T.TableCell>{customer.name}</T.TableCell>
      <T.TableCell>{customer.email}</T.TableCell>
      <T.TableCell>{product.metadata.service_name}</T.TableCell>
      <T.TableCell>{getDateWithFormat(product.created)}</T.TableCell>
    </T.TableRow>
  );
};
