import styled from "styled-components";

export const StatsItemWrapper = styled.li`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.gray.secondary};
  }

  h4 {
    font-weight: 500;
    line-height: 1.4375rem;
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const StatsValue = styled.div`
  padding: 0.5rem;
  font-size: 2rem;
  margin-top: 0.5rem;
  font-weight: 700;
  border-radius: 0.4375rem;

  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => `${theme.colors.blue}0d`};
`;
