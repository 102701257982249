import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { setIsCreateAdminClientModal } from "../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../redux/store";
import {
  Box,
  PageContainer,
  PageTitle,
} from "../../../../shared/components/styled";
import { Button } from "../../../../shared/components/ui/button";
import { GetUsersParams } from "../../../../shared/interfaces";
import { AdminClientSearch } from "../../components/admin-client-search";
import { AdminClientsTable } from "../../components/admin-clients-table";
import { CreateAdminClientModal } from "../../components/modals/create-admin-client";
import { useGetAdminClients } from "../../services";

export const AdminClientListPage = () => {
  const dispatch = useAppDispatch();
  const [adminClientFilters, setAdminClientFilters] = useState<GetUsersParams>(
    {}
  );
  const {
    data: clients,
    isFetching,
    isLoading,
  } = useGetAdminClients(adminClientFilters);

  const onOpenModal = () => {
    dispatch(setIsCreateAdminClientModal(true));
  };

  const onPaginate = (page: number) => {
    setAdminClientFilters({
      ...adminClientFilters,
      page,
    });
  };

  return (
    <PageContainer>
      <Box flex justifyBetween alignCenter>
        <PageTitle>Clientes</PageTitle>
        <Button Icon={FaPlus} fontSize="1rem" onClick={onOpenModal}>
          Crear Cliente
        </Button>
      </Box>
      <AdminClientSearch
        isLoading={isFetching}
        setAdminClientFilters={setAdminClientFilters}
      />
      <AdminClientsTable
        isLoading={isLoading}
        onPageChange={onPaginate}
        clients={clients?.results || []}
        pageCount={clients?.total_pages || 0}
      />
      <CreateAdminClientModal />
    </PageContainer>
  );
};
