import styled from "styled-components";
import { ReportItem } from "../modals/report-details/ReportDetails.styles";

export const EventRecordsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  li {
    font-weight: 500;
    padding-bottom: 0.5rem;
    :not(:last-child) {
      border-bottom: 2px solid ${({ theme }) => theme.colors.gray.lightest};
    }
  }
`;

interface Props {
  if: any;
}

export const RecordItem = styled(ReportItem)<Props>`
  display: ${(props) => (props.if ? "flex" : "none")};
`;

/* ------------------------------------------- */

interface OperatorRecordProps {
  isEditMode: boolean;
}

export const OperatorRecordsContainer = styled.div<OperatorRecordProps>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  min-height: ${(props) => (props.isEditMode ? "13rem" : "auto")};
`;

export const OperatorRecordsField = styled.textarea`
  width: 100%;
  height: 100%;
  font-size: 1rem;

  // Input type checkbox
  :read-only {
    cursor: pointer;
  }
`;
