import styled from "styled-components";
import { SharedModalStyles } from "../../../styles";

interface Props {
  width?: string;
  height?: string;
}

export const BaseModalWrapper = styled.section<Props>`
  ${SharedModalStyles}
  ${(props) => props.width && `width: ${props.width};`}
    ${(props) => props.height && `height: ${props.height};`}
`;
