import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { HiOutlineKey } from "react-icons/hi";
import { MyInput } from "../../../../../shared/components/inputs";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { ReactSetter } from "../../../../../shared/types";
import { useProviderVehiclesCtx } from "../../../context/provider-vehicles-ctx";
import { Vehicle } from "../../../models";
import {
  ValidateVehicleCodeFormProps,
  ValidateVehicleCodeFormShape,
} from "../../../schemas";
import { useValidateVehicleCode } from "../../../services";
import * as S from "../create-vehicle/CreateVehicle.styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activeVehicle?: Vehicle;
}

type Keys = keyof ValidateVehicleCodeFormProps;

export const ValidateVehicleCodeModal = ({
  isOpen,
  activeVehicle,
  onClose,
}: Props) => {
  const { setActiveVehicle } = useProviderVehiclesCtx();
  const [validateCode, { isLoading: isValidating }] = useValidateVehicleCode();

  const methods = useForm<ValidateVehicleCodeFormProps>({
    resolver: yupResolver(ValidateVehicleCodeFormShape),
  });

  const onValidateCode = (data: ValidateVehicleCodeFormProps) => {
    if (!activeVehicle) return;
    validateCode({
      code: data.code,
      vehicle_id: activeVehicle.vehicle_id,
    })
      .unwrap()
      .then(() => {
        setActiveVehicle(undefined);
        onClose();
      });
  };

  const onAfterClose = () => {
    setActiveVehicle(undefined);
  };

  return (
    <BaseModal
      width="35rem"
      isOpen={isOpen}
      title="Validar código"
      onRequestClose={onClose}
      onAfterClose={onAfterClose}
      onlyCloseOnButton
    >
      <S.ValidateVehicleCodeWrapper
        methods={methods}
        onSubmit={methods.handleSubmit(onValidateCode)}
      >
        <HiOutlineKey />
        <p>
          Hemos enviado en código de 6 dígitos al número de teléfono registrado,
          favor ingresarlos para finalizar el proceso de registro.
        </p>
        <MyInput<Keys> name="code" placeholder="Ej: 123456" />
        <PageButtons
          show
          fullWidth
          isLoading={isValidating}
          proceedText="Validar"
        />
      </S.ValidateVehicleCodeWrapper>
    </BaseModal>
  );
};
