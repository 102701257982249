import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../../shared/types";

interface InitialState {
  isValidating: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

const initialState: InitialState = {
  user: null,
  isValidating: false,
  isAuthenticated: false,
};

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setUserImage: (state, action: PayloadAction<string>) => {
      if (!state.user) return;
      state.user.user_photography = action.payload;
    },
    setIsValidating: (state, { payload }: PayloadAction<boolean>) => {
      state.isValidating = payload;
    },
    setIsAuthenticated: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload;
    },
    setUserPhoto: (state, { payload }: PayloadAction<string>) => {
      if (!state.user) return;
      state.user.user_photography = payload;
    },
  },
});

export const {
  setUser,
  setUserPhoto,
  setUserImage,
  setIsValidating,
  setIsAuthenticated,
} = authReducer.actions;
