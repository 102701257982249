import styled, {
  FlattenInterpolation,
  FlattenSimpleInterpolation,
} from "styled-components";
import { motion } from "framer-motion";
import { NoVisibleScrollBar } from "../../styles";

interface GlobalProps {
  extraStyles?: FlattenSimpleInterpolation | FlattenInterpolation<any>;
}

interface SharedProps extends GlobalProps {
  columns?: string | number;
  gridColumns?: string;
}

/* ---------------------------------------------------------------- */

interface TableProps extends GlobalProps {
  maxHeight?: string;
}

export const Table = styled.div<TableProps>`
  display: flex;
  flex-direction: column;
  max-height: ${({ maxHeight }) => maxHeight || "calc(100vh - 10rem)"};

  p {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 700;
    font-size: 0.875rem;
  }

  ${({ extraStyles }) => extraStyles}
`;
/* ---------------------------------------------------------------- */

export const TableTitlesBox = styled.div<SharedProps>`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.lightest};
  padding-bottom: 1rem;

  ${({ gridColumns }) =>
    gridColumns && `grid-template-columns: ${gridColumns};`}
  ${({ extraStyles }) => extraStyles};
`;
/* ---------------------------------------------------------------- */

export const TableTitle = styled.p<GlobalProps>`
  ${({ extraStyles }) => extraStyles}
`;
/* ---------------------------------------------------------------- */

interface TableBodyProps extends SharedProps {
  padding?: string;
}

export const TableBody = styled(motion.ul).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
})<TableBodyProps>`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${({ padding }) => padding};
  ${NoVisibleScrollBar}

  > li {
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    ${({ gridColumns }) =>
      gridColumns && `grid-template-columns: ${gridColumns};`}
  }

  ${({ extraStyles }) => extraStyles}
`;
/* ---------------------------------------------------------------- */

interface TableRowsProps extends GlobalProps {
  relative?: boolean;
}

export const TableRow = styled.li<TableRowsProps>`
  display: grid;
  width: 100%;
  gap: 1rem;
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.lightest};
  }

  ${({ relative }) => relative && `position: relative;`}
  ${({ extraStyles }) => extraStyles}
`;
/* ---------------------------------------------------------------- */

interface CellProps extends GlobalProps {
  pointer?: boolean;
}

export const TableCell = styled.p<CellProps>`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  font-weight: 600 !important;
  word-break: break-word;

  ${({ pointer }) => pointer && `cursor: pointer;`}
  ${({ extraStyles }) => extraStyles}
`;
/* ---------------------------------------------------------------- */
