import { skipToken } from "@reduxjs/toolkit/dist/query";
import dayjs from "dayjs";
import { useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useGetUserDetails } from "../../../../redux/rtk-query";
import { Box } from "../../../../shared/components/styled";
import { MyLoader } from "../../../../shared/components/ui/Loader";
import { PageButtons } from "../../../../shared/components/ui/page-buttons";
import { UserSectionNav } from "../../../../shared/components/user-info/user-section-nav";
import { useToggle } from "../../../../shared/hooks";
import { Operator } from "../../../../shared/types";
import { OperatorDetailsCard } from "../../components/operator-details";
import { OperatorStatsList } from "../../components/operator-stats";
import { OperatorDetailsCtx } from "../../context/operator-details-ctx";
import { UpdateOperatorFormProps } from "../../schemas";
import {
  useGetOperatorStats,
  useGetOperatorScore,
  useUpdateOperator,
} from "../../services";
import { UpdateOperatorBody } from "../../services/requests.interfaces";

interface FormReturn {
  validateForm: () => Promise<{
    isValid: boolean;
    values: UpdateOperatorFormProps;
  }>;
}

export const OperatorDetailsPage = () => {
  const formRef = useRef<FormReturn>();
  const { userId } = useParams() as { userId: string };
  const [isEditMode, toggleEditMode] = useToggle();

  const { data: user, isFetching } = useGetUserDetails(userId);

  const operatorId = useMemo(
    () => (user?.role_name === "Operador" ? user.operator_id : skipToken),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.role_name]
  );

  const { data: score } = useGetOperatorScore(operatorId);
  const [updateOperator, { isLoading: isUpdating }] = useUpdateOperator();
  const { data: operatorEvents, isLoading: isGettingStats } =
    useGetOperatorStats(operatorId);

  const onUpdateOperator = async () => {
    if (!formRef.current || user?.role_name !== "Operador") return;
    const { isValid, values } = await formRef.current.validateForm();
    if (!isValid) return;

    const birthday = dayjs(values.birthday).toISOString();
    const phone = `${values.country_code}${values.phone}`;
    const body: UpdateOperatorBody = {
      birthday,
      rfc: (values.rfc ?? "").toUpperCase(),
      curp: (values.curp ?? "").toUpperCase(),
      operator_id: user.operator_id,
      user: {
        phone,
        email: values.email,
        surname: values.surname,
        fullname: values.fullname,
      },
    };
    updateOperator(body).unwrap().then(toggleEditMode);
  };

  if (isFetching) {
    return <MyLoader />;
  }

  return (
    <OperatorDetailsCtx.Provider
      value={{
        formRef,
        isEditMode,
        toggleEditMode,
        operator: user as Operator,
      }}
    >
      <Box flex flexColumn gap="1rem">
        <UserSectionNav
          title={user?.user_fullname || ""}
          actualSection="operador"
          toggleText="Operador activo"
        />
        <OperatorStatsList
          isLoading={isGettingStats}
          operatorEvents={operatorEvents}
        />
        <OperatorDetailsCard score={score} />
        <PageButtons
          show={isEditMode}
          isLoading={isUpdating}
          onCancel={toggleEditMode}
          onProceed={onUpdateOperator}
          proceedText="Guardar Cambios"
        />
      </Box>
    </OperatorDetailsCtx.Provider>
  );
};
