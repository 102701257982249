import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectServices } from "../../../../../redux/rtk-query";
import { MyInput } from "../../../../../shared/components/inputs";
import { MySelect } from "../../../../../shared/components/my-select";
import { Box } from "../../../../../shared/components/styled";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { colors } from "../../../../../shared/theme";
import {
  CreateSubscriptionFormProps,
  CreateSubscriptionFormShape,
} from "../../../schemas";
import { ClientProduct } from "../../../services";
import * as S from "./CreateSubscriptionModal.styles";

interface Props {
  isOpen: boolean;
  isLoading?: boolean;
  activePlan?: ClientProduct;

  onClose: () => void;
  onAfterClose?: () => void;
  onProceed?: (data: CreateSubscriptionFormProps) => void;
}

type Key = keyof CreateSubscriptionFormProps;

const interval = [
  {
    label: "Semanal",
    value: "week",
  },
  {
    label: "Mensual",
    value: "month",
  },
  {
    label: "Anual",
    value: "year",
  },
];

const excessOptions = [
  { value: true, label: "Si" },
  { value: false, label: "No" },
];

export const CreateSubscriptionModal = ({
  isOpen,
  isLoading,
  activePlan,
  onClose,
  onProceed,
  onAfterClose,
}: Props) => {
  const methods = useForm<CreateSubscriptionFormProps>({
    resolver: yupResolver(CreateSubscriptionFormShape),
    values: activePlan && {
      name: activePlan.name,
      price: activePlan.unit_amount,
      interval: activePlan.interval,
      serviceId: activePlan.metadata.serviceId,
      quantity: +activePlan.metadata.initial_quantity,
      description: activePlan.description,
      allow_promotion_codes:
        activePlan.metadata.allow_promotion_codes === "true",
    },
  });

  const { data: services = [] } = useSelector(selectServices);

  const proceedText = useMemo(
    () => (activePlan ? "Editar" : "Crear plan"),
    [activePlan]
  );

  const modalTitle = useMemo(
    () => (activePlan ? "Editar plan" : "Crear plan"),
    [activePlan]
  );

  const proceedBgColor = useMemo(
    () => (activePlan ? colors.orange : undefined),
    [activePlan]
  );

  const onSubmit = (data: CreateSubscriptionFormProps) => {
    onProceed?.(data);
  };

  const afterClose = () => {
    methods.reset({});
    onAfterClose?.();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      title={modalTitle}
      onRequestClose={onClose}
      onAfterClose={afterClose}
      width="50rem"
    >
      <S.CreateSubscriptionForm
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box grid columns="2" gap="2rem">
          <MyInput<Key>
            label="Nombre"
            name="name"
            placeholder="Nombre del plan"
            readOnly={!!activePlan}
            autoFocus={!activePlan}
          />
          <MySelect
            name="serviceId"
            label="Servicio"
            get="service_id"
            values={services}
            show="service_name"
            placeholder="Seleccione un servicio"
            disabled={!!activePlan}
          />
          <MyInput<Key>
            label="Cantidad"
            name="quantity"
            placeholder="Cantidad"
            autoFocus={!!activePlan}
          />
          <MyInput<Key>
            label="Precio"
            placeholder="$"
            name="price"
            autoFocus={!!activePlan}
            readOnly={!!activePlan}
          />
          <MySelect
            name="interval"
            label="Intervalo"
            get="value"
            show="label"
            values={interval}
            placeholder="Seleccione un intervalo"
            disabled={!!activePlan}
          />
          <MySelect
            name="allow_promotion_codes"
            label="Permitir códigos de promoción"
            values={excessOptions}
            get="value"
            show="label"
            placeholder="Seleccione una opción"
            disabled={!!activePlan}
          />
          <MyInput<Key>
            label="Descripción"
            name="description"
            placeholder="Descripción del plan"
            className="description-input"
            autoFocus={!!activePlan}
            textarea
            rows={4}
          />
        </Box>
        <PageButtons
          show
          fullWidth
          onCancel={onClose}
          isLoading={isLoading}
          proceedText={proceedText}
          proceedBgColor={proceedBgColor}
        />
      </S.CreateSubscriptionForm>
    </BaseModal>
  );
};
