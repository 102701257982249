import styled, { css } from "styled-components";

export const EventTimeCell = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > span {
    color: ${({ theme }) => theme.colors.blue};
    background-color: ${({ theme }) => `${theme.colors.blue}0d`};
    border-radius: 0.625rem;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
  }
`;

/* ---------------------------------- */

interface EventProps {
  color?: string;
  showSteps?: boolean;
}

export const EventStatus = styled.div<EventProps>`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ color }) => color};
  border-radius: 1.9375rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;

  gap: 0.3rem;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.2rem;
    transition: 0.2s ease-in-out all;
    transform: ${({ showSteps }) =>
      showSteps ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

/* ---------------------------------- */

export const EventRowStyles = css`
  position: relative;
  padding-bottom: 4rem;
`;

export const EventSteps = styled.ul`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  .separator-container {
    :last-child {
      display: none;
    }

    svg {
      color: ${({ theme }) => theme.colors.gray.secondary};
    }
  }
`;

interface EventStepProps {
  actualStep: number;
  index: number;
}

const ActiveStep = css`
  background-color: ${({ theme }) => theme.colors.blue};
  > svg {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const EventStep = styled.li<EventStepProps>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray.light};

  > svg {
    width: 60%;
    height: 60%;
    color: ${({ theme }) => theme.colors.gray.secondary};
  }

  ${({ actualStep, index }) => actualStep >= index && ActiveStep}
`;
/* ---------------------------------- */
