import styled from "styled-components";

export const ErrorsList = styled.ul`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface Props {
  isOpen: boolean;
}

export const ErrorItemAccordion = styled.li<Props>`
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  text-align: left;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #e0e0e0;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.2);

  gap: 0.5rem;
  display: flex;
  flex-direction: column;

  svg {
    font-size: 1.2rem;
    transition: 0.2s ease-in-out all;
    transform: ${({ isOpen }) => (isOpen ? "rotate(0deg)" : "rotate(-90deg)")};
  }
`;

export const ErrorItemList = styled.ul`
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;

  li {
    padding-left: 1.2rem;
  }
`;
