import { useSelector } from "react-redux";
import { useLayoutEffect, useState, useCallback, useEffect } from "react";
import io, { Socket } from "socket.io-client";

import { selectUser } from "../../auth/redux";
import { CallInList } from "../models";

enum SocketEvents {
  // NEW_TICKET = 'newTicket',
  PENDING_TICKETS = "pending-tickets",
}

enum SocketEmits {
  ATTEND_TICKET = "attendCallticket",
  PENDING_CALL_TICKETS = "pendingCallticket",
}

const SOCKET_CONNECTION_URL =
  process.env.REACT_APP_OPERATOR_CALL_LIST_SOCKET_URL || "";
const SOCKET_CONNECTION_PATH = "/tickets";

// !This socket hook only works with the operator session
export const useOperatorCallList = () => {
  const user = useSelector(selectUser);
  const [socket, setSocket] = useState<Socket>();
  const [numberOfTickets, setNumberOfTickets] = useState<number>();
  const [pendingTickets, setPendingTickets] = useState<CallInList[]>();

  const getTicketList = useCallback(() => {
    console.log("getting list of tickets");
    socket?.emit(SocketEmits.PENDING_CALL_TICKETS, (tickets: CallInList[]) => {
      setPendingTickets(tickets);
      console.log("tickets", tickets);
    });
  }, [socket]);

  const attendTicket = useCallback(
    (ticketNumber: number) => {
      const body = {
        operator: user?.user_fullname,
        ticketNumber,
      };
      socket?.emit(SocketEmits.ATTEND_TICKET, body);
      console.log("attending ticket", body);
    },
    [socket, user?.user_fullname]
  );

  useLayoutEffect(() => {
    if (user?.role_name !== "Operador") return;
    const authorization = `Bearer ${localStorage.getItem("access")}`;
    const socketCreated = io(SOCKET_CONNECTION_URL, {
      path: SOCKET_CONNECTION_PATH,
      transports: ["polling"],
      extraHeaders: { authorization },
      secure: true,
      reconnection: true,
      rejectUnauthorized: false,
    });
    setSocket(socketCreated);

    return () => {
      socketCreated.close();
      socketCreated.off(SocketEvents.PENDING_TICKETS);
    };
  }, [user?.role_name]);

  useEffect(() => {
    if (!socket) return;
    getTicketList();
    socket?.on(SocketEvents.PENDING_TICKETS, (tickets: number) => {
      console.log("getting number of tickets", tickets);
      setNumberOfTickets(tickets);
      getTicketList();
    });
  }, [getTicketList, socket]);

  return {
    attendTicket,
    pendingTickets,
    isLoading: !pendingTickets,
    numberOfTickets: numberOfTickets || pendingTickets?.length,
  };
};
