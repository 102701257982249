import * as S from "./ProfileImage.styles";

interface Props {
  src?: string;
  alt?: string;
  width?: string;
  height?: string;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

export const ProfileImage = ({
  alt,
  height,
  src,
  width,
  isLoading,
  className,
  onClick,
}: Props) => {
  if (isLoading) {
    return (
      <S.SkeletonBox
        width={width}
        height={height}
        onClick={onClick}
        className={className}
      />
    );
  }

  return (
    <S.ProfilePhoto
      src={src}
      alt={alt}
      width={width}
      height={height}
      onClick={onClick}
      className={className}
    />
  );
};
