import { RootState } from "../../store";

export const selectStatusText = (state: RootState) => state.ui.statusText;

export const selectStatusColor = (state: RootState) => state.ui.statusColor;

export const selectServiceText = (state: RootState) => state.ui.serviceText;

export const selectIsUpdatePasswordModal = (state: RootState) =>
  state.ui.isUpdatePasswordModal;

export const selectIsDeleteClientModal = (state: RootState) =>
  state.ui.isDeleteClientModal;

export const selectIsCreateClientModal = (state: RootState) =>
  state.ui.isCreateClientModal;

export const selectIsDeleteAffiliateModal = (state: RootState) =>
  state.ui.isDeleteAffiliateModal;

export const selectIsCreateProviderModal = (state: RootState) =>
  state.ui.isCreateProviderModal;

export const selectIsCreateOperatorModal = (state: RootState) =>
  state.ui.isCreateOperatorModal;

export const selectIsDeleteOperatorModal = (state: RootState) =>
  state.ui.isDeleteOperatorModal;

export const selectIsCreateAdminClientModal = (state: RootState) =>
  state.ui.isCreateAdminClientModal;

export const selectIsDeleteAdminClientModal = (state: RootState) =>
  state.ui.isDeleteAdminClientModal;

export const selectIsReportDetailsModal = (state: RootState) =>
  state.ui.isReportDetailsModal;

export const selectIsHistoricDetailsModal = (state: RootState) =>
  state.ui.isHistoricDetailsModal;
