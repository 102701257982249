import styled from "styled-components";
import { Form } from "../../../../shared/components/form";
import { Skeleton } from "../../../../shared/styles";
import { ReportSection } from "../modals/report-details/ReportDetails.styles";

export const CreateEventFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const EmergencyTypeContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.2rem;
    font-weight: 700;
  }
`;

export const EmergencyTypeList = styled.ul<{ isLoading?: boolean }>`
  gap: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 0.8fr 1fr 1.4fr 1fr;

  visibility: hidden;

  > li {
    visibility: visible;
    height: ${({ isLoading }) => isLoading && "2rem"};
    ${({ isLoading }) => isLoading && Skeleton};
  }

  :hover > :not(:hover) {
    opacity: ${({ isLoading }) => (isLoading ? 1 : 0.5)};
  }
`;

export const EmergencyTypeItem = styled(ReportSection)`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.blue : theme.colors.gray.lightest};
`;

export const KeyWordInputContainer = styled.div<{ hasError: boolean }>`
  gap: 1rem;
  /* display: grid; */
  /* grid-template-columns: 4fr 1fr; */
  display: flex;
  justify-content: flex-end;

  button {
    /* width: 100%; */
    width: 30%;
    margin-right: 0;
    /* height: 2.7rem; */
    align-self: ${({ hasError }) => (hasError ? "center" : "flex-end")};
  }
`;

export const EventDirectionWrapper = styled.div<{ placeId?: string }>`
  position: relative;

  .input-element,
  .input-container {
    ${({ placeId }) => placeId && `cursor: pointer`};
  }
`;

export const DirectionsList = styled.ul`
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  position: absolute;
  width: 100%;
  z-index: 2;
  margin-top: 0.2rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.gray.secondary};
  background-color: ${({ theme }) => theme.colors.white};

  :hover > :not(:hover) {
    opacity: 0.5;
  }
`;

export const DirectionItem = styled.li`
  transition: all 0.1s ease-in-out;
  gap: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 1rem;

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.gray.light};
  }

  span {
    font-size: 1.1rem;
    font-weight: 500;
  }

  :first-child {
    padding-top: 1rem;
  }

  :last-child {
    padding-bottom: 1rem;
  }
`;

export const EventAffiliateWrapper = styled.div<{ affiliateId?: string }>`
  display: flex;
  gap: 1.5rem;
  position: relative;

  .input-element,
  .input-container {
    ${({ affiliateId }) => affiliateId && `cursor: pointer`};
  }

  .input-container {
    height: 3rem;
  }
`;

export const EventAffiliateList = styled(DirectionsList)`
  top: 4.2rem;
  max-height: 40vh;
  overflow-y: auto;
`;

export const EventAffiliateItem = styled(DirectionItem)``;
