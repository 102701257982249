import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect } from "react";
import { useDebounce } from "react-haiku";
import { UseFormSetValue } from "react-hook-form/dist/types";
import { MdOutlinePlace } from "react-icons/md";
import { MyInput } from "../../../../shared/components/inputs";
import { useToggle } from "../../../../shared/hooks";
import { ReactSetter } from "../../../../shared/types";
import { For } from "../../../../shared/utilities/For";
import { If } from "../../../../shared/utilities/If";
import { CreateEventFormProps } from "../../schemas";
import { useGetPlaceAutocomplete } from "../../services";
import { Prediction } from "../../services/responses.interfaces";
import * as S from "./CreateEventForm.styles";

interface Props {
  direction: string;
  placeId?: string;
  setPlaceId: ReactSetter<string | undefined>;
  setDirection: UseFormSetValue<CreateEventFormProps>;
}

export const EventDirection = ({
  direction,
  setPlaceId,
  setDirection,
  placeId,
}: Props) => {
  const [showList, , setShowList] = useToggle(false);
  const debounceDirection = useDebounce(direction, 500);

  const { data: places = [] } = useGetPlaceAutocomplete(
    debounceDirection || skipToken
  );

  const onSelectPlace = (place: Prediction) => {
    return () => {
      setDirection("direction", place.description);
      setPlaceId(place.place_id);
      setShowList(false);
    };
  };

  const onClickInput = () => {
    if (!placeId) return;
    setPlaceId(undefined);
  };

  useEffect(() => {
    if (places.length > 0 && !placeId) {
      setShowList(true);
    }
  }, [debounceDirection, places, setShowList, placeId]);

  return (
    <S.EventDirectionWrapper placeId={placeId}>
      <MyInput
        name="direction"
        readOnly={!!placeId}
        onClickContainer={onClickInput}
        label="Dirección para envío de unidad"
      />
      <If showIf={showList}>
        <S.DirectionsList>
          <For
            each={places}
            render={(place) => (
              <S.DirectionItem onClick={onSelectPlace(place)}>
                <MdOutlinePlace />
                <span>{place.description}</span>
              </S.DirectionItem>
            )}
          />
        </S.DirectionsList>
      </If>
    </S.EventDirectionWrapper>
  );
};
