import styled from "styled-components";

interface IProps {
  iconRight?: boolean;
  iconLeft?: boolean;
  isFocused?: boolean;
  isValid?: boolean;
  readOnly?: boolean;
}

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  > label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.gray.primary};
    font-weight: 700;
  }

  > span {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.colors.red};
    font-weight: 500;
  }

  > span,
  label {
    margin-left: 0.5rem;
  }
`;

export const InputContainer = styled.div<IProps>`
  border: ${({ isFocused, theme }) =>
    isFocused
      ? `1px solid ${theme.colors.gray.secondary}`
      : `1px solid ${theme.colors.gray.lightest}`};
  background-color: ${({ isFocused, theme, readOnly }) =>
    isFocused
      ? readOnly
        ? theme.colors.gray.lightest
        : "transparent"
      : theme.colors.gray.lightest};

  padding-right: ${({ iconRight }) => (iconRight ? "2.2rem" : ".7rem")};
  padding-left: ${({ iconLeft }) => (iconLeft ? "2.2rem" : ".7rem")};

  position: relative;
  border-radius: 0.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;

  ${({ readOnly, theme }) =>
    readOnly &&
    `
        border: 1px solid ${theme.colors.gray.secondary};
    `}

  svg {
    position: absolute;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.gray.light};
    :first-child {
      left: 0.4rem;
    }
    :last-child {
      right: 0.4rem;
    }
  }

  .password-icon {
    cursor: pointer;
  }

  .input-error-icon {
    color: ${({ theme }) => theme.colors.red};
    font-size: 1rem;
    right: 0.6rem !important;
  }

  > input,
  textarea {
    color: ${({ theme, isValid }) => !isValid && theme.colors.red};
  }
`;

export const Input = styled.input`
  width: 100%;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray.secondary};
    font-size: 0.95rem;
  }
`;

interface InputProps {
  gap?: string;
}

export const PhoneInputContainer = styled.div<InputProps>`
  display: flex;
  gap: ${({ gap }) => gap || "0.5rem"};

  .input-container {
    height: 100%;
  }
`;
