import Tippy from "@tippyjs/react";
import { IoStatsChartOutline } from "react-icons/io5";
import { TypesOfEventsAttended } from "../../../../modules/operators/models";
import { eventColors } from "../../../data";
import { For } from "../../../utilities/For";
import { If } from "../../../utilities/If";
import { Box } from "../../styled";
import { GlobalContentBox } from "../../ui/global-content-box";
import * as S from "../stats-item/StatsItem.styles";
import * as SC from "./EventStats.styles";

interface Props {
  title: string;
  width?: string;
  isLoading?: boolean;
  events?: TypesOfEventsAttended[];
}

export const EventStats = ({ title, width, isLoading, events = [] }: Props) => {
  const isEmpty = events.length === 0;
  const totalEvents = events.reduce((acc, curr) => acc + curr.quantity, 0);

  const getPercentage = (quantity: number) => {
    return (quantity * 100) / totalEvents;
  };

  const getEventText = ({ quantity, name }: TypesOfEventsAttended) => {
    const text = quantity === 1 ? "evento" : "eventos";
    return `${name}: ${quantity} ${text}`;
  };

  return (
    <GlobalContentBox isLoading={isLoading}>
      <S.StatsItemWrapper>
        <Box flex flexColumn gap=".5rem">
          <IoStatsChartOutline />
          <h4>{title}</h4>
        </Box>
        <SC.EventStatsList width={width} length={events.length}>
          <If showIf={isEmpty}>
            <Tippy content="No hay eventos" placement="bottom">
              <SC.EventStatItem
                color="gray"
                value="100"
                whileHover={{ scale: 1.01 }}
              />
            </Tippy>
          </If>
          <If showIf={!isEmpty}>
            <For
              each={events}
              render={(event, index) => (
                <Tippy content={getEventText(event)} placement="bottom">
                  <SC.EventStatItem
                    color={eventColors[index] || "gray"}
                    value={getPercentage(event.quantity)}
                  />
                </Tippy>
              )}
            />
          </If>
        </SC.EventStatsList>
      </S.StatsItemWrapper>
    </GlobalContentBox>
  );
};
