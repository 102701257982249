import styled from "styled-components";
import { RecordItem } from "../../event-records/EventRecords.styles";

export const MedicalRecordContainer = styled.div`
  display: flex;
  flex-direction: column;

  > img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto;
  }
`;

export const MedicalRecordItem = styled(RecordItem)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
