import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { PageContainer, PageTitle } from "../../../../shared/components/styled";
import { MyLoader } from "../../../../shared/components/ui/Loader";
import { PageButtons } from "../../../../shared/components/ui/page-buttons";
import { useToggle } from "../../../../shared/hooks";
import { selectUser } from "../../../auth/redux";
import { ProfileDetailsCard } from "../../components/profile-details-card";
import { MyProfileCtx } from "../../context/my-profile-ctx";
import { UpdateProfileFormProps } from "../../schemas";
import {
  useGetProfile,
  useUpdateProfile,
} from "../../services/profile.service";

interface FormReturn {
  validateForm: () => Promise<{
    isValid: boolean;
    values: UpdateProfileFormProps;
  }>;
}

export const MyProfilePage = () => {
  const user = useSelector(selectUser);
  const formRef = useRef<FormReturn>(null);
  const [isEditMode, toggleEditMode] = useToggle();

  const { data: profile, isFetching } = useGetProfile();
  const [updateProfile, { isLoading: isUpdating }] = useUpdateProfile();

  const onSubmit = async () => {
    const { isValid, values } = (await formRef.current?.validateForm()) || {};
    if (!isValid || !user || !values) return;
    updateProfile({
      user: {
        phone: values.phone,
        fullname: values.fullname,
        surname: values.surname,
        email: values.email === user.user_email ? undefined : values.email,
      },
    })
      .unwrap()
      .then(toggleEditMode);
  };

  const value = useMemo(
    () => ({
      profile,
      formRef,
      isEditMode,
      toggleEditMode,
    }),
    [profile, formRef, isEditMode, toggleEditMode]
  );

  if (isFetching) {
    return <MyLoader />;
  }

  return (
    <MyProfileCtx.Provider value={value}>
      <PageContainer>
        <PageTitle>Mi Perfil</PageTitle>
        <ProfileDetailsCard />
        <PageButtons
          show={isEditMode}
          onProceed={onSubmit}
          isLoading={isUpdating}
          onCancel={toggleEditMode}
          proceedText="Guardar Cambios"
        />
      </PageContainer>
    </MyProfileCtx.Provider>
  );
};
