import { useCallback, useMemo, useState } from "react";
import {
  Box,
  PageContainer,
  PageTitle,
} from "../../../../shared/components/styled";
import { useToggle } from "../../../../shared/hooks";
import { ManagerCoveragesMap } from "../../components/manager-coverages";
import { ManagerCoveragesActions } from "../../components/manager-coverages-actions";
import {
  CreateCoverageBody,
  useCreateCoverages,
  useUpdateCoverage,
} from "../../services";

export const ManagerCoveragesPage = () => {
  const [isEditMode, toggleIsEditMode] = useToggle();
  const [isCreateMode, toggleIsCreateMode] = useToggle();
  const [circlesToCreate, setCirclesToCreate] = useState<google.maps.Circle[]>(
    []
  );
  const [coveragesToCreate, setCoveragesToCreate] = useState<
    CreateCoverageBody[]
  >([]);
  const [circlesCreated, setCirclesCreated] = useState<
    Record<string, google.maps.Circle>
  >({});

  const drawingMode = useMemo(
    () => (isCreateMode ? "circle" : undefined),
    [isCreateMode]
  );

  const [createCoverage, { isLoading: isCreating }] = useCreateCoverages();
  const [updateCoverage, { isLoading: isUpdating }] = useUpdateCoverage({
    fixedCacheKey: "update-coverage-mutation",
  });

  const addNewCircle = useCallback((circle: google.maps.Circle) => {
    setCirclesCreated((prev) => ({ ...prev, [circle.get("id")]: circle }));
  }, []);

  const deleteCircle = useCallback((id: string) => {
    setCirclesCreated((prev) => {
      const { [id]: toDelete, ...rest } = prev;
      return rest;
    });
  }, []);

  const onCanceled = useCallback(() => {
    circlesToCreate.forEach((circle) => circle.setMap(null));
    setCirclesToCreate([]);
    setCoveragesToCreate([]);
    toggleIsCreateMode();
  }, [circlesToCreate, toggleIsCreateMode]);

  const onCreateCoverages = useCallback(async () => {
    createCoverage(coveragesToCreate)
      .unwrap()
      .then(() => {
        window.location.reload();
      });
  }, [coveragesToCreate, createCoverage]);

  const onUpdatedCoverages = useCallback(async () => {
    const coveragesToUpdate = Object.entries(circlesCreated);
    const dataToUpdate = coveragesToUpdate.map(([id, circle]) => ({
      id,
      lat: circle.getCenter()?.lat().toString() as string,
      lng: circle.getCenter()?.lng().toString() as string,
      radio: +circle.getRadius().toFixed(0),
    }));
    const promises = dataToUpdate.map((data) => updateCoverage(data).unwrap());
    await Promise.all(promises).then(() => {
      toggleIsEditMode();
    });
  }, [circlesCreated, updateCoverage, toggleIsEditMode]);

  const onCircleComplete = useCallback(
    (circle: google.maps.Circle) => {
      const lat = circle.getCenter()?.lat();
      const lng = circle.getCenter()?.lng();
      const radio = circle.getRadius();

      if (lat && lng && radio) {
        setCoveragesToCreate([
          ...coveragesToCreate,
          {
            lat: lat.toString(),
            lng: lng.toString(),
            radio: Number(radio.toFixed(0)),
          },
        ]);
        setCirclesToCreate([...circlesToCreate, circle]);
      }
    },
    [circlesToCreate, coveragesToCreate]
  );

  return (
    <PageContainer>
      <Box flex justifyBetween alignCenter>
        <PageTitle>Coberturas</PageTitle>
        <ManagerCoveragesActions
          isCreateMode={isCreateMode}
          isCreating={isCreating}
          isEditMode={isEditMode}
          isUpdating={isUpdating}
          onCanceled={onCanceled}
          toggleIsEditMode={toggleIsEditMode}
          onCreateCoverages={onCreateCoverages}
          onUpdatedCoverages={onUpdatedCoverages}
          toggleIsCreateMode={toggleIsCreateMode}
        />
      </Box>
      <ManagerCoveragesMap
        isEditMode={isEditMode}
        drawingMode={drawingMode}
        onCircleLoaded={addNewCircle}
        onDeleteCircle={deleteCircle}
        onCircleComplete={onCircleComplete}
      />
    </PageContainer>
  );
};
