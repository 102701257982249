import { css } from "styled-components";

export const AffiliateImageStyles = css`
  width: 2rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

export const AffiliatePhoneIconStyles = css`
  svg {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray.primary};
  }
`;
