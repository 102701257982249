import { useCallback, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { PageContainer, PageTitle } from "../../../../shared/components/styled";
import { Box } from "../../../../shared/components/styled/Box";
import { Button } from "../../../../shared/components/ui/button";
import { useToggle } from "../../../../shared/hooks";
import { Client } from "../../../../shared/types";
import { selectUser } from "../../../auth/redux";
import { CreateSubscriptionModal } from "../../components/modals/create-subscription";
import { SubscriptionsTable } from "../../components/subscriptions-table";
import { CreateSubscriptionFormProps } from "../../schemas";
import {
  UpdateSubscriptionBody,
  useCreateClientSubscription,
  useGetClientSubscriptions,
  useUpdateClientSubscription,
} from "../../services";
import { ClientProduct } from "../../services/responses.interfaces";

export const SubscriptionsListPage = () => {
  const { client_id: clientId } = useSelector(selectUser) as Client;
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [activePlan, setActivePlan] = useState<ClientProduct>();

  const [updatePlan, { isLoading: isUpdating }] = useUpdateClientSubscription();
  const [createPlan, { isLoading: isCreating }] = useCreateClientSubscription();
  const { data: subscriptions = [], isLoading } =
    useGetClientSubscriptions(clientId);

  const onWatchDetails = useCallback(
    (plan: ClientProduct) => {
      setActivePlan(plan);
      toggleIsModalOpen();
    },
    [toggleIsModalOpen]
  );

  const onCreatePlan = useCallback(
    (data: CreateSubscriptionFormProps) => {
      createPlan({
        ...data,
        price: data.price * 100,
      })
        .unwrap()
        .then(toggleIsModalOpen);
    },
    [createPlan, toggleIsModalOpen]
  );

  const onUpdatePlan = useCallback(
    (data: CreateSubscriptionFormProps, subscriptionId: string) => {
      const body: UpdateSubscriptionBody = {
        subscriptionId,
        initial_quantity: data.quantity,
        price: data.price * 100,
        description: data.description,
      };
      updatePlan(body).unwrap().then(toggleIsModalOpen);
    },
    [updatePlan, toggleIsModalOpen]
  );

  const onProceedForm = useCallback(
    (data: CreateSubscriptionFormProps) => {
      activePlan ? onUpdatePlan(data, activePlan.id) : onCreatePlan(data);
    },
    [activePlan, onCreatePlan, onUpdatePlan]
  );

  const afterCloseModal = useCallback(() => {
    setActivePlan(undefined);
  }, []);

  return (
    <PageContainer>
      <Box flex justifyBetween alignCenter>
        <PageTitle>Suscripciones</PageTitle>
        <Button Icon={FaPlus} fontSize="1rem" onClick={toggleIsModalOpen}>
          Crear plan
        </Button>
      </Box>
      <SubscriptionsTable
        isLoading={isLoading}
        subscriptions={subscriptions}
        onWatchDetails={onWatchDetails}
      />
      <CreateSubscriptionModal
        isOpen={isModalOpen}
        activePlan={activePlan}
        onProceed={onProceedForm}
        onClose={toggleIsModalOpen}
        onAfterClose={afterCloseModal}
        isLoading={isCreating || isUpdating}
      />
    </PageContainer>
  );
};
