import Tippy from "@tippyjs/react";
import { LatLngLiteral } from "../../../modules/providers/pages/provider-coverage";
import { imgUrl } from "../navbar";
import * as S from "./Marker.styles";
import OverlayView from "./Overlay";
import { useState } from "react";

interface CustomMarkerProps {
  position: LatLngLiteral;
  name?: string;
  img?: string | null;
  map?: google.maps.Map;
  children?: React.ReactNode;
  address?: string;
}

export default function CustomMarker({
  position,
  map,
  img,
  name,
  children,
  address,
}: CustomMarkerProps) {
  const [showAddress, setShowAddress] = useState(false);
  if (!map) return null;

  const handleShowAddress = () => {
    setShowAddress(true);
  };

  const handleHideAddress = () => {
    setShowAddress(false);
  };

  const content = children ?? (
    <Tippy content={name} delay={[100, 0]}>
      <div
        style={{ position: "relative" }}
        onClick={handleShowAddress}
        onMouseLeave={handleHideAddress}
      >
        <S.ImageWrapper>
          <S.ImageContainer>
            <img src={img ?? imgUrl} alt="Usuario" />
          </S.ImageContainer>
        </S.ImageWrapper>
        {showAddress && address && (
          <h2
            style={{
              width: "10rem",
              position: "absolute",
              top: "3.2rem",
              left: "50%",
              transform: "translateX(-50%)",
              color: "#fff",
              fontSize: "1rem",
              fontWeight: 400,
              textAlign: "center",
              whiteSpace: "normal",
              backgroundColor: "#00000090",
              padding: ".3rem .4rem",
              borderRadius: ".4rem",
            }}
          >
            {address}
          </h2>
        )}
      </div>
    </Tippy>
  );

  return (
    <OverlayView map={map} position={position}>
      {content}
    </OverlayView>
  );
}
