import styled from "styled-components";

export const CallingModalWrapper = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  > svg {
    font-size: 10rem;
    color: ${({ theme }) => theme.colors.gray.primary};
  }
`;
