import React, { createContext } from "react";

import { Affiliate } from "../../../../shared/types";

export interface IAffiliateDetailsCtx {
  affiliate?: Affiliate;
  toggleEditMode: () => void;
  isEditMode: boolean;
  formRef: React.RefObject<any>;
}

export const AffiliateDetailsCtx = createContext({} as IAffiliateDetailsCtx);

export const useAffiliateDetailsCtx = () =>
  React.useContext(AffiliateDetailsCtx);
