import * as Yup from "yup";
import {
  birthDateRequired,
  curpRequired,
  emailRequired,
  phoneNoCodeRequired,
  rfcRequired,
  stringOptional,
  stringRequired,
  validPassword,
} from "../../../shared/schemas";

export const OperatorSearchFormShape = Yup.object({
  fullname: stringOptional,
  birthday: stringOptional,
  phone: stringOptional,
});

export type OperatorSearchFormProps = Yup.InferType<
  typeof OperatorSearchFormShape
>;

/* ------------------------------------------------------------------------------ */

export const CreateOperatorFormShape = Yup.object({
  country_code: stringRequired,
  operator_rfc: rfcRequired,
  operator_curp: curpRequired,
  operator_email: emailRequired,
  operator_password: validPassword,
  operator_fullname: stringRequired,
  operator_surname: stringRequired,
  operator_birthday: birthDateRequired,
  operator_phone: phoneNoCodeRequired,
  operator_confirmPassword: stringRequired.oneOf(
    [Yup.ref("operator_password"), null],
    "Las contraseñas no coinciden"
  ),
});

export type CreateOperatorFormProps = Yup.InferType<
  typeof CreateOperatorFormShape
>;

/* ------------------------------------------------------------------------------ */

export const UpdateOperatorFormShape = Yup.object({
  fullname: stringRequired,
  surname: stringRequired,
  birthday: birthDateRequired,
  email: emailRequired,
  rfc: rfcRequired,
  curp: curpRequired,
  complete_name: stringRequired,
  full_phone: stringOptional,
  country_code: stringRequired,
  phone: phoneNoCodeRequired,
});

export type UpdateOperatorFormProps = Yup.InferType<
  typeof UpdateOperatorFormShape
>;

/* ------------------------------------------------------------------------------ */
