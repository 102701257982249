import styled from "styled-components";

export const NavbarCallsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > svg {
    font-size: 1.2rem;
    font-size: 1.5rem;
    cursor: pointer;
    box-sizing: content-box;
    color: ${(props) => props.theme.colors.blue};
    background-color: ${(props) => props.theme.colors.white};
    padding: 0.5rem;
    border-radius: 50%;
  }
`;

export const NumberOfTickets = styled.span`
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.red};
  padding: 0.2rem 0.5rem;
  border-radius: 50%;

  position: absolute;
  right: -0.5rem;
  bottom: -0.6rem;

  cursor: pointer;
`;
