import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import Tippy from "@tippyjs/react";
import * as T from "../../../../shared/components/styled";
import { ActionsCellBox } from "../../../../shared/components/styled";
import { getPhoneWithFormat } from "../../../../shared/helpers";
import { ActionsCellStyles } from "../../../../shared/styles";
import { If } from "../../../../shared/utilities/If";
import { useProviderVehiclesCtx } from "../../context/provider-vehicles-ctx/ProviderVehiclesCtx";
import type { Vehicle } from "../../models";
import * as S from "./VehiclesTable.styles";
import { useToggle } from "../../../../shared/hooks";
import { useReSendVehicleCode } from "../../services";

interface Props {
  vehicle: Vehicle;
}

export const VehiclesTableRow = ({ vehicle }: Props) => {
  const actionsRef = useRef(null);
  const [actionsOpen, toggleActionsOpen, setActionsOpen] = useToggle();

  const { setActiveVehicle, openModal, openCodeModal, openDeleteVehicleModal } =
    useProviderVehiclesCtx();
  useClickOutside(actionsRef, () => setActionsOpen(false));

  const [sendCode] = useReSendVehicleCode();

  const onEditVehicle = () => {
    openModal();
    setActionsOpen(false);
    setActiveVehicle(vehicle);
  };

  const onValidateCode = () => {
    if (vehicle.vehicle_code_is_validated) return;
    setActiveVehicle(vehicle);
    sendCode(vehicle.vehicle_id);
    openCodeModal();
  };

  const onDeleteVehicle = () => {
    setActionsOpen(false);
    setActiveVehicle(vehicle);
    openDeleteVehicleModal();
  };

  return (
    <T.TableRow extraStyles={S.TableRowCss} relative>
      <T.TableCell pointer onClick={onEditVehicle}>
        {vehicle.vehicle_license_plate}
      </T.TableCell>
      <T.TableCell>{vehicle.vehicle_model_year}</T.TableCell>
      <T.TableCell>{vehicle.vehicle_color}</T.TableCell>
      <T.TableCell>{vehicle.vehicle_brand}</T.TableCell>
      <T.TableCell>{vehicle.vehicle_model_type}</T.TableCell>
      <T.TableCell>{vehicle.type_name}</T.TableCell>
      <T.TableCell>{getPhoneWithFormat(vehicle.vehicle_phone)}</T.TableCell>
      <T.TableCell>{vehicle.service_description}</T.TableCell>
      <T.TableCell>
        <Tippy
          content="Enviar código de verificación"
          disabled={vehicle.vehicle_code_is_validated}
          placement="bottom"
        >
          <S.StatusBox
            is_validated={vehicle.vehicle_code_is_validated}
            onClick={onValidateCode}
          >
            {vehicle.vehicle_code_is_validated ? "Activo" : "Inactivo"}
          </S.StatusBox>
        </Tippy>
      </T.TableCell>
      <If showIf={!actionsOpen}>
        <T.TableCell
          onClick={toggleActionsOpen}
          extraStyles={ActionsCellStyles}
          style={{ padding: "1rem" }}
        >
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={actionsOpen}>
        <T.TableCell
          extraStyles={ActionsCellStyles}
          style={{ padding: "1rem" }}
        >
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={actionsOpen}>
        <ActionsCellBox ref={actionsRef}>
          <div onClick={onEditVehicle}>Editar</div>
          <div onClick={onDeleteVehicle}>Eliminar</div>
        </ActionsCellBox>
      </If>
    </T.TableRow>
  );
};
