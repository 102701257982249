import React from "react";
import { Rating } from "react-simple-star-rating";
import { If } from "../../../utilities/If";
import { imgUrl } from "../../navbar";
import { Box } from "../../styled";
import * as S from "./UserDetailsCard.styles";

interface UserDetailsCardProps {
  score?: number;
  editTitle?: string;
  hideScore?: boolean;
  profilePhoto?: string | null;
  isLoadingImg?: boolean;
  canUpdatePhoto?: boolean;
  toggleEditMode?: () => void;
  onChangePhoto?: (file: File) => void;
}

export const UserDetailsCard = ({
  onChangePhoto,
  toggleEditMode,
  editTitle,
  isLoadingImg,
  canUpdatePhoto,
  score,
  hideScore,
  profilePhoto,
}: UserDetailsCardProps) => {
  const fileInput = React.useRef<HTMLInputElement>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !onChangePhoto) return;
    onChangePhoto(file);
  };

  const onOpenFileInput = () => {
    if (!canUpdatePhoto) return;
    fileInput.current?.click();
  };

  const onToggle = () => {
    toggleEditMode?.();
  };

  return (
    <S.UserDetailsCardWrapper>
      <Box flex flexColumn alignCenter gap=".5rem">
        <S.UserImage
          src={profilePhoto || imgUrl}
          isLoading={isLoadingImg}
          onClick={onOpenFileInput}
          canUpdatePhoto={canUpdatePhoto}
        />
        <input
          type="file"
          ref={fileInput}
          style={{ display: "none" }}
          accept="image/jpeg, image/png"
          onChange={onFileChange}
        />
        <If showIf={!hideScore}>
          <div className="rating-container">
            <Rating readonly initialValue={score} />
          </div>
        </If>
      </Box>
      <If showIf={!!editTitle}>
        <p className="edit-user" onClick={onToggle}>
          {editTitle}
        </p>
      </If>
    </S.UserDetailsCardWrapper>
  );
};
