import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import { AiOutlinePlus } from "react-icons/ai";
import { IoRemoveOutline } from "react-icons/io5";
import { Box } from "../../../../shared/components/styled";
import { useToggle } from "../../../../shared/hooks";
import { If } from "../../../../shared/utilities/If";
import { useEventsSocketCtx } from "../../../events/context/events-socket-ctx";
import { ActiveEventsList } from "./ActiveEventsList";
import * as S from "./OperatorActiveEvents.styles";

export const OperatorActiveEvents = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [showEvents, toggleShowEvents, setShowEvents] = useToggle();

  const { events = [], isLoadingEvents } = useEventsSocketCtx();

  useClickOutside(componentRef, () => {
    showEvents && setShowEvents(false);
  });

  const Icon = showEvents ? IoRemoveOutline : AiOutlinePlus;

  return (
    <S.OperatorActiveEventsWrapper ref={componentRef} showEvents={showEvents}>
      <S.EventsDescription onClick={toggleShowEvents}>
        <p>Eventos activos</p>
        <Box flex alignCenter gap=".5rem">
          <If showIf={!showEvents}>
            <span className="events-length">
              {isLoadingEvents ? "?" : events?.length}
            </span>
          </If>
          <Icon className="toggle-list-icon" />
        </Box>
      </S.EventsDescription>
      {showEvents && <ActiveEventsList events={events} />}
    </S.OperatorActiveEventsWrapper>
  );
};
