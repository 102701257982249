import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectServices } from "../../../../../redux/rtk-query";
import { MyInput } from "../../../../../shared/components/inputs";
import { PhoneInput } from "../../../../../shared/components/inputs/PhoneInput";
import { MySelect } from "../../../../../shared/components/my-select";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { getNumberCountryCode } from "../../../../../shared/helpers";
import { Vehicle } from "../../../models";
import {
  CreateVehicleFormProps,
  CreateVehicleFormShape,
} from "../../../schemas";
import { selectVehicleTypes } from "../../../services";
import * as S from "./CreateVehicle.styles";

type Keys = keyof CreateVehicleFormProps;

interface Props {
  isLoading: boolean;
  activeVehicle?: Vehicle;

  onClose: () => void;
  onSubmit: (data: CreateVehicleFormProps) => void;
}

export const VehicleDataForm = ({
  activeVehicle,
  isLoading,
  onClose,
  onSubmit,
}: Props) => {
  const methods = useForm<CreateVehicleFormProps>({
    resolver: yupResolver(CreateVehicleFormShape),
    defaultValues: {
      typeId: activeVehicle?.type_id,
      brand: activeVehicle?.vehicle_brand,
      color: activeVehicle?.vehicle_color,
      serviceId: activeVehicle?.service_id,
      model_type: activeVehicle?.vehicle_model_type,
      model_year: activeVehicle?.vehicle_model_year,
      license_plate: activeVehicle?.vehicle_license_plate,
      phone:
        activeVehicle?.vehicle_phone &&
        getNumberCountryCode(activeVehicle.vehicle_phone).phone,
      country_code:
        activeVehicle?.vehicle_phone &&
        getNumberCountryCode(activeVehicle.vehicle_phone).code,
    },
  });

  const vehicleTypes = useSelector(selectVehicleTypes);
  const { data: services = [] } = useSelector(selectServices);
  const proceedText = useMemo(
    () => (activeVehicle ? "Actualizar" : "Crear"),
    [activeVehicle]
  );

  return (
    <S.CreateVehicleModalWrapper
      methods={methods}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <MyInput<Keys>
        name="license_plate"
        label="Placa"
        placeholder="Ej: 123-ABC"
      />
      <PhoneInput<Keys>
        name="phone"
        label="Teléfono"
        auto_select={!activeVehicle}
      />
      <MyInput<Keys> name="brand" label="Marca" placeholder="Ej: Toyota" />
      <MyInput<Keys>
        name="model_type"
        label="Tipo de modelo"
        placeholder="Ej: Corolla"
      />
      <MyInput<Keys> name="model_year" label="Modelo" placeholder="Ej: 2019" />
      <MyInput<Keys> name="color" label="Color" placeholder="Ej: Rojo" />
      <MySelect
        name="typeId"
        get="type_id"
        show="type_name"
        values={vehicleTypes}
        label="Tipo de Vehículo"
      />
      <MySelect
        name="serviceId"
        label="Servicio"
        get="service_id"
        values={services}
        show={["service_name", "service_description"]}
      />
      <PageButtons
        show
        fullWidth
        onCancel={onClose}
        isLoading={isLoading}
        proceedText={proceedText}
      />
    </S.CreateVehicleModalWrapper>
  );
};
