import dayjs from "dayjs";
import { useMemo } from "react";
import { FaStar } from "react-icons/fa";
import { Box } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { getDateWithFormat } from "../../../../shared/helpers";
import { EventDetails } from "../../models/interfaces";
import * as S from "./EventRecords.styles";

interface Props {
  event: EventDetails;
}

export const AutomaticEventRecords = ({ event }: Props) => {
  const totalEventTime = useMemo(() => {
    if (!event.created_at || !event.event_end_time) return;
    return dayjs(event?.event_end_time).diff(event?.created_at, "minutes");
  }, [event.created_at, event.event_end_time]);

  return (
    <GlobalContentBox
      title="Registros automáticos"
      style={{ minHeight: "10rem", width: "100%" }}
    >
      <Box flex flexColumn gap="1rem">
        <S.RecordItem if={event.created_at}>
          <h4>Hora de recepción de la alerta:</h4>
          <span>{getDateWithFormat(event.created_at, "h:mm A")}</span>
        </S.RecordItem>
        <S.RecordItem if={event.estimated_time_of_arrival}>
          <h4>Tiempo estimado de arribo: </h4>
          <span>{event.estimated_time_of_arrival}</span>
        </S.RecordItem>
        <S.RecordItem if={event.arrival_time_at_destination}>
          <h4>Hora de llegada al destino: </h4>
          <span>
            {getDateWithFormat(event.arrival_time_at_destination!, "h:mm A")}
          </span>
        </S.RecordItem>
        <S.RecordItem if={event.arrival_time_at_emergency_destination}>
          <h4>Hora de llegada al destino de emergencia: </h4>
          <span>
            {getDateWithFormat(
              event.arrival_time_at_emergency_destination!,
              "h:mm A"
            )}
          </span>
        </S.RecordItem>
        <S.RecordItem if={event.hour_of_attention}>
          <h4>Hora de atención: </h4>
          <span>{getDateWithFormat(event.hour_of_attention!, "h:mm A")}</span>
        </S.RecordItem>
        <S.RecordItem if={event.event_end_time}>
          <h4>Hora de cierre del evento: </h4>
          <span>{getDateWithFormat(event.event_end_time!, "h:mm A")}</span>
        </S.RecordItem>
        <S.RecordItem if={event.score_to_worker}>
          <h4>Evaluación del evento: </h4>
          <span>
            {event.score_to_worker} <FaStar />
          </span>
        </S.RecordItem>
        <S.RecordItem if={totalEventTime}>
          <h4>Tiempo total de atención: </h4>
          <span>{totalEventTime} min</span>
        </S.RecordItem>
      </Box>
    </GlobalContentBox>
  );
};
