import { toast } from "react-toastify";
import {
  generalRoutes,
  globalApi,
  usersRoutes,
} from "../../../redux/rtk-query";
import store from "../../../redux/store";
import { handleServiceRequest } from "../../../shared/helpers";
import { ErrorResponse } from "../../../shared/interfaces";
import { User } from "../../../shared/types";
import { setUser, setUserPhoto } from "../../auth/redux";
import {
  UpdatePasswordBody,
  UpdateProfileBody,
  UploadUserPhotoBody,
} from "./requests.interfaces";

export const profileService = globalApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<User, void>({
      query: () => generalRoutes.meRT(),
      transformResponse: (response: { data: User }) => response.data,
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const onSuccess = (user: User) => {
          dispatch(setUser(user));
        };
        handleServiceRequest(queryFulfilled, {
          errorMessage:
            "Ha ocurrido un error al traer su perfil, intente nuevamente",
          onSuccess,
        });
      },
      providesTags: ["profile"],
    }),
    updatePassword: builder.mutation<void, UpdatePasswordBody>({
      query: ({ id, ...body }) => ({
        body,
        method: "PUT",
        url: generalRoutes.updatePasswordRT(id),
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (message === "password invalid") {
            toast.error("La contraseña actual no es correcta");
          } else {
            toast.error(
              "Ha ocurrido un error al actualizar la contraseña, intente nuevamente"
            );
          }
        };
        handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Contraseña actualizada correctamente",
        });
      },
    }),
    updateProfile: builder.mutation<void, UpdateProfileBody>({
      query: (body) => {
        const { user } = store.getState().auth;
        let url = "";

        if (user?.role_name === "Gerente") {
          url = usersRoutes.updateManagerRT(user.manager_id);
        } else if (user?.role_name === "Empresa") {
          url = usersRoutes.updateCompanyRT(user.company_id);
        } else if (user?.role_name === "Proveedor") {
          url = usersRoutes.updateProviderRT(user.supplier_id);
        } else if (user?.role_name === "Operador") {
          url = usersRoutes.updateOperatorRT(user.operator_id);
        } else if (user?.role_name === "Cliente") {
          url = usersRoutes.updateAdminClientRT(user.client_id);
        }

        return {
          body,
          method: "PUT",
          url,
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (message.includes("e-mail")) {
            toast.warn("El correo electrónico ya está en uso");
          } else {
            toast.error(
              "Ha ocurrido un error al crear el proveedor, intente nuevamente"
            );
          }
        };
        handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Perfil actualizado correctamente",
        });
      },
      invalidatesTags: (_, error) => (error ? [] : ["profile"]),
    }),
    uploadProfilePhoto: builder.mutation<string, UploadUserPhotoBody>({
      query: ({ file }) => {
        const user = store.getState().auth.user as User;
        const formData = new FormData();
        formData.append("file", file);

        return {
          method: "POST",
          body: formData,
          url: usersRoutes.uploadUserPhotoRT(user.user_id),
        };
      },
      transformResponse: (response: { data: string }) => response.data,
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const onSuccess = (photo: string) => {
          dispatch(setUserPhoto(photo));
        };
        handleServiceRequest(queryFulfilled, {
          onSuccess,
          errorMessage: "Error al subir la foto de perfil, intente nuevamente",
        });
      },
      invalidatesTags: (_, error) => (error ? [] : ["profile"]),
    }),
  }),
});

export const {
  useGetProfileQuery: useGetProfile,
  useLazyGetProfileQuery: useLazyGetProfile,
  useUpdateProfileMutation: useUpdateProfile,
  useUpdatePasswordMutation: useUpdatePassword,
  useUploadProfilePhotoMutation: useUploadProfilePhoto,
} = profileService;
