import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

interface Props {
  active?: boolean;
}

export const SelectedItemStyles = css`
  background-color: ${({ theme }) => `${theme.colors.blue}0d`};
  svg,
  p {
    color: ${({ theme }) => theme.colors.blue};
  }

  .component-svg > path {
    stroke: ${({ theme }) => theme.colors.blue};
  }
`;

export const SidebarItemWrapper = styled(NavLink)<Props>`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 0.8rem 0.5rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  transition: all 0.2s ease-in-out;

  svg {
    color: ${({ theme }) => theme.colors.gray.light};
    width: 1.5rem;
    height: 1.5rem;
  }

  .component-svg {
    position: relative;
    bottom: 0.2rem;

    > path {
      transition: all 0.2s ease-in-out;
      stroke: ${({ theme }) => theme.colors.gray.light};
    }
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 600;
  }

  svg,
  p {
    transition: all 0.2s ease-in-out;
  }

  ${({ active }) => active && SelectedItemStyles}

  :hover {
    ${SelectedItemStyles}
  }
`;
