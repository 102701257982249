import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetUserScore } from "../../../../redux/rtk-query";
import { Box } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { UserDetailsCard } from "../../../../shared/components/user-info/user-details-card";
import { useProviderDetailsCtx } from "../../context/provider-details-ctx";
import { ProviderInfoForm } from "../provider-info-form";

export const ProviderDetailsCard = () => {
  const { provider, isEditMode, formRef, toggleEditMode } =
    useProviderDetailsCtx();

  const { data: score } = useGetUserScore(provider || skipToken);

  const editTitle =
    provider?.user_status !== "Suspendido" ? "Editar proveedor" : undefined;

  return (
    <GlobalContentBox style={{ position: "relative" }}>
      <Box flex flexColumn gap="1rem">
        <UserDetailsCard
          score={score}
          toggleEditMode={toggleEditMode}
          profilePhoto={provider?.user_photography}
          editTitle={isEditMode ? undefined : editTitle}
        />
        <ProviderInfoForm ref={formRef} />
      </Box>
      {/* <If showIf={isEditMode && !isOptionsOpen}>
                <OpenOptionsButton
                    extraStyles={ActionsCellStyles}
                    onClick={toggleOptionsOpen}
                >
                    <div />
                    <div />
                    <div />
                </OpenOptionsButton>
            </If>
            <If showIf={isEditMode && isOptionsOpen}>
                <OpenOptionsButton extraStyles={ActionsCellStyles}>
                    <div />
                    <div />
                    <div />
                </OpenOptionsButton>
            </If>
            <If showIf={isEditMode && isOptionsOpen}>
                <ActionsCellBox
                    ref={actionsRef}
                    top="2rem"
                    right="1.5rem"
                >
                    <div>Eliminar usuario</div>
                </ActionsCellBox>
            </If> */}
    </GlobalContentBox>
  );
};
