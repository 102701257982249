import { useEffect } from "react";
import { toast } from "react-toastify";
import { findLast } from "lodash";
import { useEventsSocketCtx } from "../../../../modules/events/context/events-socket-ctx";

export const NotificationSoundContainer = () => {
  const { events = [], isLoadingEvents } = useEventsSocketCtx();

  useEffect(() => {
    if (events?.length > 0 && !isLoadingEvents) {
      const newEvent = findLast(events, { status: "Nuevo" });
      if (newEvent) {
        toast.success("Tienes un nuevo evento", { position: "top-right" });
        window.addEventListener("click", () => {}, { once: true });
        const audio = new Audio(
          "https://anngeltest-bucketdata.s3.amazonaws.com/notification.wav"
        );
        audio.play();
      }
    }
  }, [events, isLoadingEvents]);

  return <></>;
};
