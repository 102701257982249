import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCoverage, useGetUserDetails } from "../../../../redux/rtk-query";
import { CoverageSelector } from "../../../../shared/components/coverage-selector";
import { Box } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { MyLoader } from "../../../../shared/components/ui/Loader";
import { PageButtons } from "../../../../shared/components/ui/page-buttons";
import { UserSectionNav } from "../../../../shared/components/user-info/user-section-nav";
import { useToggle } from "../../../../shared/hooks";
import { Supplier } from "../../../../shared/types";
import { If } from "../../../../shared/utilities/If";
import { ProviderCoverageMap } from "../../components/provider-coverage-map";
import { ProviderDetailsCard } from "../../components/provider-details-card";
import { ProviderWorkers } from "../../components/provider-workers";
import { ProviderDetailsCtx } from "../../context/provider-details-ctx";
import { UpdateProviderFormProps } from "../../schemas";
import {
  UpdateProviderBody,
  useGetProviderZones,
  useUpdateProvider,
} from "../../services";

interface ChildReturn {
  validateForm: () => Promise<{
    isValid: boolean;
    values: UpdateProviderFormProps;
  }>;
}

export const ProviderDetailsPage = () => {
  const formRef = useRef<ChildReturn>();
  const { userId } = useParams() as { userId: string };
  const [isEditMode, toggleEditMode] = useToggle();
  const [map, setMap] = useState<google.maps.Map>();

  const { data: coverages = [] } = useGetCoverage();
  const { data: user, isFetching } = useGetUserDetails(userId);
  const [updateProvider, { isLoading: isUpdating }] = useUpdateProvider();
  const { data: zones = [] } = useGetProviderZones(
    user?.role_name === "Proveedor" ? user.supplier_id : skipToken
  );

  const onMapLoaded = useCallback((mapLoaded: google.maps.Map) => {
    setMap(mapLoaded);
  }, []);

  const onUpdate = async () => {
    if (!formRef.current || user?.role_name !== "Proveedor") return;
    const { isValid, values } = await formRef.current.validateForm();
    if (!isValid) return;

    const phone = `${values.country_code}${values.phone}`;
    const body: UpdateProviderBody = {
      supplier_id: user.supplier_id,
      rfc: (values.rfc ?? "").toUpperCase(),
      user: {
        phone,
        email: values.email,
        fullname: values.fullname,
      },
    };
    updateProvider(body).unwrap().then(toggleEditMode);
  };

  const value = useMemo(
    () => ({
      formRef,
      isEditMode,
      provider: user as Supplier,
      toggleEditMode,
    }),
    [formRef, isEditMode, toggleEditMode, user]
  );

  if (isFetching) {
    return <MyLoader />;
  }

  return (
    <ProviderDetailsCtx.Provider value={value}>
      <Box flex flexColumn gap="1rem">
        <UserSectionNav
          title={user?.user_fullname || ""}
          actualSection={user?.user_fullname || ""}
          toggleText="Proveedor activo"
          backTo="Proveedores"
        />
        <ProviderDetailsCard />
        <PageButtons
          show={isEditMode}
          onProceed={onUpdate}
          isLoading={isUpdating}
          onCancel={toggleEditMode}
          proceedText="Guardar Cambios"
        />
        <If showIf={!isEditMode}>
          <Box childWidth="100%" gap="1.5rem" height="40rem" flex>
            <ProviderWorkers provider={user as Supplier} />
            <GlobalContentBox
              title="Cobertura"
              contentStyle={{
                padding: ".5rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <CoverageSelector coverages={coverages} map={map} />
              <ProviderCoverageMap
                zoom={10}
                zones={zones}
                canDelete={false}
                coverages={coverages}
                onMapLoaded={onMapLoaded}
              />
            </GlobalContentBox>
          </Box>
        </If>
      </Box>
    </ProviderDetailsCtx.Provider>
  );
};
