import { useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useGetUserDetails } from "../../../../redux/rtk-query";
import { PageContainer } from "../../../../shared/components/styled";
import { MyLoader } from "../../../../shared/components/ui/Loader";
import { PageButtons } from "../../../../shared/components/ui/page-buttons";
import { UserSectionNav } from "../../../../shared/components/user-info/user-section-nav";
import { useToggle } from "../../../../shared/hooks";
import { Client } from "../../../../shared/types";
import { AdminClientDetailsCard } from "../../components/admin-client-details-card";
import { DeleteAdminClientModal } from "../../components/modals/delete-admin-client";
import {
  AdminClientDetailsCtx,
  IAdminClientDetailsCtx,
} from "../../context/admin-client-details-ctx";
import { UpdateAdminClientFormProps } from "../../schemas";
import { UpdateAdminClientBody, useUpdateAdminClient } from "../../services";

interface FormReturn {
  validateForm: () => Promise<{
    isValid: boolean;
    values: UpdateAdminClientFormProps;
  }>;
}

export const AdminClientDetailsPage = () => {
  const formRef = useRef<FormReturn>();
  const { userId = "" } = useParams<{ userId: string }>();
  const { data: user, isFetching } = useGetUserDetails(userId);
  const [isEditMode, toggleEditMode] = useToggle();
  const [updateClient, { isLoading: isUpdating }] = useUpdateAdminClient();

  const onUpdate = async () => {
    if (!formRef.current || user?.role_name !== "Cliente") return;
    const { isValid, values } = await formRef.current.validateForm();
    if (!isValid) return;

    const phone = `${values.country_code}${values.phone}`;
    const body: UpdateAdminClientBody = {
      client_id: user.client_id,
      rfc: (values.rfc ?? "").toUpperCase(),
      user: {
        phone,
        email: values.email,
        fullname: values.fullname,
      },
    };
    updateClient(body).unwrap().then(toggleEditMode);
  };

  const value = useMemo<IAdminClientDetailsCtx>(
    () => ({
      formRef,
      isEditMode,
      adminClient: user as Client,
      toggleEditMode,
    }),
    [formRef, isEditMode, user, toggleEditMode]
  );

  if (isFetching) return <MyLoader />;

  return (
    <AdminClientDetailsCtx.Provider value={value}>
      <PageContainer gap="1rem">
        <UserSectionNav
          title={user?.user_fullname || ""}
          actualSection={user?.user_fullname || ""}
          toggleText="Usuario Activo"
          backTo="Clientes"
        />
        <AdminClientDetailsCard />
        <PageButtons
          show={isEditMode}
          onProceed={onUpdate}
          isLoading={isUpdating}
          onCancel={toggleEditMode}
          proceedText="Guardar Cambios"
        />
        <DeleteAdminClientModal />
      </PageContainer>
    </AdminClientDetailsCtx.Provider>
  );
};
