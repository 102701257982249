import { css } from "styled-components";
import { appear, SkeletonAnimation } from "./animations";

export const AppearAnimation = css`
  animation: 0.2s ${appear} ease-in;
`;

export const SearchComponentStyles = css<{ hasErrors?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  > *:not(button) {
    align-self: flex-start;
  }

  > button {
    width: 100%;
    height: 2.7rem;

    align-self: ${({ hasErrors }) => (hasErrors ? "center" : "flex-end")};
  }
`;

export const CustomScrollBar = css`
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray.light};
    border-radius: 0.1rem;
  }
`;

export const NoVisibleScrollBar = css`
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }
`;

export const ActionsCellStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  cursor: pointer;

  > div {
    width: 0.3rem;
    height: 0.3rem;
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const SharedModalStyles = css`
  ${NoVisibleScrollBar}
  overflow: auto;
  max-height: 90vh;
  position: relative;
  width: 25rem;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;

  > h2 {
    font-size: 1rem;
    font-weight: 700;
  }
`;

export const Skeleton = css`
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${SkeletonAnimation} 2s infinite;
`;

export const Capitalize = css`
  text-transform: capitalize;
`;
