import { UserRole } from "../../../shared/enums";
import { AffiliateDetailsPage } from "../pages/affiliate-details";

const AffiliatesRoutes = [
  {
    path: "/affiliate/:userId",
    name: "Detalles de afiliado",
    Component: () => <AffiliateDetailsPage />,
    rolesAllowed: ["Gerente", "Empresa", "Cliente"] as UserRole[],
  },
] as const;

export { AffiliatesRoutes };
