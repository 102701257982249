import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useImperativeHandle, useMemo } from "react";
import { MyInput } from "../../../../shared/components/inputs";
import { useMyProfileCtx } from "../../context/my-profile-ctx";
import * as S from "./ProfileInfoForm.styles";
import {
  UpdateProfileFormProps,
  UpdateProfileFormShape,
  UpdateProfileFormShape2,
} from "../../schemas";

import { If } from "../../../../shared/utilities/If";
import { useSelector } from "react-redux";
import { selectUser } from "../../../auth/redux";
import { Client } from "../../../../shared/types";

type Key = keyof UpdateProfileFormProps;

export const ProfileInfoForm = React.forwardRef(function ProfileInfoForm(
  _,
  ref
) {
  const { isEditMode, profile } = useMyProfileCtx();
  const client = useSelector(selectUser) as Client;

  const hasSurname = useMemo(
    () => profile?.role_name === "Gerente" || profile?.role_name === "Operador",
    [profile]
  );

  const resolver = hasSurname
    ? yupResolver(UpdateProfileFormShape2)
    : yupResolver(UpdateProfileFormShape);

  const methods = useForm<UpdateProfileFormProps>({
    resolver,
    mode: "onChange",
    defaultValues: {
      phone: profile?.user_phone,
      email: profile?.user_email,
      fullname: profile?.user_fullname,
      surname: profile?.user_surname ? profile?.user_surname : undefined,
      complete_name: `${profile?.user_fullname} ${
        profile?.user_surname ? profile?.user_surname : ""
      }`,
    },
  });

  useImperativeHandle(ref, () => ({
    validateForm: async () => {
      const isValid = await methods.trigger();
      const values = methods.getValues();
      return { isValid, values };
    },
  }));

  useEffect(() => {
    isEditMode || methods.reset();
  }, [isEditMode, methods]);

  return (
    <S.ProfileInfoFormWrapper methods={methods}>
      <If showIf={!isEditMode}>
        <MyInput<Key> name="complete_name" label="Nombre completo" readOnly />
      </If>
      <If showIf={isEditMode}>
        <MyInput<Key>
          name="fullname"
          label="Nombres"
          readOnly={!isEditMode}
          autoFocus
        />
        <If showIf={hasSurname}>
          <MyInput<Key>
            name="surname"
            label="Apellidos"
            readOnly={!isEditMode}
            autoFocus
          />
        </If>
      </If>
      <MyInput<Key>
        name="phone"
        label="Número de teléfono"
        readOnly={!isEditMode}
        autoFocus
      />
      <MyInput<Key>
        name="email"
        label="Correo electrónico"
        readOnly={!isEditMode}
        autoFocus
      />
      <MyInput<"clientCode">
        name="clientCode"
        label="Codigo de franquicia"
        readOnly={true}
        autoFocus
        value={client?.client_code}
      />
    </S.ProfileInfoFormWrapper>
  );
});
