import React from "react";
import * as T from "../../../../shared/components/styled";
import { getPhoneWithFormat } from "../../../../shared/helpers";
import { StatusBox } from "../../../users/components/clients-table/ClientTable.styles";
import { WorkerWithVehicle } from "../../services";

interface Props {
  worker: WorkerWithVehicle;
}

export const ProviderWorkersRow = ({ worker }: Props) => {
  return (
    <T.TableRow>
      <T.TableCell>
        {worker.user_fullname} {worker.user_surname}
      </T.TableCell>
      <T.TableCell>{getPhoneWithFormat(worker.user_phone)}</T.TableCell>
      <T.TableCell>{worker.user_email}</T.TableCell>
      <T.TableCell>{worker.vehicle_license_plate ?? "NA"}</T.TableCell>
      <T.TableCell>
        <StatusBox status={worker.user_status}>{worker.user_status}</StatusBox>
      </T.TableCell>
      <T.TableCell>{worker.user_availability}</T.TableCell>
    </T.TableRow>
  );
};
