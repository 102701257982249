import * as T from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { TablePagination } from "../../../../shared/components/ui/table-title";
import { Affiliate } from "../../../../shared/types";
import { TableWithPaginationProps } from "../../../../shared/types/paginate";
import { For } from "../../../../shared/utilities/For";
import { ClientAffiliatesTableRow } from "./ClientAffiliatesTableRow";

const titles = [
  "Foto",
  "Nombre",
  "Fecha",
  "Teléfono",
  "Correo",
  "Estado",
  "Acciones",
];

const gridColumns = ".3fr 1.5fr repeat(4,1fr) .5fr";
const columnsWithoutActions = ".3fr 1.5fr repeat(4,1fr)";

interface Props extends TableWithPaginationProps {
  title?: string;
  isCallable?: boolean;
  affiliates: Affiliate[];
  canHandleActions?: boolean;
  onCallAffiliate?: (affiliate: Affiliate) => void;
}

export const ClientAffiliatesTable = ({
  canHandleActions = true,
  ...props
}: Props) => {
  const columns = canHandleActions ? gridColumns : columnsWithoutActions;
  const realTitles = canHandleActions ? titles : titles.slice(0, -1);

  const pagination = (
    <TablePagination
      pageCount={props.pageCount}
      onPageChange={props.onPageChange}
      title={props.title || "Usuarios asociados"}
    />
  );

  return (
    <GlobalContentBox title={pagination} isLoading={props.isLoading}>
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={realTitles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>
        <T.TableBody gridColumns={columns}>
          <For
            each={props.affiliates}
            render={(affiliate) => (
              <ClientAffiliatesTableRow
                affiliate={affiliate}
                isCallable={props.isCallable}
                canHandleActions={canHandleActions}
                onCallAffiliate={props.onCallAffiliate}
              />
            )}
          />
        </T.TableBody>
      </T.Table>
    </GlobalContentBox>
  );
};
