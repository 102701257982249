import React from "react";
import { IconType } from "react-icons";
import Tippy from "@tippyjs/react";
import { StandardButtonProps, StandardButtonStyles } from "./Button.styles";
import { If } from "../../../utilities/If";
import { Loader } from "../Loader/Loader";
import { Box } from "../../styled/Box";
import "tippy.js/dist/tippy.css";

interface Props extends StandardButtonProps {
  Icon?: IconType;
  onClick?: () => void;

  text?: string;
  tippy?: string;
  style?: React.CSSProperties;
  iconPosition?: "left" | "right";
  children?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  loaderSize?: string;
  loaderColor?: string;
  animate?: boolean;
  className?: string;
  tippyDelay?: number | [number, number];
  [key: string]: unknown;
}

const variants = {
  tap: { scale: 0.98 },
  hover: { scale: 1.02 },
};

export const Button = (props: Props) => {
  const {
    Icon,
    children,
    disabled,
    loading,
    animate,
    loaderColor,
    loaderSize,
    text,
    tippy,
    tippyDelay,
    ...rest
  } = props;

  const isDisabled = disabled || loading;
  const whiteTap = animate && !loading && !disabled ? "tap" : undefined;
  const whiteHover = animate && !loading && !disabled ? "hover" : undefined;
  const flexDirection = props.iconPosition === "left" ? "row" : "row-reverse";

  const buttonContent = (
    <StandardButtonStyles
      variants={variants}
      disabled={isDisabled}
      whileTap={whiteTap}
      whileHover={whiteHover}
      {...rest}
    >
      <If showIf={loading}>
        <Loader color={loaderColor} size={loaderSize || props.fontSize} />
      </If>
      <If showIf={!loading}>
        <Box
          flex
          alignCenter
          justifyCenter
          gap=".5rem"
          flexDirection={flexDirection}
        >
          {Icon && <Icon />}
          {children || text}
        </Box>
      </If>
    </StandardButtonStyles>
  );

  return !tippy ? (
    buttonContent
  ) : (
    <Tippy content={tippy} delay={tippyDelay ?? [0, 0]} placement="bottom">
      {buttonContent}
    </Tippy>
  );
};
