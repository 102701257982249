import { toast } from "react-toastify";
import { companyRoutes } from "../../../redux/rtk-query";
import { companyAxiosService } from "../../../services/company_services";

export const downloadTemplate = async () => {
  try {
    const { data } = await companyAxiosService.get(
      companyRoutes.downloadTemplateRT(),
      {
        responseType: "blob",
      }
    );
    const file = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  } catch (error) {
    console.log(error);
    toast.error("Error al intentar descargar el archivo, intente nuevamente");
  }
};
