import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { UserRole } from "../../../../shared/enums";
import { selectUser } from "../../redux";

interface Props {
  isRoute?: boolean;
  roles?: UserRole[];
  children: React.ReactNode;
}

export const PrivateComponent = ({ roles, children, isRoute }: Props) => {
  const user = useSelector(selectUser);

  if (!user) {
    return null;
  }

  if (!roles) {
    return <>{children}</>;
  }

  if (!roles.includes(user.role_name)) {
    return isRoute ? <Navigate to="/not-allowed" replace /> : null;
  }

  return <>{children}</>;
};
