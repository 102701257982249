import { useForm } from "react-hook-form";
import { MyInput } from "../../../../shared/components/inputs";
import { Button } from "../../../../shared/components/ui/button";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { ReactSetter } from "../../../../shared/types";
import { EventsQueryFilters } from "../../services/requests.interfaces";
import * as S from "./ReportsSearch.styles";

interface Props {
  isLoading: boolean;
  setFilters: ReactSetter<EventsQueryFilters>;
}

interface FormValues {
  ticket: string;
  fullname: string;
}

type Key = keyof FormValues;

export const ReportsSearch = ({ isLoading, setFilters }: Props) => {
  const methods = useForm<FormValues>();

  const onSubmit = (values: FormValues) => {
    setFilters(values);
  };

  return (
    <GlobalContentBox title="Búsqueda">
      <S.ReportsSearchForm
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MyInput<Key>
          name="ticket"
          label="Ticket"
          placeholder="Ej: AB-6387909"
        />
        <MyInput<Key>
          name="fullname"
          label="Usuario"
          placeholder="Ej: Juan Perez"
        />
        <Button animate loading={isLoading}>
          Buscar
        </Button>
      </S.ReportsSearchForm>
    </GlobalContentBox>
  );
};
