import styled from "styled-components";
import { ProfileImage } from "../../ui/profile-image";

export const UserDetailsCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .edit-user {
    margin: 0 auto;
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 700;
    cursor: pointer;
    font-size: 0.9rem;
  }
  .rating-container {
    background-color: ${({ theme }) => theme.colors.gray.lightest};
    border-radius: 1.25rem;
    padding: 0.25rem 0.625rem;
  }

  .star-svg {
    width: 1.4375rem;
    height: 1.4375rem;
    :not(:last-child) {
      margin-right: 0.8rem;
    }
  }
`;

export const UserImage = styled(ProfileImage)<{ canUpdatePhoto?: boolean }>`
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  margin: 0 auto;
  cursor: ${({ canUpdatePhoto }) => (canUpdatePhoto ? "pointer" : "default")};
`;
