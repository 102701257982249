import { createContext, useContext } from "react";
import { User } from "../../../../shared/types";

interface IMyProfileCtx {
  profile?: User;
  isEditMode: boolean;
  formRef: React.RefObject<any>;
  toggleEditMode: () => void;
}

export const MyProfileCtx = createContext({} as IMyProfileCtx);

export const useMyProfileCtx = () => useContext(MyProfileCtx);
