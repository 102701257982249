import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  selectIsCreateOperatorModal,
  setIsCreateOperatorModal,
} from "../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../redux/store";
import {
  Box,
  PageContainer,
  PageTitle,
} from "../../../../shared/components/styled";
import { Button } from "../../../../shared/components/ui/button";
import { GetUsersParams } from "../../../../shared/interfaces";
import { PrivateComponent } from "../../../auth/components/private-component";
import { CreateOperatorModal } from "../../components/modals/create-operator-modal";
import { OperatorsSearch } from "../../components/operators-search";
import { OperatorsTable } from "../../components/operators-table";
import { useGetOperators } from "../../services";

export const OperatorsListPage = () => {
  const dispatch = useAppDispatch();
  const [operatorFilters, setOperatorFilters] = useState<GetUsersParams>({});
  const {
    data: operators,
    isLoading,
    isFetching,
  } = useGetOperators(operatorFilters);

  const onOpenModal = () => {
    dispatch(setIsCreateOperatorModal(true));
  };

  const onPaginate = (page: number) => {
    setOperatorFilters({
      ...operatorFilters,
      page,
    });
  };

  return (
    <PageContainer>
      <Box flex justifyBetween alignCenter>
        <PageTitle>Operadores</PageTitle>
        <PrivateComponent roles={["Empresa", "Cliente"]}>
          <Button Icon={FaPlus} fontSize="1rem" onClick={onOpenModal}>
            Crear Operador
          </Button>
        </PrivateComponent>
      </Box>
      <OperatorsSearch
        isLoading={isFetching}
        setOperatorFilters={setOperatorFilters}
      />
      <OperatorsTable
        isLoading={isLoading}
        onPageChange={onPaginate}
        operators={operators?.results || []}
        pageCount={operators?.total_pages || 0}
      />
      <PrivateComponent roles={["Empresa", "Cliente"]}>
        <CreateOperatorModal />
      </PrivateComponent>
    </PageContainer>
  );
};
