import { skipToken } from "@reduxjs/toolkit/dist/query";
import { imgUrl } from "../../../../shared/components/navbar";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import {
  getDateWithFormat,
  getPhoneWithFormat,
} from "../../../../shared/helpers";
import { Supplier } from "../../../../shared/types";
import { For } from "../../../../shared/utilities/For";
import { useGetProviderWorkers } from "../../services";
import * as S from "./ProviderWorkers.styles";

interface Props {
  provider?: Supplier;
}

export const ProviderWorkers = ({ provider }: Props) => {
  const { data: workers = [], isLoading } = useGetProviderWorkers(
    provider?.supplier_id || skipToken
  );

  return (
    <GlobalContentBox isLoading={isLoading} title="Usuarios asociados">
      <S.ProviderWorkersList>
        <For
          each={workers}
          render={(worker) => (
            <S.ProviderWorkersListItem>
              <img
                src={worker.user_photography || imgUrl}
                alt={worker.user_fullname.toLowerCase()}
              />
              <div>
                <h3>
                  {worker.user_fullname} {worker.user_surname}
                </h3>
                <h4>
                  Correo: <span>{worker.user_email}</span>
                </h4>
                <h4>
                  Fecha:{" "}
                  <span>{getDateWithFormat(worker.worker_birthday)}</span>
                </h4>
                <h4>
                  Teléfono: <span>{getPhoneWithFormat(worker.user_phone)}</span>
                </h4>
              </div>
            </S.ProviderWorkersListItem>
          )}
        />
      </S.ProviderWorkersList>
    </GlobalContentBox>
  );
};
