import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import { setIsDeleteClientModal } from "../../../../redux/reducers/ui-reducer";
import { useGetUserScore } from "../../../../redux/rtk-query";
import { useAppDispatch } from "../../../../redux/store";
import { Box } from "../../../../shared/components/styled";
import { ActionsCellBox } from "../../../../shared/components/styled/ActionsCellBox";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { UserDetailsCard } from "../../../../shared/components/user-info/user-details-card";
import { useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { If } from "../../../../shared/utilities/If";
import { useClientDetailsPageCtx } from "../../context/client-details-page-ctx/ClientDetailsPageCtx";
import { ClientInfoForm } from "../client-info-form";
import { ClientDeleteModal } from "../modals/client-delete-modal";
import * as S from "./ClientDetailsCard.styles";

export const ClientDetailsCard = () => {
  const dispatch = useAppDispatch();
  const actionsRef = useRef<HTMLDivElement>(null);
  const { toggleEditMode, isEditMode, formRef, user } =
    useClientDetailsPageCtx();
  const [isOptionsOpen, toggleIsOptionsOpen, setIsOptionsOpen] =
    useToggle(false);

  const { data: score } = useGetUserScore(user || skipToken);

  useClickOutside(actionsRef, () => setIsOptionsOpen(false));

  const openDeleteClientModal = () => {
    dispatch(setIsDeleteClientModal(true));
    toggleIsOptionsOpen();
  };

  const editTitle =
    user?.user_status !== "Suspendido" ? "Editar Empresa" : undefined;

  return (
    <GlobalContentBox style={{ position: "relative" }}>
      <Box flex flexColumn gap="1rem">
        <UserDetailsCard
          score={score}
          toggleEditMode={toggleEditMode}
          profilePhoto={user?.user_photography}
          editTitle={isEditMode ? undefined : editTitle}
        />
        <ClientInfoForm ref={formRef} />
      </Box>
      <If showIf={isEditMode && !isOptionsOpen}>
        <S.OpenOptionsButton
          extraStyles={ActionsCellStyles}
          onClick={toggleIsOptionsOpen}
        >
          <div />
          <div />
          <div />
        </S.OpenOptionsButton>
      </If>
      <If showIf={isEditMode && isOptionsOpen}>
        <S.OpenOptionsButton extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </S.OpenOptionsButton>
      </If>
      <If showIf={isEditMode && isOptionsOpen}>
        <ActionsCellBox ref={actionsRef} top="2rem" right="1.5rem">
          <div onClick={openDeleteClientModal}>Eliminar usuario</div>
        </ActionsCellBox>
      </If>
      <ClientDeleteModal />
    </GlobalContentBox>
  );
};
