import styled from "styled-components";
import { Form } from "../../../../shared/components/form";

export const ProfileInfoFormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;

  > * {
    align-self: flex-start;
  }
`;
