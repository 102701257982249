import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { MyInput } from "../../../../shared/components/inputs";
import { Button } from "../../../../shared/components/ui/button";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { GetUsersParams } from "../../../../shared/interfaces";
import { ReactSetter } from "../../../../shared/types";
import {
  ProvidersSearchFormProps,
  ProvidersSearchFormShape,
} from "../../schemas";
import * as S from "./ProvidersSearch.styles";

type Keys = keyof ProvidersSearchFormProps;

interface Props {
  isLoading: boolean;
  setProviderFilters: ReactSetter<GetUsersParams>;
}

export const ProvidersSearch = ({ isLoading, setProviderFilters }: Props) => {
  const methods = useForm<ProvidersSearchFormProps>({
    resolver: yupResolver(ProvidersSearchFormShape),
  });

  const onSubmit = (data: ProvidersSearchFormProps) => {
    setProviderFilters((prev) => ({
      ...prev,
      ...data,
    }));
  };
  return (
    <GlobalContentBox title="Búsqueda de proveedores">
      <S.ProvidersSearchForm
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MyInput<Keys>
          name="fullname"
          label="Nombre"
          placeholder="Ingresa un nombre"
        />
        <MyInput<Keys>
          name="phone"
          label="Número de teléfono"
          placeholder="Ingresa un teléfono"
        />
        <MyInput<Keys>
          name="email"
          label="Correo electrónico"
          placeholder="Ingresa un correo electrónico"
        />
        <Button animate loading={isLoading}>
          Buscar
        </Button>
      </S.ProvidersSearchForm>
    </GlobalContentBox>
  );
};
