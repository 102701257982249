import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { GlobalLayout } from "../layouts/global";
import { PrivateLayout } from "../layouts/private";
import { PublicLayout } from "../layouts/public";
import { PrivateComponent } from "../modules/auth/components/private-component";
import { PrivateRoutes, PublicRoutes } from "./app.routes";
import { RouteProps } from "./interfaces";

export const AppRouter = () => (
  <BrowserRouter>
    <GlobalLayout>
      <Routes>
        <Route element={<PublicLayout />}>
          {PublicRoutes.map(({ Component, path }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
        <Route element={<PrivateLayout />}>
          {PrivateRoutes.map(
            ({ Component, path, rolesAllowed }: RouteProps) => (
              <Route
                key={path}
                path={path}
                element={
                  <PrivateComponent roles={rolesAllowed} isRoute>
                    <Component />
                  </PrivateComponent>
                }
              />
            )
          )}
        </Route>
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </GlobalLayout>
  </BrowserRouter>
);
