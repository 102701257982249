import { createAsyncThunk } from "@reduxjs/toolkit";
import { generalRoutes } from "../../../redux/rtk-query";
import { generalAxiosService } from "../../../services/general-service";
import { setTokens } from "../../../shared/helpers";
import { User } from "../../../shared/types";
import { LoginResponse } from "../services";
import { setIsAuthenticated, setIsValidating, setUser } from "./auth.reducer";

export const startGetUserProfile = createAsyncThunk(
  "auth/startGetUserProfile",
  async (_, { dispatch }) => {
    interface Response {
      data: User;
    }
    try {
      const {
        data: { data },
      } = await generalAxiosService.get<Response>(generalRoutes.meRT());
      dispatch(setUser(data));
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const startValidateRefresh = createAsyncThunk(
  "auth/startValidateRefresh",
  async (_, { dispatch }) => {
    interface Response {
      data: LoginResponse;
    }

    try {
      const refresh = localStorage.getItem("refresh");
      const {
        data: { data },
      } = await generalAxiosService.get<Response>(
        generalRoutes.validateRefreshTokenRT(),
        { headers: { Authorization: `Bearer ${refresh}` } }
      );
      setTokens(data);
      await dispatch(startGetUserProfile());
      dispatch(setIsAuthenticated(true));
    } catch (error) {
      console.error(error);
      localStorage.clear();
      dispatch(setIsAuthenticated(false));
    }
  }
);

export const startValidateAccess = createAsyncThunk(
  "auth/startValidateAccess",
  async (_, { dispatch }) => {
    dispatch(setIsValidating(true));

    try {
      const access = localStorage.getItem("access");
      await generalAxiosService.get(generalRoutes.validateAccessTokenRT(),{ headers: { Authorization: `Bearer ${access}` } });
      await dispatch(startGetUserProfile());
      dispatch(setIsAuthenticated(true));
    } catch (error) {
      console.error(error);
      await dispatch(startValidateRefresh());
    } finally {
      dispatch(setIsValidating(false));
    }
  }
);
