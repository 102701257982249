import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FaStar } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  selectIsHistoricDetailsModal,
  setIsHistoricDetailsModal,
} from "../../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { imgUrl } from "../../../../../shared/components/navbar";
import { Box } from "../../../../../shared/components/styled";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { Button } from "../../../../../shared/components/ui/button";
import { getDateWithFormat } from "../../../../../shared/helpers";
import { If } from "../../../../../shared/utilities/If";
import { useGetEventDetails } from "../../../services";
import { HistoricEvaluationCell } from "../../reports-table/ReportsTable.styles";
import * as S from "./HistoricDetailsModal.styles";

interface Props {
  eventId?: string;
  skipRequest: boolean;
  afterCloseModal: () => void;
}

export const HistoricDetailsModal = ({
  afterCloseModal,
  eventId,
  skipRequest,
}: Props) => {
  const dispatch = useAppDispatch();

  const isOpen = useSelector(selectIsHistoricDetailsModal);

  const { data: event, isLoading } = useGetEventDetails(eventId || skipToken, {
    skip: skipRequest,
  });

  const onClose = () => {
    dispatch(setIsHistoricDetailsModal(false));
  };

  return (
    <BaseModal
      isOpen={isOpen}
      isLoading={isLoading}
      onRequestClose={onClose}
      onAfterClose={afterCloseModal}
      title="Detalles del historial"
    >
      <S.HistoricDetailsWrapper>
        <S.HistoricEntityWrapper>
          <Box flex justifyBetween>
            <h3 className="entity-title">Usuario</h3>
            <HistoricEvaluationCell>
              <FaStar />
              <span>
                {event?.score_to_affiliate === null
                  ? "Sin calificar"
                  : event?.score_to_affiliate}
              </span>
            </HistoricEvaluationCell>
          </Box>
          <S.HistoricEntity>
            <img
              src={event?.affiliate_photography || imgUrl}
              alt={event?.affiliate_fullname?.toLowerCase()}
            />
            <div>
              <h3>
                {event?.affiliate_fullname} {event?.affiliate_surname}
              </h3>
              <h4>
                Fecha: <span>{getDateWithFormat(event?.created_at)}</span>
              </h4>
              <h4>
                Hora:{" "}
                <span>{getDateWithFormat(event?.created_at, "h:mm A")}</span>
              </h4>
              <h4>
                Dirección: <span>{event?.attention_location}</span>
              </h4>
            </div>
          </S.HistoricEntity>
        </S.HistoricEntityWrapper>
        <If showIf={!!event?.worker_fullname}>
          <S.HistoricEntityWrapper>
            <Box flex justifyBetween>
              <h3 className="entity-title">Proveedor</h3>
              <HistoricEvaluationCell>
                <FaStar />
                <span>
                  {event?.score_to_worker === null
                    ? "Sin calificar"
                    : event?.score_to_worker}
                </span>
              </HistoricEvaluationCell>
            </Box>
            <S.HistoricEntity>
              <img
                src={event?.worker_photography || imgUrl}
                alt={event?.worker_fullname?.toLowerCase()}
              />
              <div>
                <h3>
                  {event?.worker_fullname} {event?.worker_surname}
                </h3>
                <h4>
                  Fecha:{" "}
                  <span>
                    {getDateWithFormat(event?.vehicle_assignment_time)}
                  </span>
                </h4>
                <h4>
                  Hora:{" "}
                  <span>
                    {getDateWithFormat(
                      event?.vehicle_assignment_time,
                      "h:mm A"
                    )}
                  </span>
                </h4>
                <h4>
                  Dirección: <span>{event?.initial_vehicle_location}</span>
                </h4>
              </div>
            </S.HistoricEntity>
          </S.HistoricEntityWrapper>
        </If>
        <Button secondary xl width="100%" onClick={onClose}>
          Cerrar
        </Button>
      </S.HistoricDetailsWrapper>
    </BaseModal>
  );
};
