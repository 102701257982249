import { FaStar } from "react-icons/fa";
import {
  setIsHistoricDetailsModal,
  setIsReportDetailsModal,
} from "../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../redux/store";
import * as T from "../../../../shared/components/styled";
import { colors } from "../../../../shared/theme";
import { If } from "../../../../shared/utilities/If";
import { Report } from "../../models/interfaces";
import { ReportPageType } from "../../pages/reports-list";
import * as S from "./ReportsTable.styles";

interface Props {
  event: Report;
  pageType: ReportPageType;
  onRowClick: (id: string) => void;
}

export const ReportsTableRow = ({ event, pageType, onRowClick }: Props) => {
  const dispatch = useAppDispatch();
  const openModal = () => {
    onRowClick(event._id);
    pageType === "reports"
      ? dispatch(setIsReportDetailsModal(true))
      : dispatch(setIsHistoricDetailsModal(true));
  };

  const statusColor =
    event.status === "Cancelado" ? colors.alert_dark : colors.blue;

  return (
    <T.TableRow>
      <T.TableCell pointer onClick={openModal}>
        {event.ticket.slice(0, 10).toUpperCase()}
      </T.TableCell>
      <T.TableCell>
        {event.affiliate_fullname} {event.affiliate_surname}
      </T.TableCell>
      <T.TableCell>{event.supplier_fullname}</T.TableCell>
      <T.TableCell>
        {event.worker_fullname} {event.worker_surname}
      </T.TableCell>
      <T.TableCell>
        <If showIf={pageType === "reports"}>
          <S.WatchReportCell onClick={openModal}>Ver</S.WatchReportCell>
        </If>
        <If showIf={pageType === "historic"}>
          <S.HistoricEvaluationCell>
            <FaStar />
            <span>
              {event.score_to_worker === null
                ? "Sin calificar"
                : event.score_to_worker}
            </span>
          </S.HistoricEvaluationCell>
        </If>
      </T.TableCell>
      <T.TableCell>
        <S.EventStatusCell color={statusColor}>
          {event.status}
        </S.EventStatusCell>
      </T.TableCell>
    </T.TableRow>
  );
};
