import React, { useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProviderInfoFormWrapper } from "./ProviderInfoForm.styles";
import {
  UpdateProviderFormProps,
  UpdateProviderFormShape,
} from "../../schemas";
import { useProviderDetailsCtx } from "../../context/provider-details-ctx";
import { MyInput } from "../../../../shared/components/inputs";
import { If } from "../../../../shared/utilities/If";
import { PhoneInput } from "../../../../shared/components/inputs/PhoneInput";
import { getNumberCountryCode } from "../../../../shared/helpers";

type Keys = keyof UpdateProviderFormProps;

export const ProviderInfoForm = React.forwardRef(function ProviderInfoForm(
  _,
  ref
) {
  const { provider, isEditMode } = useProviderDetailsCtx();
  const methods = useForm<UpdateProviderFormProps>({
    resolver: yupResolver(UpdateProviderFormShape),
    mode: "onChange",
    defaultValues: {
      rfc: provider?.supplier_rfc,
      email: provider?.user_email,
      full_phone: provider?.user_phone,
      fullname: provider?.user_fullname,
      phone:
        provider?.user_phone && getNumberCountryCode(provider.user_phone).phone,
      country_code:
        provider?.user_phone && getNumberCountryCode(provider.user_phone).code,
    },
  });

  useImperativeHandle(ref, () => ({
    validateForm: async () => {
      const isValid = await methods.trigger();
      return { isValid, values: methods.getValues() };
    },
  }));

  useEffect(() => {
    if (isEditMode) return;
    methods.reset();
  }, [isEditMode, methods]);

  return (
    <ProviderInfoFormWrapper methods={methods} isEditMode={isEditMode}>
      <If showIf={isEditMode}>
        <PhoneInput<Keys>
          autoFocus
          gap="1.5rem"
          name="phone"
          label="Teléfono"
          auto_focus_select
          auto_select={false}
          className="phone-input"
          readOnly={!isEditMode}
        />
      </If>
      <MyInput<Keys>
        name="fullname"
        label="Nombre"
        readOnly={!isEditMode}
        autoFocus
      />
      <If showIf={!isEditMode}>
        <MyInput<Keys>
          name="full_phone"
          label="Teléfono"
          readOnly={!isEditMode}
          autoFocus
        />
      </If>
      <MyInput<Keys>
        name="email"
        label="Correo electrónico"
        readOnly={!isEditMode}
        autoFocus
      />
      <MyInput<Keys> name="rfc" label="RFC" readOnly={!isEditMode} autoFocus />
    </ProviderInfoFormWrapper>
  );
});
