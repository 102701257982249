import styled from "styled-components";

interface IPageLoaderProps {
  isOpaque?: boolean;
}

export const PageLoaderWrapper = styled.div<IPageLoaderProps>`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  background-color: ${(props) =>
    props.isOpaque ? "rgba(0, 0, 0, 0.5)" : props.theme.colors.white};
`;
