import { useForm } from "react-hook-form";
import { MyInput } from "../../../../shared/components/inputs";
import { Button } from "../../../../shared/components/ui/button";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { GetUsersParams } from "../../../../shared/interfaces";
import { ReactSetter } from "../../../../shared/types";
import * as S from "./AdminClientSearch.styles";

interface Props {
  isLoading: boolean;
  setAdminClientFilters: ReactSetter<GetUsersParams>;
}
interface FormProps {
  email: string;
  phone: string;
  fullname: string;
}
type Keys = keyof FormProps;

export const AdminClientSearch = ({
  isLoading,
  setAdminClientFilters,
}: Props) => {
  const methods = useForm<FormProps>();
  const onSubmit = (data: FormProps) => {
    setAdminClientFilters(data);
  };

  return (
    <GlobalContentBox title="Búsqueda de clientes">
      <S.AdminClientSearchForm
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MyInput<Keys>
          name="fullname"
          label="Nombre"
          placeholder="Ingresa un nombre"
        />
        <MyInput<Keys>
          name="phone"
          label="Número de teléfono"
          placeholder="Ingresa un teléfono"
        />
        <MyInput<Keys>
          name="email"
          label="Correo electrónico"
          placeholder="Ingresa un correo electrónico"
        />
        <Button animate loading={isLoading}>
          Buscar
        </Button>
      </S.AdminClientSearchForm>
    </GlobalContentBox>
  );
};
