import { Call, Device } from "@twilio/voice-sdk";
import { useEffect, useState, useCallback } from "react";
import {
  useGetCallInfo,
  useGetCallToken,
} from "../../modules/operators/services";

export const useCallingService = () => {
  const [call, setCall] = useState<Call>();
  const [device, setDevice] = useState<Device>();
  const [isCalling, setIsCalling] = useState(false);
  const [userAnswered, setUserAnswered] = useState(false);

  const { data: tokenData } = useGetCallToken();
  const [getCallInfo, { isFetching: isGettingCallInfo }] = useGetCallInfo();

  const onCall = useCallback(
    async (phoneNumber: string) => {
      setIsCalling(true);
      setUserAnswered(false);
      const myCall = await device?.connect({
        params: { phoneNumber },
      });
      setCall(myCall);
    },
    [device]
  );

  const onHangUp = useCallback(() => {
    device?.disconnectAll();
  }, [device]);

  const restartValues = useCallback(() => {
    setIsCalling(false);
    setUserAnswered(false);
  }, []);

  useEffect(() => {
    if (!tokenData?.token) return;
    const myDevice = new Device(tokenData.token);
    setDevice(myDevice);
  }, [tokenData?.token]);

  useEffect(() => {
    if (!call) return;
    call.on("accept", (customCall: Call) => {
      setCall(customCall);
    });

    call.on("disconnect", async (conn: Call) => {
      const callInfo = await getCallInfo(
        conn.parameters.CallSid as string
      ).unwrap();
      setCall(undefined);
      setIsCalling(false);
      callInfo && setUserAnswered(true);
    });
  }, [call, getCallInfo]);

  return {
    onCall,
    onHangUp,
    restartValues,

    isCalling,
    userAnswered,
    isGettingCallInfo,
  };
};
