import styled from "styled-components";

export const SidebarWrapper = styled.aside`
  width: 16rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;

  > img {
    width: 8rem;
    height: 5rem;
    margin: 0 auto;
  }
`;
