import * as Yup from "yup";
import { stringRequired } from "../../../shared/schemas";

export const CreateEventFormShape = Yup.object({
  fullname: stringRequired,
  birthday: stringRequired,
  direction: stringRequired,
  // key_word:  stringRequired,
});

export type CreateEventFormProps = Yup.InferType<typeof CreateEventFormShape>;

/* ---------------------------------------------------------------------------- */
