import { FaCarAlt, FaMapMarkedAlt, FaUser } from "react-icons/fa";
import { IoMdSpeedometer } from "react-icons/io";
import { ProviderSvg } from "../../../assets/svgs/Provider.svg";
import { UserRole } from "../../../shared/enums";
import { ProviderActiveVehiclesPage } from "../pages/provider-active-vehicles";
import { ProviderCoveragePage } from "../pages/provider-coverage";
import { ProviderDetailsPage } from "../pages/provider-details";
import { ProviderVehiclesPage } from "../pages/provider-vehicles";
import { ProviderWorkersPage } from "../pages/provider-workers";
import { ProvidersListPage } from "../pages/providers-list";

const ProvidersRoutes = [
  {
    path: "/providers",
    name: "Proveedores",
    showInMenu: true,
    Icon: () => <ProviderSvg className="component-svg" />,
    Component: () => <ProvidersListPage />,
    rolesAllowed: ["Gerente"] as UserRole[],
  },
  {
    path: "/providers/:userId",
    name: "Detalles de proveedor",
    Component: () => <ProviderDetailsPage />,
    rolesAllowed: ["Gerente"] as UserRole[],
  },
  {
    path: "/vehicles",
    name: "Vehículos",
    showInMenu: true,
    Icon: FaCarAlt,
    Component: () => <ProviderVehiclesPage />,
    rolesAllowed: ["Proveedor"] as UserRole[],
  },
  {
    path: "/activity",
    name: "Actividad",
    showInMenu: true,
    Icon: IoMdSpeedometer,
    Component: () => <ProviderActiveVehiclesPage />,
    rolesAllowed: ["Proveedor"] as UserRole[],
  },
  {
    path: "/coverage",
    name: "Cobertura",
    showInMenu: true,
    Icon: FaMapMarkedAlt,
    Component: () => <ProviderCoveragePage />,
    rolesAllowed: ["Proveedor"] as UserRole[],
  },
  {
    path: "/workers",
    name: "Trabajadores",
    Icon: FaUser,
    showInMenu: true,
    Component: () => <ProviderWorkersPage />,
    rolesAllowed: ["Proveedor"] as UserRole[],
  },
] as const;

export { ProvidersRoutes };
