import * as Yup from "yup";
import { patterns } from "../../../shared/constants";
import {
  emailRequired,
  numberRequired,
  phoneNoCodeRequired,
  rfcRequired,
  stringOptional,
  stringRequired,
  validPassword,
} from "../../../shared/schemas";

export const ProvidersSearchFormShape = Yup.object({
  email: stringOptional,
  phone: stringOptional,
  fullname: stringOptional,
});

export type ProvidersSearchFormProps = Yup.InferType<
  typeof ProvidersSearchFormShape
>;

/* ------------------------------------------------------------------------------ */

export const CreateProviderFormShape = Yup.object({
  country_code: stringRequired,
  provider_rfc: rfcRequired,
  provider_email: emailRequired,
  provider_password: validPassword,
  provider_fullname: stringRequired,
  provider_phone: phoneNoCodeRequired,
  provider_confirmPassword: stringRequired.oneOf(
    [Yup.ref("provider_password"), null],
    "Las contraseñas no coinciden"
  ),
});

export type CreateProviderFormProps = Yup.InferType<
  typeof CreateProviderFormShape
>;

/* ------------------------------------------------------------------------------ */

export const UpdateProviderFormShape = Yup.object({
  email: emailRequired,
  rfc: rfcRequired,
  fullname: stringRequired,
  country_code: stringRequired,
  full_phone: stringOptional,
  phone: phoneNoCodeRequired,
});

export type UpdateProviderFormProps = Yup.InferType<
  typeof UpdateProviderFormShape
>;

/* ------------------------------------------------------------------------------ */

export const CreateVehicleFormShape = Yup.object({
  typeId: stringRequired,
  serviceId: stringRequired,
  country_code: stringRequired,
  phone: phoneNoCodeRequired,
  brand: stringRequired.uppercase(),
  model_type: stringRequired.uppercase(),
  license_plate: stringRequired.uppercase(),
  color: stringRequired
    .uppercase()
    .matches(
      patterns.onlyString,
      "No se permiten números ni caracteres especiales"
    ),
  model_year: numberRequired.integer("El año debe ser un número entero"),
});

export type CreateVehicleFormProps = Yup.InferType<
  typeof CreateVehicleFormShape
>;

/* ------------------------------------------------------------------------------ */

export const ValidateVehicleCodeFormShape = Yup.object({
  code: stringRequired.min(6, "El código debe tener 6 caracteres"),
});

export type ValidateVehicleCodeFormProps = Yup.InferType<
  typeof ValidateVehicleCodeFormShape
>;

/* ------------------------------------------------------------------------------ */
