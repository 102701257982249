import { Box } from "../../../../../shared/components/styled";
import { Event } from "../../../models/interfaces";

interface Props {
  event?: Event;
}

export const ManualRecordProvider = ({ event }: Props) => {
  return (
    <Box flex flexColumn gap="16px" style={{ minHeight: "10rem" }}>
      {event?.pqr_worker}
      {/* <S.ReportItem>
                <h4>1. Lorem ipsum dolor sit amet consectetur adipisicing elit.</h4>
            </S.ReportItem>
            <S.ReportItem>
                <h4>2. Lorem ipsum dolor sit amet consectetur adipisicing elit.</h4>
            </S.ReportItem>
            <S.ReportItem>
                <h4>3. Lorem ipsum dolor sit amet consectetur adipisicing elit.</h4>
            </S.ReportItem>
            <S.ReportItem>
                <h4>4. Lorem ipsum dolor sit amet consectetur adipisicing elit.</h4>
            </S.ReportItem> */}
    </Box>
  );
};
