import { imgUrl } from "../../../../../shared/components/navbar";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { Button } from "../../../../../shared/components/ui/button";
import { useGetAffiliateMedicalRecords } from "../../../../affiliates/services";
import * as S from "./AffiliateMedicalRecord.styles";

interface Props {
  isOpen: boolean;
  affiliateId: string;
  closeModal: () => void;
}

export const AffiliateMedicalRecord = ({
  closeModal,
  isOpen,
  affiliateId,
}: Props) => {
  const { data, isLoading } = useGetAffiliateMedicalRecords(affiliateId);

  return (
    <BaseModal
      isOpen={isOpen}
      isLoading={isLoading}
      title="Expediente médico"
      onRequestClose={closeModal}
    >
      <S.MedicalRecordContainer>
        <img src={data?.user_photography || imgUrl} alt={data?.user_fullname} />
        <S.MedicalRecordItem if={data?.user_fullname}>
          <h4>Nombre:</h4>
          <span>
            {data?.user_fullname} {data?.user_surname}
          </span>
        </S.MedicalRecordItem>
        <S.MedicalRecordItem if>
          <h4>Peso en kg:</h4>
          <span>{data?.record_weight || "Sin registrar"}</span>
        </S.MedicalRecordItem>
        <S.MedicalRecordItem if>
          <h4>Estatura en metros:</h4>
          <span>{data?.record_height || "Sin registrar"}</span>
        </S.MedicalRecordItem>
        <S.MedicalRecordItem if>
          <h4>Tipo de sangre:</h4>
          <span>{data?.record_blood_type || "Sin registrar"}</span>
        </S.MedicalRecordItem>
        <S.MedicalRecordItem if>
          <h4>
            seguro /<br /> Aseguradora:
          </h4>
          <span>{data?.record_insurance_company || "Sin registrar"}</span>
        </S.MedicalRecordItem>

        <S.MedicalRecordItem if>
          <h4>Numero de póliza:</h4>
          <span>{data?.record_policy || "Sin registrar"}</span>
        </S.MedicalRecordItem>
        <S.MedicalRecordItem if>
          <h4>Alergias:</h4>
          <span>{data?.record_allergies || "Sin registrar"}</span>
        </S.MedicalRecordItem>
        <S.MedicalRecordItem if>
          <h4>Enfermedades crónicas:</h4>
          <span>{data?.record_diseases || "Sin registrar"}</span>
        </S.MedicalRecordItem>
        <S.MedicalRecordItem if>
          <h4>Medicamentos:</h4>
          <span>{data?.record_medicines || "Sin registrar"}</span>
        </S.MedicalRecordItem>
        <Button secondary xl width="100%" onClick={closeModal}>
          Cerrar
        </Button>
      </S.MedicalRecordContainer>
    </BaseModal>
  );
};
