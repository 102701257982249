import { toast } from "react-toastify";
import {
  affiliatesRoutes,
  companyRoutes,
  globalApi,
  usersRoutes,
} from "../../../redux/rtk-query";
import { handleServiceRequest, includesAll } from "../../../shared/helpers";
import { ErrorResponse, GetUsersParams } from "../../../shared/interfaces";
import { Affiliate } from "../../../shared/types";
import { PaginateRes } from "../../../shared/types/paginate";
import { handleAffiliatesExcelErrors } from "../helpers/affiliatesExcelError";
import { AffiliateMedicalRecord } from "../models";
import {
  UpdateAffiliateBody,
  UploadAffiliatesByCompanyBody,
} from "./requests.interfaces";
import { UploadAffiliatesResponse } from "./responses.interfaces";

const AffiliatesService = globalApi.injectEndpoints({
  endpoints: (builder) => ({
    updateAffiliate: builder.mutation<void, UpdateAffiliateBody>({
      query: ({ affiliate_id, ...body }) => ({
        url: usersRoutes.updateAffiliateRT(affiliate_id),
        method: "PUT",
        body,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo electrónico ya está en uso");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya está en uso");
          } else if (includesAll(message, "curp", "already exists")) {
            toast.warn("El CURP ya está en uso");
          } else {
            toast.error(
              "Ha ocurrido un error al actualizar los datos dal usuario, intente nuevamente"
            );
          }
        };
        await handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Afiliado actualizado exitosamente",
        });
      },
      invalidatesTags: (_, error) =>
        error ? [] : ["company affiliates", "user details"],
    }),
    uploadAffiliates: builder.mutation<
      UploadAffiliatesResponse,
      UploadAffiliatesByCompanyBody
    >({
      query: ({ company_id, file }) => {
        const body = new FormData();
        body.append("file", file);
        return {
          url: companyRoutes.uploadAffiliatesRT(company_id),
          method: "POST",
          body,
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          onSuccess: handleAffiliatesExcelErrors,
        });
      },
      transformResponse: (response: { data: UploadAffiliatesResponse }) =>
        response.data,
      invalidatesTags: ["company affiliates"],
    }),
    getAffiliateMedicalRecords: builder.query<AffiliateMedicalRecord, string>({
      query: (affiliate_id) =>
        affiliatesRoutes.getAffiliateMedicalRecordRT(affiliate_id),
      transformResponse: (response: { data: AffiliateMedicalRecord }) =>
        response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage:
            "Error al obtener los registros médicos del afiliado, intente nuevamente",
        });
      },
      providesTags: ["affiliate medical records"],
    }),
    validateAffiliate: builder.mutation<void, string>({
      query: (affiliate_id) => ({
        url: usersRoutes.validateAffiliateRT(affiliate_id),
        method: "POST",
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          successMessage: "Usuario validado exitosamente",
          errorMessage: "Error al validar el afiliado, intente nuevamente",
        });
      },
      invalidatesTags: (_, error) => (error ? [] : ["affiliates to call"]),
    }),
    getSubscriptionAffiliates: builder.query<
      PaginateRes<Affiliate[]>,
      GetUsersParams
    >({
      query: (params) => ({
        url: usersRoutes.getSubscriptionAffiliatesRT(),
        params,
      }),
      transformResponse: (response: { data: PaginateRes<Affiliate[]> }) =>
        response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al obtener los usuarios, intente nuevamente",
        });
      },
    }),
    getAffiliateBeneficiaries: builder.query<Affiliate[], string>({
      query: (affiliate_id) =>
        usersRoutes.getAffiliateBeneficiariesRT(affiliate_id),
      transformResponse: (response: { data: Affiliate[] }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage:
            "Error al obtener los beneficiarios, intente nuevamente",
        });
      },
      providesTags: ["affiliate beneficiaries"],
    }),
  }),
});

export const {
  useUpdateAffiliateMutation: useUpdateAffiliate,
  useUploadAffiliatesMutation: useUploadAffiliates,
  useValidateAffiliateMutation: useValidateAffiliate,
  useGetAffiliateBeneficiariesQuery: useGetAffiliateBeneficiaries,
  useGetSubscriptionAffiliatesQuery: useGetSubscriptionAffiliates,
  useGetAffiliateMedicalRecordsQuery: useGetAffiliateMedicalRecords,
} = AffiliatesService;
