import React from "react";

export const useToggleValue = <T extends readonly any[]>(
  values: T,
  initialValue: T[number]
) => {
  const [value, setValue] = React.useState<T[number]>(initialValue);

  const toggle = React.useCallback(() => {
    const index = values.indexOf(value);
    setValue(values[(index + 1) % values.length]);
  }, [value, values]);

  return [value, toggle, setValue] as const;
};
