import dayjs from "dayjs";
import "dayjs/locale/es";

dayjs.locale("es");

export const getDateWithFormat = (
  date?: string | number,
  format = "D / MMM / YYYY"
) => {
  return dayjs(date).format(format);
};

export const getDateWithInputFormat = (date?: string | number) => {
  return dayjs(date).format("YYYY-MM-DD");
};
