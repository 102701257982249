import styled from "styled-components";
import { Skeleton } from "../../../styles";

interface Props {
  width?: string;
  height?: string;
}

export const ProfilePhoto = styled.img<Props>`
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  object-position: center;

  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const SkeletonBox = styled.div<Props>`
  ${Skeleton}
  cursor: pointer;
  border-radius: 50%;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
