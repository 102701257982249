import { useForm } from "react-hook-form";
import { BsFillCreditCardFill } from "react-icons/bs";
import { MyInput } from "../../../../shared/components/inputs";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { User } from "../../../../shared/types";
import { PaymentForm } from "./ClientPaymentInfo.styles";

interface Props {
  user?: User;
}

export const ClientPaymentInfo = ({ user }: Props) => {
  const methods = useForm({
    defaultValues: {
      "payment-name": user?.user_fullname,
      email2: user?.user_email,
    },
  });
  return (
    <PaymentForm methods={methods}>
      <GlobalContentBox title="Información de pago">
        <MyInput
          readOnly
          name="cardNumber1"
          label="Número de tarjeta"
          IconRight={BsFillCreditCardFill}
        />
        <MyInput readOnly name="expirationDate" label="Fecha de vencimiento" />
        <MyInput readOnly name="cvv" label="CVV" />
        <MyInput
          readOnly
          name="payment-name"
          label="Nombre del titular"
          autoFocus
        />
      </GlobalContentBox>
      <GlobalContentBox title="Datos de facturación">
        <MyInput readOnly label="Razón social" name="businessName" />
        <MyInput readOnly name="rfc" label="RFC" />
        <MyInput readOnly label="Use CFDI" name="useCFDI" />
        <MyInput readOnly name="email2" label="Correo electrónico" autoFocus />
      </GlobalContentBox>
    </PaymentForm>
  );
};
