import styled from "styled-components";

export const UserSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;

  .user-status {
    font-weight: 500;
    margin-right: -0.5rem;
  }
`;

export const UserSectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  > * {
    font-size: 1.7rem;
  }
  > h1 {
    text-transform: capitalize;
  }
  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const UserSectionActual = styled.div``;
