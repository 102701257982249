import { useForm } from "react-hook-form";
import { MyInput } from "../inputs";
import { Button } from "../ui/button";
import { GlobalContentBox } from "../ui/global-content-box";
import * as S from "./UsersSearchForm.styles";

interface Props {
  title?: string;
  isLoading: boolean;
  onSubmit?: (data: UsersSearchFormProps) => void;
}

export interface UsersSearchFormProps {
  email?: string;
  phone?: string;
  fullname?: string;
}
type Keys = keyof UsersSearchFormProps;

export const UsersSearchForm = ({
  onSubmit,
  isLoading,
  title = "Búsqueda de usuarios",
}: Props) => {
  const methods = useForm<UsersSearchFormProps>();

  return (
    <GlobalContentBox title={title}>
      <S.UsersSearchFormStyled
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit || (() => {}))}
      >
        <MyInput<Keys>
          name="fullname"
          label="Nombre"
          placeholder="Ingresa un nombre"
        />
        <MyInput<Keys>
          name="phone"
          label="Número de teléfono"
          placeholder="Ingresa un teléfono"
        />
        <MyInput<Keys>
          name="email"
          label="Correo electrónico"
          placeholder="Ingresa un correo electrónico"
        />
        <Button animate loading={isLoading}>
          Buscar
        </Button>
      </S.UsersSearchFormStyled>
    </GlobalContentBox>
  );
};
