import { AnimatePresence } from "framer-motion";
import React from "react";
import { useClickOutside } from "react-haiku";
import { IconType } from "react-icons";
import { BiUserCircle } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import { setIsAuthenticated } from "../../../../modules/auth/redux";
import { useAppDispatch } from "../../../../redux/store";
import { UserAvailability } from "../../../enums";
import { useMyNavigate } from "../../../hooks/useMyNavigate";
import { ReactSetter } from "../../../types";
import * as S from "./NavbarOptions.styles";

interface NavProps {
  isOpen: boolean;
  setIsOpen: ReactSetter<boolean>;
  setAvailability: (availability: UserAvailability) => void;
}

const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const NavbarOptions = ({
  isOpen,
  setIsOpen,
  setAvailability,
}: NavProps) => {
  const dispatch = useAppDispatch();
  const navigate = useMyNavigate();
  const ref = React.useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setIsOpen(false));

  const onLogout = () => {
    dispatch(setIsAuthenticated(false));
    setAvailability("Desconectado");
    navigate({ name: "Login" });
    localStorage.clear();
    window.location.reload();
  };

  const goToProfile = () => {
    navigate({ name: "Mi Perfil" });
    setIsOpen(false);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <S.NavbarOptionsContainer
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.2 }}
          ref={ref}
        >
          <NavbarItem
            Icon={BiUserCircle}
            text="Mi perfil"
            onClick={goToProfile}
          />
          <NavbarItem Icon={MdLogout} text="Cerrar sesión" onClick={onLogout} />
        </S.NavbarOptionsContainer>
      )}
    </AnimatePresence>
  );
};

interface Props {
  Icon: IconType;
  text: string;
  onClick?: () => void;
}

const NavbarItem = ({ Icon, text, onClick }: Props) => (
  <div onClick={onClick}>
    <h3>{text}</h3>
    <Icon />
  </div>
);
