import { useState } from "react";
import { useSelector } from "react-redux";
import * as T from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { For } from "../../../../shared/utilities/For";
import { Report } from "../../models/interfaces";
import { ReportPageType } from "../../pages/reports-list";
import { HistoricDetailsModal } from "../modals/historic-details";
import { ReportsDetailsModal } from "../modals/report-details";
import { ReportsTableRow } from "./ReportsTableRow";
import {
  selectIsHistoricDetailsModal,
  selectIsReportDetailsModal,
} from "../../../../redux/reducers/ui-reducer";
import { If } from "../../../../shared/utilities/If";
import { TableWithPaginationProps } from "../../../../shared/types/paginate";
import { TablePagination } from "../../../../shared/components/ui/table-title";

const reportTitles = [
  "Ticket",
  "Usuario",
  "Proveedor",
  "Nombre del worker",
  "Reportes",
  "Estado",
];

const historiesTitles = [
  "Ticket",
  "Usuario",
  "Proveedor",
  "Nombre del worker",
  "Evaluación",
  "Estado",
];

const columns = "repeat(5, 1fr) .5fr";

interface Props extends TableWithPaginationProps {
  events: Report[];
  pageType: ReportPageType;
}

export const ReportsTable = ({
  isLoading,
  events,
  pageType,
  pageCount,
  onPageChange,
}: Props) => {
  const isHistoricOpen = useSelector(selectIsHistoricDetailsModal);
  const isReportsOpen = useSelector(selectIsReportDetailsModal);
  const [activeEvent, setActiveEvent] = useState<string>();

  const tableTitle =
    pageType === "reports" ? "Listado de reportes" : "Listado de tickets";

  const tableTitles = pageType === "reports" ? reportTitles : historiesTitles;

  const onRowClick = (id: string) => {
    setActiveEvent(id);
  };

  const afterCloseModal = () => {
    setActiveEvent(undefined);
  };

  const pagination = (
    <TablePagination
      pageCount={pageCount}
      onPageChange={onPageChange}
      title={tableTitle}
    />
  );

  return (
    <GlobalContentBox isLoading={isLoading} title={pagination}>
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={tableTitles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>
        <T.TableBody gridColumns={columns}>
          <For
            each={events}
            render={(event) => (
              <ReportsTableRow
                event={event}
                pageType={pageType}
                onRowClick={onRowClick}
              />
            )}
          />
        </T.TableBody>
      </T.Table>
      <If showIf={isReportsOpen}>
        <ReportsDetailsModal
          eventId={activeEvent}
          afterCloseModal={afterCloseModal}
          skipRequest={pageType === "historic"}
        />
      </If>
      <If showIf={isHistoricOpen}>
        <HistoricDetailsModal
          eventId={activeEvent}
          afterCloseModal={afterCloseModal}
          skipRequest={pageType === "reports"}
        />
      </If>
    </GlobalContentBox>
  );
};
