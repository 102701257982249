import styled, { css } from "styled-components";
import {
  ActiveClientCss,
  InactiveClientCss,
} from "../../../users/components/clients-table/ClientTable.styles";

interface Props {
  is_validated: boolean;
}

const InactiveVehicleCss = css`
  ${InactiveClientCss}
  cursor: pointer;
`;

export const StatusBox = styled.div<Props>`
  padding: 0.3rem;
  cursor: default;
  font-weight: 500;
  text-align: center;
  border-radius: 2rem;
  color: ${({ theme }) => theme.colors.white};

  ${(props) => (props.is_validated ? ActiveClientCss : InactiveVehicleCss)}
  width: 6rem;
`;

export const TableTitleCss = css`
  text-align: center;
`;

export const TableRowCss = css`
  p {
    margin: 0 auto;
    text-align: center;
  }
`;
