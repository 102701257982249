import Tippy from "@tippyjs/react";
import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import { MdAddIcCall } from "react-icons/md";
import { imgUrl } from "../../../../shared/components/navbar";
import * as T from "../../../../shared/components/styled";
import { ActionsCellBox } from "../../../../shared/components/styled/ActionsCellBox";
import {
  getDateWithFormat,
  getPhoneWithFormat,
} from "../../../../shared/helpers";
import { useMyNavigate, useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { Affiliate } from "../../../../shared/types";
import { If } from "../../../../shared/utilities/If";
import { StatusBox } from "../clients-table/ClientTable.styles";
import * as S from "./ClientAffiliatesTable.styles";

interface Props {
  affiliate: Affiliate;
  isCallable?: boolean;
  canHandleActions?: boolean;
  onCallAffiliate?: (affiliate: Affiliate) => void;
}

export const ClientAffiliatesTableRow = ({
  affiliate,
  isCallable,
  canHandleActions,
  onCallAffiliate,
}: Props) => {
  const navigate = useMyNavigate();

  const ref = useRef<HTMLDivElement>(null);
  const [isActionsOpen, toggle, setIsActionsOpen] = useToggle(false);

  useClickOutside(ref, () => setIsActionsOpen(false));

  const onCallAffiliateHandler = () => {
    if (!isCallable) return;
    onCallAffiliate?.(affiliate);
  };

  const onGoToDetails = (isEditMode = false) => {
    if (isCallable || !canHandleActions) return;
    isEditMode = affiliate.affiliate_is_deleted ? false : isEditMode;
    return () => {
      navigate({
        name: "Detalles de afiliado",
        params: { userId: affiliate.user_id },
        query: { isEditMode },
      });
    };
  };

  return (
    <T.TableRow relative>
      <T.TableCell
        pointer={!isCallable}
        as="img"
        onClick={onGoToDetails()}
        extraStyles={S.AffiliateImageStyles}
        src={affiliate.user_photography || imgUrl}
      />
      <T.TableCell pointer={!isCallable} onClick={onGoToDetails()}>
        {affiliate.user_fullname} {affiliate.user_surname}
      </T.TableCell>
      <T.TableCell>
        {getDateWithFormat(affiliate.affiliate_birthday)}
      </T.TableCell>
      <T.TableCell>{getPhoneWithFormat(affiliate.user_phone)}</T.TableCell>
      <T.TableCell>{affiliate.user_email}</T.TableCell>
      <T.TableCell>
        <StatusBox status={affiliate.user_status}>
          {affiliate.user_status}
        </StatusBox>
      </T.TableCell>
      <If showIf={canHandleActions}>
        <If showIf={!isCallable}>
          <If showIf={!isActionsOpen}>
            <T.TableCell onClick={toggle} extraStyles={ActionsCellStyles}>
              <div />
              <div />
              <div />
            </T.TableCell>
          </If>
          <If showIf={isActionsOpen}>
            <T.TableCell extraStyles={ActionsCellStyles}>
              <div />
              <div />
              <div />
            </T.TableCell>
          </If>
          <If showIf={isActionsOpen}>
            <ActionsCellBox ref={ref}>
              <div onClick={onGoToDetails(true)}>
                {affiliate.affiliate_is_deleted ? "Ver detalles" : "Editar"}
              </div>
            </ActionsCellBox>
          </If>
        </If>
        <If showIf={isCallable}>
          <T.TableCell extraStyles={S.AffiliatePhoneIconStyles}>
            <Tippy
              content={`Llamar a ${affiliate.user_fullname} ${affiliate.user_surname}`}
              delay={[500, 0]}
            >
              <div>
                <MdAddIcCall onClick={onCallAffiliateHandler} />
              </div>
            </Tippy>
          </T.TableCell>
        </If>
      </If>
    </T.TableRow>
  );
};
