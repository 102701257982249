import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineNumber } from "react-icons/ai";
import { BiLock } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../redux/store";
import { MyInput } from "../../../../shared/components/inputs";
import { Button } from "../../../../shared/components/ui/button";
import { PageButtons } from "../../../../shared/components/ui/page-buttons";
import { PageLoader } from "../../../../shared/components/ui/PageLoader";
import { setTokens } from "../../../../shared/helpers";
import { useToggle } from "../../../../shared/hooks";
import { If } from "../../../../shared/utilities/If";
import { useLazyGetProfile } from "../../../profile/services";
import { ForgotPasswordForm } from "../../components/forgot-password-form/ForgotPasswordForm";
import { setIsAuthenticated } from "../../redux";
import {
  LoginFormProps,
  LoginFormShape,
  ValidateCodeFormProps,
  ValidateCodeFormShape,
} from "../../schemas";
import {
  LoginResponse,
  useLogin,
  useValidateCode,
  useValidateUser,
} from "../../services";
import * as S from "./Login.page.styles";

type Keys = keyof LoginFormProps;
type Keys2 = keyof ValidateCodeFormProps;
type LocalUserStatus = "pending" | "failed";

export const LoginPage = () => {
  const dispatch = useAppDispatch();

  const [isRequesting, setIsRequesting] = useState(false);
  const [forgotPassword, toggleForgotPassword] = useToggle();
  const [temporalTokens, setTemporalTokens] = useState<LoginResponse>();
  const [userStatus, setUserStatus] = useState<LocalUserStatus>("pending");

  const [login] = useLogin();
  const [validateUser] = useValidateUser();
  const [getProfile, { isLoading: isGettingProfile }] = useLazyGetProfile();
  const [validateCode, { isLoading: isValidatingCode }] = useValidateCode();

  const methods = useForm<LoginFormProps>({
    resolver: yupResolver(LoginFormShape),
  });
  const methods2 = useForm<ValidateCodeFormProps>({
    resolver: yupResolver(ValidateCodeFormShape),
  });

  const onValidationFailed = (tokens: LoginResponse) => {
    setUserStatus("failed");
    setTemporalTokens(tokens);
    toast.success("Le enviamos un código por SMS para validar su cuenta");
  };

  const onValidationSuccess = async (tokens: LoginResponse) => {
    setTokens(tokens);
    await getProfile()
      .unwrap()
      .then(() => {
        dispatch(setIsAuthenticated(true));
      });
  };

  const onLoginSuccess = (res: LoginResponse) => {
    validateUser(res.accessToken)
      .unwrap()
      .then(() => onValidationSuccess(res))
      .catch(() => onValidationFailed(res))
      .finally(() => setIsRequesting(false));
  };

  const onLogin = async (data: LoginFormProps) => {
    toast.dismiss();
    setIsRequesting(true);
    login(data)
      .unwrap()
      .then(onLoginSuccess)
      .catch(() => setIsRequesting(false));
  };

  const onSendCode = async ({ code }: ValidateCodeFormProps) => {
    toast.dismiss();
    if (!temporalTokens) return;
    validateCode({ code, accessToken: temporalTokens.accessToken })
      .unwrap()
      .then(() => onValidationSuccess(temporalTokens));
  };

  useEffect(() => {
    methods.reset();
    methods2.reset();
  }, [userStatus, methods, methods2]);

  return (
    <S.LoginContainer>
      <img src="/assets/logotipo.png" alt="anngel logo" />
      <If showIf={userStatus !== "failed" && !forgotPassword}>
        <S.LoginForm methods={methods} onSubmit={methods.handleSubmit(onLogin)}>
          <MyInput<Keys>
            name="email"
            label="Usuario"
            placeholder="Default"
            IconLeft={FiUser}
          />
          <MyInput<Keys>
            name="password"
            label="Contraseña"
            placeholder="Ingrese su contraseña"
            type="password"
            IconLeft={BiLock}
          />
          <Button width="100%">Entrar</Button>
          <S.ForgotPasswordText onClick={toggleForgotPassword}>
            ¿Olvidaste tu contraseña?
          </S.ForgotPasswordText>
        </S.LoginForm>
      </If>
      <If showIf={userStatus === "failed" && !forgotPassword}>
        <S.ValidateCodeForm
          methods={methods2}
          onSubmit={methods2.handleSubmit(onSendCode)}
        >
          <h3>Ingresa el código de 6 dígitos que te enviamos por SMS</h3>
          <MyInput<Keys2>
            name="code"
            label="Código de verificación"
            placeholder="Ej: 123456"
            IconLeft={AiOutlineNumber}
          />
          <PageButtons
            className="buttons-container"
            proceedText="Enviar"
            onCancel={() => setUserStatus("pending")}
            show
          />
        </S.ValidateCodeForm>
      </If>
      <If showIf={forgotPassword}>
        <ForgotPasswordForm onCanceled={toggleForgotPassword} />
      </If>
      <PageLoader
        isOpaque
        showIf={isRequesting || isValidatingCode || isGettingProfile}
      />
    </S.LoginContainer>
  );
};
