import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectIsCreateProviderModal,
  setIsCreateProviderModal,
} from "../../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { MyInput } from "../../../../../shared/components/inputs";
import { PhoneInput } from "../../../../../shared/components/inputs/PhoneInput";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import {
  CreateProviderFormProps,
  CreateProviderFormShape,
} from "../../../schemas";
import { useCreateProvider } from "../../../services";
import { CreateProviderBody } from "../../../services/requests.interfaces";
import * as S from "./CreateProviderModal.styles";

type Keys = keyof CreateProviderFormProps;

export const CreateProviderModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useSelector(selectIsCreateProviderModal);

  const [createProvider, { isLoading }] = useCreateProvider();
  const methods = useForm<CreateProviderFormProps>({
    resolver: yupResolver(CreateProviderFormShape),
  });

  const onClose = () => {
    dispatch(setIsCreateProviderModal(false));
  };

  const onSubmit = (data: CreateProviderFormProps) => {
    const phone = `${data.country_code}${data.provider_phone}`;
    const body: CreateProviderBody = {
      ...(data.provider_rfc !== "" ? { rfc: data.provider_rfc } : {}),
      user: {
        phone,
        email: data.provider_email,
        fullname: data.provider_fullname,
        password: data.provider_password,
      },
    };
    createProvider(body).unwrap().then(onClose);
  };

  return (
    <BaseModal isOpen={isOpen} onRequestClose={onClose} title="Crear Proveedor">
      <S.CreateProviderFormWrapper
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MyInput<Keys> name="provider_fullname" label="Nombre Completo" />
        <MyInput<Keys> name="provider_email" label="Correo Electrónico" />
        <PhoneInput<Keys> name="provider_phone" label="Teléfono" />
        <MyInput<Keys> name="provider_rfc" label="RFC" />
        <MyInput<Keys>
          name="provider_password"
          label="Contraseña"
          type="password"
        />
        <MyInput<Keys>
          name="provider_confirmPassword"
          label="Confirmar Contraseña"
          type="password"
        />
        <PageButtons
          show
          onCancel={onClose}
          isLoading={isLoading}
          proceedText="Crear Proveedor"
        />
      </S.CreateProviderFormWrapper>
    </BaseModal>
  );
};
