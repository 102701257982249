import React from "react";
import * as S from "./NotAllowedPage.styles";

export const NotAllowedPage = () => {
  return (
    <S.NotAllowedPageContainer>
      <h2>Contenido no encontrado</h2>
    </S.NotAllowedPageContainer>
  );
};
