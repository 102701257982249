import React from "react";
import { SidebarList } from "./sidebar-list/SidebarList";
import * as S from "./Sidebar.styles";

export const Sidebar = () => {
  return (
    <S.SidebarWrapper>
      <img src="/assets/logotipo.png" alt="logo" />
      <SidebarList />
    </S.SidebarWrapper>
  );
};
