import { toast } from "react-toastify";
import {
  generalRoutes,
  globalApi,
  supplierRoutes,
  usersRoutes,
} from "../../../redux/rtk-query";
import store from "../../../redux/store";
import { handleServiceRequest, includesAll } from "../../../shared/helpers";
import { ErrorResponse, GetUsersParams } from "../../../shared/interfaces";
import { Manager, Supplier, Worker } from "../../../shared/types";
import { PaginateRes } from "../../../shared/types/paginate";
import { Coverage, Zone } from "../../events/models/interfaces";
import { Vehicle, VehicleStandard, VehicleType } from "../models";
import * as I from "./requests.interfaces";
import { WorkerWithVehicle } from "./responses.interfaces";

export const providersService = globalApi.injectEndpoints({
  endpoints: (builder) => ({
    getProviders: builder.query<PaginateRes<Supplier[]>, GetUsersParams>({
      query: (params) => ({
        url: usersRoutes.getProvidersRT(),
        params,
      }),
      transformResponse: (response: { data: PaginateRes<Supplier[]> }) =>
        response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al obtener los proveedores",
        });
      },
      providesTags: ["providers"],
    }),
    getProviderWorkers: builder.query<Worker[], string>({
      query: (id) => usersRoutes.getProviderWorkersRT(id),
      transformResponse: (response: { data: Worker[] }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al obtener los trabajadores del proveedor",
        });
      },
      providesTags: ["provider workers"],
    }),
    getProviderWorkersPagination: builder.query<
      PaginateRes<WorkerWithVehicle[]>,
      GetUsersParams
    >({
      query: (params) => {
        const { supplier_id: supplierId } = store.getState().auth
          .user as Supplier;

        return {
          url: usersRoutes.getProviderWorkersPaginationRT(supplierId),
          params,
        };
      },
      transformResponse: (response: {
        data: PaginateRes<WorkerWithVehicle[]>;
      }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al obtener los trabajadores del proveedor",
        });
      },
      providesTags: ["provider workers"],
    }),
    createProvider: builder.mutation<void, I.CreateProviderBody>({
      query: (body) => {
        const user = store.getState().auth.user as Manager;
        return {
          url: usersRoutes.createProviderRT(user.manager_id),
          method: "POST",
          body,
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo electrónico ya se encuentra registrado");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya se encuentra registrado");
          } else {
            toast.error(
              "Ha ocurrido un error al crear el proveedor, intente nuevamente"
            );
          }
        };
        await handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Proveedor creado exitosamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["providers"]),
    }),
    updateProvider: builder.mutation<void, I.UpdateProviderBody>({
      query: ({ supplier_id, ...body }) => ({
        url: usersRoutes.updateProviderRT(supplier_id),
        method: "PUT",
        body,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo electrónico ya se encuentra registrado");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya se encuentra registrado");
          } else {
            toast.error(
              "Ha ocurrido un error al actualizar el proveedor, intente nuevamente"
            );
          }
        };
        await handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Proveedor actualizado exitosamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["providers", "user details"]),
    }),
    deleteProvider: builder.mutation<void, string>({
      query: (supplier_id) => ({
        url: usersRoutes.deleteProviderRT(supplier_id),
        method: "DELETE",
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          successMessage: "Proveedor eliminado exitosamente",
          errorMessage:
            "Ha ocurrido un error al eliminar el proveedor, intente nuevamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["providers"]),
    }),
    createVehicle: builder.mutation<VehicleStandard, I.CreateVehicleBody>({
      query: (body) => {
        const { supplier_id: supplierId } = store.getState().auth
          .user as Supplier;
        return {
          url: supplierRoutes.createVehicleRT(supplierId),
          method: "POST",
          body,
        };
      },
      transformResponse: (response: { data: VehicleStandard }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "license_plate", "already exists")) {
            toast.warn("La placa ingresada ya está en uso");
          } else if (includesAll(message, "phone", "already exists")) {
            toast.warn("El número de teléfono ingresado ya está en uso");
          } else {
            toast.error(
              "Ha ocurrido un error al crear el vehículo, intente nuevamente"
            );
          }
        };
        await handleServiceRequest(queryFulfilled, {
          onError,
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["provider vehicles"]),
    }),
    updateVehicle: builder.mutation<void, I.UpdateVehicleBody>({
      query: ({ vehicle_id, ...body }) => ({
        url: supplierRoutes.updateVehicleRT(vehicle_id),
        method: "PUT",
        body,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "license_plate", "already exists")) {
            toast.warn("La placa ingresada ya está en uso");
          } else if (includesAll(message, "phone", "already exists")) {
            toast.warn("El número de teléfono ingresado ya está en uso");
          } else {
            toast.error(
              "Ha ocurrido un error al actualizar el vehículo, intente nuevamente"
            );
          }
        };
        await handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Vehículo actualizado exitosamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["provider vehicles"]),
    }),
    deleteVehicle: builder.mutation<void, string>({
      query: (vehicle_id) => ({
        url: supplierRoutes.deleteVehicleRT(vehicle_id),
        method: "DELETE",
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          successMessage: "Vehículo eliminado exitosamente",
          errorMessage:
            "Ha ocurrido un error al eliminar el vehículo, intente nuevamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["provider vehicles"]),
    }),
    getVehicles: builder.query<Vehicle[], string>({
      query: (supplier_id) => supplierRoutes.getVehiclesRT(supplier_id),
      transformResponse: (response: { data: Vehicle[] }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al obtener los vehículos",
        });
      },
      providesTags: ["provider vehicles"],
    }),
    validateVehicleCode: builder.mutation<boolean, I.ValidateVehicleCode>({
      query: ({ vehicle_id, code }) => ({
        url: supplierRoutes.validateVehicleCodeRT(vehicle_id, code),
        method: "PATCH",
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al validar el código",
          successMessage: "Código validado exitosamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["provider vehicles"]),
    }),
    reSendVehicleCode: builder.query<void, string>({
      query: (vehicle_id) => supplierRoutes.reSendVehicleCodeRT(vehicle_id),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al enviar el código, intente nuevamente",
        });
      },
    }),
    getVehicleTypes: builder.query<VehicleType[], void>({
      query: () => generalRoutes.getVehicleTypesRT(),
      transformResponse: (response: { data: VehicleType[] }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al obtener los tipos de vehículos",
        });
      },
      providesTags: ["vehicle types"],
    }),
    getProviderCoverage: builder.query<Coverage[], void>({
      query: () => supplierRoutes.getProviderCoverageRT(),
      transformResponse: (response: { data: Coverage[] }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al obtener la cobertura",
        });
      },
      providesTags: ["provider coverage"],
    }),
    getProviderZones: builder.query<Zone[], string>({
      query: (providerId) => supplierRoutes.getProviderZoneRT(providerId),
      transformResponse: (response: { data: Zone[] }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al obtener las zonas",
        });
      },
      providesTags: ["provider zone"],
    }),
    createProviderZones: builder.mutation<void, I.CreateZoneBody>({
      query: ({ coverageId, zones }) => {
        const { supplier_id: supplierId } = store.getState().auth
          .user as Supplier;
        const positions = zones.map((zone) => JSON.stringify(zone));

        const body = {
          positions,
          coverageId,
          supplierId,
        };
        return {
          url: supplierRoutes.createProviderZonesRT(),
          method: "POST",
          body,
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage:
            "Ha ocurrido un error al crear la zona, intente nuevamente",
          successMessage: "Zonas creadas exitosamente",
        });
      },
    }),
    deleteProviderZone: builder.mutation<void, string>({
      query: (zone_id) => ({
        url: supplierRoutes.deleteProviderZoneRT(zone_id),
        method: "DELETE",
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage:
            "Ha ocurrido un error al eliminar la zona, intente nuevamente",
          successMessage: "Zona eliminada exitosamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["provider zone"]),
    }),
    updateProviderZone: builder.mutation<void, I.UpdateZoneBody>({
      query: ({ zoneId, zone }) => {
        const positions = JSON.stringify(zone);
        return {
          body: { positions },
          method: "PUT",
          url: supplierRoutes.updateProviderZoneRT(zoneId),
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage:
            "Ha ocurrido un error al actualizar la zona, intente nuevamente",
        });
      },
    }),
  }),
});

export const {
  useGetVehiclesQuery: useGetVehicles,
  useGetProvidersQuery: useGetProviders,
  useCreateVehicleMutation: useCreateVehicle,
  useDeleteVehicleMutation: useDeleteVehicle,
  useUpdateVehicleMutation: useUpdateVehicle,
  useCreateProviderMutation: useCreateProvider,
  useUpdateProviderMutation: useUpdateProvider,
  useDeleteProviderMutation: useDeleteProvider,
  useGetVehicleTypesQuery: useGetVehicleTypes,
  useGetProviderZonesQuery: useGetProviderZones,
  useLazyReSendVehicleCodeQuery: useReSendVehicleCode,
  useUpdateProviderZoneMutation: useUpdateProviderZone,
  useDeleteProviderZoneMutation: useDeleteProviderZone,
  useGetProviderWorkersQuery: useGetProviderWorkers,
  useCreateProviderZonesMutation: useCreateProviderZones,
  useValidateVehicleCodeMutation: useValidateVehicleCode,
  useGetProviderCoverageQuery: useGetProviderCoverage,
  useGetProviderWorkersPaginationQuery: useGetProviderWorkersPagination,
} = providersService;
