import styled from "styled-components";

interface Props {
  top?: string;
  right?: string;
}

export const ActionsCellBox = styled.section<Props>`
  background-color: ${(props) => props.theme.colors.white};
  position: absolute;
  padding: 1rem 0 1rem 1rem;
  border-radius: 1rem;
  border: 0.0625rem solid ${(props) => props.theme.colors.gray.lightest};
  cursor: pointer;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div {
    font-weight: 700;
    padding-right: 4rem;
  }

  right: ${({ right }) => right || "8rem"};
  top: ${({ top }) => top || "0rem"};
`;
