import { globalApi, managersRoutes } from "../../../redux/rtk-query";
import { handleServiceRequest } from "../../../shared/helpers";
import { CreateCoverageBody, UpdateCoverageBody } from "./requests.interfaces";

export const managerServices = globalApi.injectEndpoints({
  endpoints: (build) => ({
    createCoverages: build.mutation<void, CreateCoverageBody[]>({
      query: (coverage) => ({
        url: managersRoutes.createCoveragesRT(),
        method: "POST",
        body: { coverage },
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Ha ocurrido un error al crear la cobertura, por favor intente nuevamente."
        );
      },
    }),
    deleteCoverage: build.mutation<void, string>({
      query: (id) => ({
        url: managersRoutes.deleteCoverageRT(id),
        method: "DELETE",
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Ha ocurrido un error al eliminar la cobertura, por favor intente nuevamente."
        );
      },
      invalidatesTags: (_, error) => (error ? [] : ["coverages"]),
    }),
    updateCoverage: build.mutation<void, UpdateCoverageBody>({
      query: ({ id, ...body }) => ({
        url: managersRoutes.updateCoverageRT(id),
        method: "PUT",
        body,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Ha ocurrido un error al actualizar la cobertura, por favor intente nuevamente."
        );
      },
      invalidatesTags: (_, error) => (error ? [] : ["coverages"]),
    }),
  }),
});

export const {
  useDeleteCoverageMutation: useDeleteCoverage,
  useUpdateCoverageMutation: useUpdateCoverage,
  useCreateCoveragesMutation: useCreateCoverages,
} = managerServices;
