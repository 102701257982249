import React, { useMemo, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  Box,
  PageContainer,
  PageTitle,
} from "../../../../shared/components/styled";
import { Button } from "../../../../shared/components/ui/button";
import { Supplier } from "../../../../shared/types";
import { If } from "../../../../shared/utilities/If";
import { selectUser } from "../../../auth/redux";
import { CreateVehicleModal } from "../../components/modals/create-vehicle";
import { DeleteVehicleModal } from "../../components/modals/delete-vehicle";
import { ValidateVehicleCodeModal } from "../../components/modals/validate-vehicle-code";
import { VehiclesTable } from "../../components/vehicles-table";
import { ProviderVehiclesCtx } from "../../context/provider-vehicles-ctx";
import { Vehicle } from "../../models";
import { useGetVehicles } from "../../services";

export const ProviderVehiclesPage = () => {
  const user = useSelector(selectUser) as Supplier;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [activeVehicle, setActiveVehicle] = useState<Vehicle>();
  const [isDeleteVehicleModal, setIsDeleteVehicleModal] = useState(false);

  const { data: vehicles = [], isLoading } = useGetVehicles(user?.supplier_id);

  const openModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const openCodeModal = React.useCallback(() => {
    setIsCodeModalOpen(true);
  }, []);

  const closeCodeModal = React.useCallback(() => {
    setIsCodeModalOpen(false);
  }, []);

  const openDeleteVehicleModal = React.useCallback(() => {
    setIsDeleteVehicleModal(true);
  }, []);

  const closeDeleteVehicleModal = React.useCallback(() => {
    setIsDeleteVehicleModal(false);
  }, []);

  const value = useMemo(
    () => ({
      openModal,
      openCodeModal,
      closeCodeModal,
      setActiveVehicle,
      openDeleteVehicleModal,
    }),
    [
      openModal,
      openCodeModal,
      closeCodeModal,
      setActiveVehicle,
      openDeleteVehicleModal,
    ]
  );

  return (
    <ProviderVehiclesCtx.Provider value={value}>
      <PageContainer>
        <Box flex justifyBetween>
          <PageTitle>Vehículos</PageTitle>
          <Button Icon={FaPlus} fontSize="1rem" onClick={openModal}>
            Crear Vehículo
          </Button>
        </Box>
        <VehiclesTable vehicles={vehicles} isLoading={isLoading} />
        <ValidateVehicleCodeModal
          isOpen={isCodeModalOpen}
          onClose={closeCodeModal}
          activeVehicle={activeVehicle}
        />
        <DeleteVehicleModal
          isOpen={isDeleteVehicleModal}
          onClose={closeDeleteVehicleModal}
          activeVehicle={activeVehicle}
        />
        <If showIf={isModalOpen}>
          <CreateVehicleModal
            isOpen={isModalOpen}
            onClose={closeModal}
            activeVehicle={activeVehicle}
          />
        </If>
      </PageContainer>
    </ProviderVehiclesCtx.Provider>
  );
};
