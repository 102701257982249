import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import * as S from "./UserSectionNav.styles";
import { Box, PageTitle } from "../../styled";
import { ToggleSwitch } from "../../ui/toggle-switch";
import { useMyNavigate, useToggle } from "../../../hooks";
import { RouteName } from "../../../../router";

interface UserSectionNavProps {
  title: string;
  actualSection: string;
  toggleText: string;
  backTo?: RouteName | number;
}

export const UserSectionNav = ({
  actualSection,
  title,
  toggleText,
  backTo = -1,
}: UserSectionNavProps) => {
  const navigate = useMyNavigate();
  const [isActive, setIsActive] = useToggle();

  const onBack = () => {
    if (typeof backTo === "number") {
      navigate(backTo);
    } else {
      navigate({ name: backTo });
    }
  };

  return (
    <S.UserSectionWrapper>
      <Box flex justifyBetween height="4rem">
        <S.UserSectionTitle>
          <IoIosArrowBack onClick={onBack} />
          <PageTitle>{title.toLowerCase()}</PageTitle>
        </S.UserSectionTitle>
        {/* <Box flex alignCenter>
                    <p className='user-status'>{toggleText}</p>
                    <ToggleSwitch
                        isActive={isActive}
                        toggleIsActive={setIsActive}
                    />
                </Box> */}
      </Box>
      {/* <Box flex alignCenter justifyBetween>
                <S.UserSectionActual>
                    {actualSection}
                </S.UserSectionActual>
            </Box> */}
    </S.UserSectionWrapper>
  );
};
