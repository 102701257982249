import styled from "styled-components";
import { Form } from "../../../../shared/components/form";
import { SearchComponentStyles } from "../../../../shared/styles";

interface Props {
  hasErrors?: boolean;
}

export const OperatorSearchForm = styled(Form)<Props>`
  ${SearchComponentStyles}
`;
