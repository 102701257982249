import { yupResolver } from "@hookform/resolvers/yup";
import React, { useImperativeHandle, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCheck } from "react-icons/ai";
import { MyInput } from "../../../../shared/components/inputs";
import { useClientDetailsPageCtx } from "../../context/client-details-page-ctx";
import { UpdateClientFormProps, UpdateClientFormShape } from "../../schemas";
import * as S from "./ClientInfoForm.styles";

import { getNumberCountryCode } from "../../../../shared/helpers";
import { PhoneInput } from "../../../../shared/components/inputs/PhoneInput";
import { If } from "../../../../shared/utilities/If";

type Keys = keyof UpdateClientFormProps;

export const ClientInfoForm = React.forwardRef(function ClientInfoForm(_, ref) {
  const { isEditMode, user } = useClientDetailsPageCtx();

  const methods = useForm<UpdateClientFormProps>({
    mode: "onChange",
    resolver: yupResolver(UpdateClientFormShape),
    defaultValues: {
      email: user?.user_email,
      name: user?.user_fullname,
      full_phone: user?.user_phone,
      phone: user?.user_phone && getNumberCountryCode(user.user_phone).phone,
      country_code:
        user?.user_phone && getNumberCountryCode(user.user_phone).code,
      rfc: user?.role_name === "Empresa" ? user.company_rfc : undefined,
    },
  });

  useImperativeHandle(ref, () => ({
    validateForm: async () => {
      const isValid = await methods.trigger();
      const values = methods.getValues();
      return { isValid, values };
    },
  }));

  useEffect(() => {
    isEditMode || methods.reset();
  }, [isEditMode, methods]);

  return (
    <S.ClientInfoFormWrapper
      methods={methods}
      isEditMode={isEditMode}
      onSubmit={methods.handleSubmit(() => {})}
    >
      <If showIf={isEditMode}>
        <PhoneInput<Keys>
          autoFocus
          gap="1.5rem"
          name="phone"
          label="Teléfono"
          auto_focus_select
          auto_select={false}
          className="phone-input"
          readOnly={!isEditMode}
        />
      </If>
      <MyInput<Keys>
        name="name"
        label="Contacto Principal"
        readOnly={!isEditMode}
        autoFocus
      />
      <MyInput<Keys> name="rfc" label="RFC" readOnly={!isEditMode} autoFocus />
      <If showIf={!isEditMode}>
        <MyInput<Keys>
          name="full_phone"
          label="Numero de teléfono"
          className="phone-input"
          IconRight={AiOutlineCheck}
          readOnly={!isEditMode}
          autoFocus
        />
      </If>
      <MyInput<Keys>
        name="email"
        label="Correo electrónico"
        className="email-input"
        IconRight={AiOutlineCheck}
        readOnly={!isEditMode}
        autoFocus
      />
      {/* {isEditMode ||
                <MyInput
                    name="cardNumber"
                    label="Numero de tarjeta seleccionada"
                    className="card-input"
                    IconRight={BsFillCreditCardFill}
                    readOnly={!isEditMode}
                    autoFocus
                />} */}
    </S.ClientInfoFormWrapper>
  );
});
