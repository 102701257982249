import { keyframes } from "styled-components";

export const appear = keyframes`
    from{
        opacity: 0;
    }to{
        opacity: 1;
    }
`;

export const SkeletonAnimation = keyframes`
    100% {
        background-position: -100% 0;
    }
`;
