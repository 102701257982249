import { createContext, useContext } from "react";
import { User } from "../../../../shared/types";

export interface IClientDetailsPageCtx {
  user?: User;
  isEditMode: boolean;
  formRef: React.RefObject<any>;
  toggleEditMode: () => void;
}

export const ClientDetailsPageCtx = createContext({} as IClientDetailsPageCtx);

export const useClientDetailsPageCtx = () => useContext(ClientDetailsPageCtx);
