import { createContext, useContext } from "react";
import { Client } from "../../../../shared/types";

export interface IAdminClientDetailsCtx {
  adminClient?: Client;
  isEditMode: boolean;
  formRef: React.RefObject<any>;
  toggleEditMode: () => void;
}

export const AdminClientDetailsCtx = createContext(
  {} as IAdminClientDetailsCtx
);

export const useAdminClientDetailsCtx = () => useContext(AdminClientDetailsCtx);
