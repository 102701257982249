import React from "react";
import { colors } from "../../../theme";
import { If } from "../../../utilities/If";
import { Loader } from "../Loader";
import * as S from "./GlobalContentBox.styles";

interface Props {
  title?: React.ReactNode;
  isLoading?: boolean;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  height?: string;
}

export const GlobalContentBox = ({
  children,
  title,
  isLoading,
  className,
  style,
  contentStyle,
  height,
}: Props) => (
  <S.GlobalContentBoxWrapper
    height={height}
    style={style}
    className={`global-content-box-wrapper ${className}`}
  >
    <If showIf={!!title}>
      <S.GlobalContentBoxTitle className="global-content-box-title">
        {title}
      </S.GlobalContentBoxTitle>
    </If>
    <S.GlobalContentBoxContent
      style={contentStyle}
      isLoading={isLoading}
      className="global-content-box-content"
    >
      <If showIf={!isLoading}>{children}</If>
      <If showIf={isLoading}>
        <Loader color={colors.blue} size="4rem" />
      </If>
    </S.GlobalContentBoxContent>
  </S.GlobalContentBoxWrapper>
);
