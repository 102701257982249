import styled from "styled-components";

export const PaginationContainer = styled.div`
  .pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    gap: 0.5rem;
  }

  .movement-icon {
    position: relative;
    top: 0.1rem;
  }

  .pagination a {
    cursor: pointer;
    padding: 0.5rem;
  }

  .pagination__link--disabled a {
    opacity: 0.5;
    cursor: default;
  }

  .pagination__link--active a {
    color: ${(props) => props.theme.colors.blue};
    font-weight: bold;
  }
`;
