import { Polygon, PolygonProps, useGoogleMap } from "@react-google-maps/api";
import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { validatePolygonPaths } from "../../../../shared/helpers";
import { Coverage, Zone } from "../../../events/models/interfaces";
import { LatLngLiteral } from "../../pages/provider-coverage";

interface Props {
  zone: Zone;
  coverages: Coverage[];
  options: PolygonProps["options"];
  onSelectZone?: (zoneId: string) => void;
  onUpdateZone: (zoneId: string, positions: LatLngLiteral[]) => void;
}

export const AreaItem = ({
  zone,
  options,
  coverages,
  onUpdateZone,
  onSelectZone,
}: Props) => {
  const map = useGoogleMap();

  const polygonRef = React.useRef<google.maps.Polygon | null>(null);
  const [path, setPath] = React.useState<LatLngLiteral[]>(
    JSON.parse(zone.zone_positions)
  );

  const onEdit = useCallback(() => {
    toast.dismiss();
    if (!polygonRef.current || !options?.editable) return;
    const { coords, isNotValid } = validatePolygonPaths(
      polygonRef.current,
      coverages
    );
    if (isNotValid) {
      toast.warn("La zona no puede estar fuera de la cobertura");
      polygonRef.current.setPath(path);
    } else {
      setPath(coords);
      onUpdateZone(zone.zone_id, coords);
    }
  }, [options?.editable, coverages, zone.zone_id, path, onUpdateZone]);

  const onLoaded = useCallback((polygon: google.maps.Polygon) => {
    polygonRef.current = polygon;
  }, []);

  const onUnmount = useCallback(() => {
    polygonRef.current = null;
  }, []);

  const onDblClick = useCallback(() => {
    onSelectZone?.(zone.zone_id);
  }, [onSelectZone, zone.zone_id]);

  return (
    <Polygon
      onLoad={onLoaded}
      onMouseUp={onEdit}
      onUnmount={onUnmount}
      onDblClick={onDblClick}
      options={{ ...options, map }}
      path={path}
    />
  );
};
