import { AiOutlineCheck } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { TiCancel } from "react-icons/ti";
import { colors } from "../../../shared/theme";
import type { ActiveEvent } from "../../operators/models";
import { DashboardEvent, EventDetails } from "../models/interfaces";

export const eventStepsIcons = {
  // Step 1
  LookingForUnit: FiSearch,
  // Step 2
  Received: HiOutlineSpeakerphone,
  // Step 3
  Started: AiOutlineCheck,
};

export const getEventTypeInfo = (
  event: ActiveEvent | DashboardEvent | EventDetails
) => {
  const isNew = event.status === "Nuevo";
  const isStarted = event.status === "Iniciado";

  if (event.vehicle_assignment_time && isStarted) {
    return {
      text: "Evento iniciado",
      color: colors.green.primary,
      Icon: eventStepsIcons.Started,
      step: 3,
    };
  }
  if (event.operator_assignment_time && isNew) {
    return {
      text: "Alerta recibida",
      color: colors.red,
      Icon: eventStepsIcons.Received,
      step: 2,
    };
  }
  if (event.vehicle_assignment_time === null && isNew) {
    return {
      text: "Buscando unidad",
      color: colors.text,
      Icon: eventStepsIcons.LookingForUnit,
      step: 1,
    };
  }
  if (event.status === "Cancelado") {
    return {
      text: "Cancelado",
      Icon: TiCancel,
      color: colors.alert_dark,
      step: 0,
    };
  }
  return {
    text: "Finalizado",
    Icon: FaCheck,
    color: colors.blue,
    step: 0,
  };
};
