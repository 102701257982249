import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCountries } from "../../../redux/rtk-query";
import { MySelect } from "../my-select";
import { MyInput, MyInputProps } from "./MyInput";
import * as S from "./MyInput.styles";

interface Props<T> extends MyInputProps<T> {
  gap?: string;
  code_name?: T;
  auto_select?: boolean;
  auto_focus_select?: boolean;
}

export const PhoneInput = <T extends string>({
  code_name = "country_code" as T,
  auto_select = true,
  auto_focus_select,
  gap,
  ...props
}: Props<T>) => {
  const countries = useSelector(selectCountries);
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!auto_select) return;
    setValue(code_name, "+52" as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  return (
    <S.PhoneInputContainer style={{ width: "100%" }} gap={gap}>
      <MySelect
        get="code"
        name={code_name}
        label="Código"
        getSeparator=" "
        values={countries}
        show={["code", "name"]}
        autoFocus={auto_focus_select}
      />
      <MyInput {...props} />
    </S.PhoneInputContainer>
  );
};
