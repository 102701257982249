import React from "react";
import { useSelector } from "react-redux";
import * as T from "../../../../shared/components/styled/Table";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { useMyNavigate } from "../../../../shared/hooks";
import { For } from "../../../../shared/utilities/For";
import { selectUser } from "../../../auth/redux";
import { EventDetails } from "../../models/interfaces";
import { EventTableRow } from "./EventTableRow";

const titles = [
  "Ticket",
  "Tipo de emergencia",
  "Usuario",
  "Proveedor",
  "Tiempo activo",
  "Estado",
];

const columns = ".5fr repeat(3, 1fr) 1.2fr 1fr";

interface Props {
  isLoading: boolean;
  events: EventDetails[];
}

export const EventsTable = ({ events, isLoading }: Props) => {
  const navigate = useMyNavigate();

  const user = useSelector(selectUser);

  const onRowClick = React.useCallback(
    (event: EventDetails) => {
      if (user?.role_name !== "Operador") return;
      navigate({
        name: "Detalles del evento",
        params: { eventId: event._id },
      });
    },
    [navigate, user?.role_name]
  );

  return (
    <GlobalContentBox isLoading={isLoading} title="Operación en tiempo real">
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={titles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>
        <T.TableBody gridColumns={columns}>
          <For
            each={events}
            render={(event) => (
              <EventTableRow event={event} onRowClick={onRowClick} />
            )}
          />
        </T.TableBody>
      </T.Table>
    </GlobalContentBox>
  );
};
