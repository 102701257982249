import React from "react";
import { MdWifiCalling3 } from "react-icons/md";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { Button } from "../../../../../shared/components/ui/button";
import { CallingModalWrapper } from "../../../../operators/components/modals/calling-affiliate-modal/CallingAffiliateModal.styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CallingUserEventModal = ({ isOpen, onClose }: Props) => {
  return (
    <BaseModal
      onlyCloseOnButton
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Llamando..."
    >
      <CallingModalWrapper>
        <MdWifiCalling3 />
        <Button xl secondary onClick={onClose} width="100%">
          Colgar
        </Button>
      </CallingModalWrapper>
    </BaseModal>
  );
};
