import { useMemo } from "react";
import { Map, MapType } from "../../../../shared/components/map/Map";
import { Coverage, Zone } from "../../../events/models/interfaces";
import { CoverageAreas } from "../coverage-areas";
import { CoverageCircles } from "../coverage-circles";

interface Props {
  type?: MapType;
  zones?: Zone[];
  zoom?: number;
  editable?: boolean;
  circleEditable?: boolean;
  canDeleteCoverage?: boolean;
  canDelete?: boolean;
  coverages: Coverage[];
  children?: React.ReactNode;
  center?: google.maps.LatLngLiteral;
  onMapLoaded?: (map: google.maps.Map) => void;
  onDeleteCircle?: (coverageId: string) => void;
  addNewCircle?: (circle: google.maps.Circle) => void;
}

export const ProviderCoverageMap = ({
  coverages,
  zones,
  children,
  editable,
  circleEditable,
  canDelete = true,
  canDeleteCoverage,
  type = "load",
  zoom = 13,
  center,
  onMapLoaded,
  addNewCircle,
  onDeleteCircle,
}: Props) => {
  const customCenter = useMemo(() => {
    const lat =
      coverages.find((cov) => cov.coverage_country === "Mexico")
        ?.coverage_lat ?? coverages[0]?.coverage_lat;
    const lng =
      coverages.find((cov) => cov.coverage_country === "Mexico")
        ?.coverage_lng ?? coverages[0]?.coverage_lng;
    return lat && lng ? { lat: +lat, lng: +lng } : undefined;
  }, [coverages.length]);

  return (
    <Map
      zoom={zoom}
      type={type}
      height="100%"
      onLoaded={onMapLoaded}
      center={center || customCenter}
    >
      <CoverageCircles
        coverages={coverages}
        editable={circleEditable}
        addNewCircle={addNewCircle}
        deletable={canDeleteCoverage}
        onDeleteCircle={onDeleteCircle}
      />
      <CoverageAreas
        zones={zones || []}
        editable={editable}
        canDelete={canDelete}
        coverages={coverages}
      />
      {children}
    </Map>
  );
};
