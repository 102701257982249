import styled from "styled-components";
import { CustomScrollBar } from "../../../../shared/styles";

export const ProviderWorkersList = styled.ul`
  ${CustomScrollBar}
  height: 100%;
  overflow-y: auto;

  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ProviderWorkersListItem = styled.li`
  gap: 0.5rem;
  display: flex;

  > img {
    width: 7rem;
    height: 7rem;
    border-radius: 1.5rem;
    object-fit: cover;
    object-position: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h3 {
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.blue};
  }

  h4 {
    font-weight: 500;
    span {
      font-weight: 700;
    }
  }
`;
