import styled from "styled-components";
import { CustomScrollBar } from "../../styles";

interface Props {
  gap?: string;
}

export const PageContainer = styled.div<Props>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || "2rem"};
  /* overflow: auto;
    ${CustomScrollBar} */
`;
