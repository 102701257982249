import { useCallback, useState } from "react";
import { PageContainer, PageTitle } from "../../../../shared/components/styled";
import {
  UsersSearchForm,
  UsersSearchFormProps,
} from "../../../../shared/components/users-search-form";
import { GetUsersParams } from "../../../../shared/interfaces";
import { ProviderWorkersTable } from "../../components/provider-workers-table";
import { useGetProviderWorkersPagination } from "../../services/index";

export const ProviderWorkersPage = () => {
  const [workersFilters, setWorkersFilter] = useState<GetUsersParams>({});

  const {
    data: workers,
    isFetching,
    isLoading,
  } = useGetProviderWorkersPagination(workersFilters);

  const onSubmitSearch = useCallback(
    (data: UsersSearchFormProps) => {
      setWorkersFilter({
        ...workersFilters,
        ...data,
      });
    },
    [workersFilters]
  );

  const onPaginate = (page: number) => {
    setWorkersFilter({
      ...workersFilters,
      page,
    });
  };

  return (
    <PageContainer>
      <PageTitle>Usuarios</PageTitle>
      <UsersSearchForm isLoading={isFetching} onSubmit={onSubmitSearch} />
      <ProviderWorkersTable
        onPageChange={onPaginate}
        workers={workers?.results ?? []}
        pageCount={workers?.total_pages ?? 0}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};
