import styled from "styled-components";
import { motion } from "framer-motion";

interface IProps2 {
  width?: string;
  length: number;
}

export const EventStatsList = styled.div<IProps2>`
  height: 1rem;
  position: relative;
  bottom: 1.3rem;
  display: flex;
  width: ${(props) => props.width || "100%"};

  > p {
    :first-child {
      border-radius: 1rem 0 0 1rem;
    }
    :last-child {
      border-radius: ${(props) =>
        props.length > 1 ? "0 1rem 1rem 0" : "1rem"};
    }
  }
`;

interface IProps {
  value: number | string;
  color: string;
}

export const EventStatItem = styled(motion.p)<IProps>`
  height: 100%;
  cursor: pointer;
  width: ${({ value }) => `${value}%`};
  background-color: ${({ color }) => color};
`;
