import {
  eventsRoutes,
  generalRoutes,
  globalApi,
} from "../../../redux/rtk-query";
import store from "../../../redux/store";
import { GOOGLE_API_KEY } from "../../../shared/components/map/Map";
import { handleServiceRequest } from "../../../shared/helpers";
import { User } from "../../../shared/types";
import { PaginateRes } from "../../../shared/types/paginate";
import { Event, Report } from "../models/interfaces";
import { EventsQueryFilters } from "./requests.interfaces";
import {
  GetAutocompleteResponse,
  Location,
  PlaceDetails,
} from "./responses.interfaces";

const eventsServices = globalApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlaceAutocomplete: builder.query<
      GetAutocompleteResponse["predictions"],
      string
    >({
      query: (input) => ({
        url: generalRoutes.getAutocompleteRT(),
        params: { input },
      }),
      transformResponse: (response: { data: GetAutocompleteResponse }) =>
        response.data.predictions,
    }),
    getPlaceDetails: builder.query<Location, string>({
      query: (placeId) => ({
        url: generalRoutes.getPlaceDetailsRT(),
        params: { placeId },
      }),
      transformResponse: (response: { data: PlaceDetails }) =>
        response.data.result.geometry.location,
    }),
    getSessionReports: builder.query<PaginateRes<Report[]>, EventsQueryFilters>(
      {
        query: (params) => {
          const user = store.getState().auth.user as User;
          // const url =
          //   user.role_name === "Gerente"
          //     ? eventsRoutes.getManagerReportsRT()
          //     : user.role_name === "Empresa"
          //     ? eventsRoutes.getCompanyReportsRT(user.company_id)
          //     : user.role_name === "Cliente"
          //     ? eventsRoutes.getClientReportsRT(user.client_id)
          //     : user.role_name === "Operador"
          //     ? eventsRoutes.getOperatorReportsRT(user.operator_id)
          //     : user.role_name === "Proveedor"
          //     ? eventsRoutes.getProviderReportsRT(user.supplier_id)
          //     : "";
          const url = eventsRoutes.getAllReportsRT();
          return {
            url,
            params,
          };
        },
        onQueryStarted: (_, { queryFulfilled }) => {
          handleServiceRequest(
            queryFulfilled,
            "Ha ocurrido un error al obtener los reportes"
          );
        },
        transformResponse: (response: { data: PaginateRes<Report[]> }) =>
          response.data,
        providesTags: ["reports"],
      }
    ),
    getSessionHistories: builder.query<
      PaginateRes<Report[]>,
      EventsQueryFilters
    >({
      query: (params) => {
        const user = store.getState().auth.user as User;
        const url =
          user.role_name === "Gerente"
            ? eventsRoutes.getManagerHistoriesRT()
            : user.role_name === "Empresa"
            ? eventsRoutes.getCompanyHistoriesRT(user.company_id)
            : user.role_name === "Cliente"
            ? eventsRoutes.getClientHistoriesRT(user.client_id)
            : user.role_name === "Operador"
            ? eventsRoutes.getOperatorHistoriesRT(user.operator_id)
            : user.role_name === "Proveedor"
            ? eventsRoutes.getProviderHistoriesRT(user.supplier_id)
            : "";
        return {
          url,
          params,
        };
      },
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Ha ocurrido un error al obtener el historial"
        );
      },
      transformResponse: (response: { data: PaginateRes<Report[]> }) =>
        response.data,
      providesTags: ["histories"],
    }),
    getEventDetails: builder.query<Event, string>({
      query: (eventId) => eventsRoutes.getEventDetailsRT(eventId),
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Ha ocurrido un error al obtener los detalles del evento"
        );
      },
      transformResponse: (response: { data: Event }) => response.data,
    }),
  }),
});

export const {
  useGetSessionReportsQuery: useGetReports,
  useGetSessionHistoriesQuery: useGetHistories,
  useGetEventDetailsQuery: useGetEventDetails,
  useGetPlaceDetailsQuery: useGetPlaceDetails,
  useGetPlaceAutocompleteQuery: useGetPlaceAutocomplete,
} = eventsServices;

export const getAddressFromLatLng = async (lat: string, lng: string) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
  );
  const data = await response.json();
  return data.results[1]?.formatted_address;
};
