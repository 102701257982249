/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useAvailabilitySocket } from "../../../modules/auth/hooks";
import { selectUser } from "../../../modules/auth/redux";
import { useUploadProfilePhoto } from "../../../modules/profile/services";
import {
  selectServiceText,
  selectStatusColor,
  selectStatusText,
} from "../../../redux/reducers/ui-reducer";
import { useToggle } from "../../hooks";
import { If } from "../../utilities/If";
import { ProfileImage } from "../ui/profile-image";
import { NavbarCalls } from "./navbar-calls/NavbarCalls";
import { NavbarOptions } from "./navbar-options/NavbarOptions";
import * as S from "./Navbar.styles";

export const imgUrl = "/assets/profile_picture.png";

const NavbarBase = () => {
  const user = useSelector(selectUser);
  const statusText = useSelector(selectStatusText);
  const serviceText = useSelector(selectServiceText);
  const statusColor = useSelector(selectStatusColor);
  const [isOpen, toggle, setIsOpen] = useToggle(false);

  const { setAvailability } = useAvailabilitySocket(user?.user_id);

  const { isLoading } = useUploadProfilePhoto({
    fixedCacheKey: "profile-photo",
  })[1];

  const picture = useMemo(
    () =>
      user?.user_photography
        ? `${user.user_photography}?${new Date().getTime()}`
        : imgUrl,
    [user?.user_photography, isLoading]
  );

  return (
    <S.NavbarContainer>
      <If showIf={!!statusText}>
        <S.NavbarStatus bgColor={statusColor}>
          {`${serviceText ? `Servicio de ${serviceText}` : statusText}`}
        </S.NavbarStatus>
      </If>
      <If showIf={!statusText}>
        <S.NavbarStatus bgColor="transparent" />
      </If>
      <S.NavBarItemsContainer>
        <If showIf={user?.role_name === "Operador"}>
          <NavbarCalls />
        </If>
        <If showIf={!isOpen}>
          <ProfileImage
            alt="profile"
            width="2.8rem"
            height="2.8rem"
            onClick={toggle}
            src={picture}
            isLoading={isLoading}
          />
        </If>
        <If showIf={isOpen}>
          <ProfileImage
            alt="profile"
            width="2.8rem"
            height="2.8rem"
            src={picture}
          />
        </If>
      </S.NavBarItemsContainer>
      <NavbarOptions
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setAvailability={setAvailability}
      />
    </S.NavbarContainer>
  );
};

export const Navbar = React.memo(NavbarBase);
