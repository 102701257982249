import React, { useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AffiliateInfoFormWrapper } from "./AffiliateInfoForm.styles";
import {
  UpdateAffiliateFormProps,
  UpdateAffiliateFormShape,
} from "../../schemas";
import { MyInput } from "../../../../shared/components/inputs";
import { useAffiliateDetailsCtx } from "../../context/affiliate-details-ctx";
import {
  getDateWithInputFormat,
  getNumberCountryCode,
} from "../../../../shared/helpers";
import { If } from "../../../../shared/utilities/If";
import { PhoneInput } from "../../../../shared/components/inputs/PhoneInput";

type Key = keyof UpdateAffiliateFormProps;

export const AffiliateInfoForm = React.forwardRef(function AffiliateInfoForm(
  _,
  ref
) {
  const { affiliate, isEditMode } = useAffiliateDetailsCtx();

  const methods = useForm<UpdateAffiliateFormProps>({
    resolver: yupResolver(UpdateAffiliateFormShape),
    mode: "onChange",
    defaultValues: {
      email: affiliate?.user_email,
      rfc: affiliate?.affiliate_rfc,
      curp: affiliate?.affiliate_curp,
      full_phone: affiliate?.user_phone,
      fullname: affiliate?.user_fullname,
      surname: affiliate?.user_surname ? affiliate?.user_surname : undefined,
      complete_name: `${affiliate?.user_fullname} ${affiliate?.user_surname}`,
      phone:
        affiliate?.user_phone &&
        getNumberCountryCode(affiliate.user_phone).phone,
      country_code:
        affiliate?.user_phone &&
        getNumberCountryCode(affiliate.user_phone).code,
      birthday: (affiliate?.affiliate_birthday &&
        getDateWithInputFormat(affiliate.affiliate_birthday)) as any,
    },
  });

  useImperativeHandle(ref, () => ({
    validateForm: async () => {
      const isValid = await methods.trigger();
      const values = methods.getValues();
      return { isValid, values };
    },
  }));

  useEffect(() => {
    isEditMode || methods.reset();
  }, [isEditMode, methods]);

  return (
    <AffiliateInfoFormWrapper methods={methods} isEditMode={isEditMode}>
      <If showIf={!isEditMode}>
        <MyInput<Key> name="complete_name" label="Nombre completo" readOnly />
        <MyInput<Key>
          name="full_phone"
          label="Teléfono"
          placeholder="Ej: +523012345678"
          readOnly={!isEditMode}
          autoFocus
        />
      </If>
      <If showIf={isEditMode}>
        <MyInput<Key>
          name="fullname"
          label="Nombres"
          readOnly={!isEditMode}
          autoFocus
        />
        <MyInput<Key>
          name="surname"
          label="Apellidos"
          readOnly={!isEditMode}
          autoFocus
        />
        <PhoneInput<Key>
          autoFocus
          gap="1.5rem"
          name="phone"
          label="Teléfono"
          auto_focus_select
          auto_select={false}
          className="phone-input"
          readOnly={!isEditMode}
        />
      </If>
      <MyInput<Key>
        name="email"
        label="Correo electrónico"
        readOnly={!isEditMode}
        autoFocus
      />
      <MyInput<Key>
        name="birthday"
        label="Fecha de nacimiento"
        type="date"
        readOnly={!isEditMode}
        autoFocus
      />
      <If showIf={isEditMode}>
        <MyInput<Key> name="rfc" label="RFC" readOnly={!isEditMode} autoFocus />
        <MyInput<Key>
          name="curp"
          label="CURP"
          readOnly={!isEditMode}
          autoFocus
        />
      </If>
    </AffiliateInfoFormWrapper>
  );
});
