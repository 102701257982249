import { Marker } from "@react-google-maps/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ambulanceImg, securityImg } from "../../../../assets/images";
import { CoverageSelector } from "../../../../shared/components/coverage-selector";
import { PageContainer, PageTitle } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { Operator } from "../../../../shared/types";
import { selectUser } from "../../../auth/redux";
import { useEventsSocketCtx } from "../../../events/context/events-socket-ctx";
import { ProviderCoverageMap } from "../../../providers/components/provider-coverage-map";
import { useGetCompanyPlans } from "../../../users/services";
import { OperatorStatsList } from "../../components/operator-stats";
import {
  useGetOperatorCoverages,
  useGetOperatorScore,
  useGetOperatorStats,
} from "../../services";
import { useGetClientSubscriptions } from "../../../admin-client/services";

export const OperatorHomePage = () => {
  const [map, setMap] = useState<google.maps.Map>();
  const operator = useSelector(selectUser) as Operator;

  const { data: companyPlans = [] } = useGetCompanyPlans(
    operator.operator_companyId ?? skipToken
  );
  const { data: clientPlans = [] } = useGetClientSubscriptions(
    operator.operator_clientId ?? skipToken
  );
  const { data: coverages = [], isLoading: isGettingCoverages } =
    useGetOperatorCoverages();
  const { data: stats, isLoading: isGettingStats } = useGetOperatorStats(
    operator.operator_id
  );
  const { data: score, isLoading: isGettingScore } = useGetOperatorScore(
    operator.operator_id
  );
  const { leaveVehiclesPositions, requestVehiclesPositions, vehiclePositions } =
    useEventsSocketCtx();

  const vehiclesToShow = useMemo(
    () => vehiclePositions ?? [],
    [vehiclePositions]
  );

  const onMapLoaded = useCallback((mapLoaded: google.maps.Map) => {
    setMap(mapLoaded);
  }, []);

  useEffect(() => {
    requestVehiclesPositions();
    return () => leaveVehiclesPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <PageTitle>Inicio</PageTitle>
      <OperatorStatsList
        showScore
        score={score}
        operatorEvents={stats}
        isLoading={isGettingStats || isGettingScore}
      />
      <GlobalContentBox
        height="100%"
        title="Coberturas"
        isLoading={isGettingCoverages}
        contentStyle={{
          padding: ".5rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <CoverageSelector coverages={coverages} map={map} />
        <ProviderCoverageMap
          type="js"
          zoom={10}
          coverages={coverages}
          onMapLoaded={onMapLoaded}
        >
          {(vehiclesToShow as any[])?.map(({ lat, lng, type }) => (
            <Marker
              icon={type === "AMBULANCE" ? ambulanceImg : securityImg}
              key={`${lat}-${lng}`}
              position={{ lat: +lat, lng: +lng }}
            />
          ))}
        </ProviderCoverageMap>
      </GlobalContentBox>
    </PageContainer>
  );
};
