import React from "react";
import { ModalProps, MyModal } from "../../my-modal";
import { CloseModal } from "../close-modal";
import { MyLoader } from "../Loader";
import { BaseModalWrapper } from "./BaseModal.styles";

export interface BaseModalProps extends ModalProps {
  title?: string;

  width?: string;
  height?: string;

  children: React.ReactNode;

  /**
   * If true, the modal won't be closed when the user clicks outside of it
   */
  onlyCloseOnButton?: boolean;

  /**
   * If true, the modal will show a loading spinner
   * while the data is being fetched
   */
  isLoading?: boolean;
}

export const BaseModal = ({
  children,
  title,
  width,
  onlyCloseOnButton,
  isLoading,
  height,
  ...props
}: BaseModalProps) => {
  const onClose = onlyCloseOnButton ? () => {} : props.onRequestClose;

  const loader = (
    <BaseModalWrapper width="20rem" height="20rem">
      <MyLoader />
    </BaseModalWrapper>
  );

  const content = (
    <BaseModalWrapper width={width} height={height}>
      <CloseModal onClick={props.onRequestClose} />
      {title && <h2>{title}</h2>}
      {children}
    </BaseModalWrapper>
  );

  return (
    <MyModal {...props} onRequestClose={onClose}>
      {isLoading ? loader : content}
    </MyModal>
  );
};
