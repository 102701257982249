import styled from "styled-components";
import { Form } from "../../../../../shared/components/form";

export const CreateClientAffiliateForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  .buttons-container {
    grid-column: 1 / -1;
    button {
      width: 100%;
    }
  }
`;
