import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { PageContainer, PageTitle } from "../../../../shared/components/styled";
import { useGetAdminClientAffiliates } from "../../services";
import { selectUser } from "../../../auth/redux";
import { Client } from "../../../../shared/types";
import { AdminClientAffiliatesTable } from "../../components/admin-client-affiliates-table";

export const AdminClientUsersPage = () => {
  const client = useSelector(selectUser) as Client;
  const { data = [], isLoading } = useGetAdminClientAffiliates(
    client?.client_id ?? skipToken
  );

  return (
    <PageContainer>
      <PageTitle>Usuarios Afiliados</PageTitle>
      <AdminClientAffiliatesTable affiliates={data} isLoading={isLoading} />
    </PageContainer>
  );
};
