import React, { useState } from "react";
import { MdLocalPhone } from "react-icons/md";
import { CallingListModal } from "../../../../modules/operators/components/modals/calling-list-modal";
import { TicketInCallModal } from "../../../../modules/operators/components/modals/ticket-in-call-modal";
import { useOperatorCallList } from "../../../../modules/operators/hooks";
import { CallInList } from "../../../../modules/operators/models";
import { useCallingService, useToggle } from "../../../hooks";
import * as S from "./NavbarCalls.styles";

export const NavbarCalls = () => {
  const [activeTicket, setActiveTicket] = useState<CallInList>();
  const [isListModalOpen, toggleListModalOpen] = useToggle();
  const [isTicketModalOpen, toggleTicketModalOpen] = useToggle();

  const {
    numberOfTickets,
    isLoading,
    pendingTickets = [],
    attendTicket,
  } = useOperatorCallList();
  const {
    onCall,
    onHangUp,
    restartValues,
    isCalling,
    isGettingCallInfo,
    userAnswered,
  } = useCallingService();

  const onCallTicket = (ticket: CallInList) => {
    setActiveTicket(ticket);
    toggleListModalOpen();
    toggleTicketModalOpen();
    onCall(ticket.cellphone);
  };

  const afterTicketModalClose = () => {
    if (userAnswered && activeTicket) {
      attendTicket(activeTicket.number);
    }
    toggleListModalOpen();
    restartValues();
    setActiveTicket(undefined);
  };

  const onRequestTicketModalClose = () => {
    if (isCalling) return;
    toggleTicketModalOpen();
  };

  return (
    <>
      <S.NavbarCallsContainer onClick={toggleListModalOpen}>
        <MdLocalPhone />
        <S.NumberOfTickets>{numberOfTickets ?? "?"}</S.NumberOfTickets>
      </S.NavbarCallsContainer>
      <CallingListModal
        isLoading={isLoading}
        isOpen={isListModalOpen}
        onCallTicket={onCallTicket}
        callTickets={pendingTickets}
        onRequestClose={toggleListModalOpen}
      />
      <TicketInCallModal
        isCalling={isCalling}
        activeTicket={activeTicket}
        isOpen={isTicketModalOpen}
        isGettingCallInfo={isGettingCallInfo}
        onCall={onCall}
        onHangUp={onHangUp}
        afterClose={afterTicketModalClose}
        onRequestClose={onRequestTicketModalClose}
      />
    </>
  );
};
