import { Marker } from "@react-google-maps/api";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ambulanceImg, securityImg } from "../../../../assets/images";
import { CoverageSelector } from "../../../../shared/components/coverage-selector";
import { Map } from "../../../../shared/components/map/Map";
import { PageContainer, PageTitle } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { Supplier } from "../../../../shared/types";
import { selectUser } from "../../../auth/redux";
import { useEventsSocketCtx } from "../../../events/context/events-socket-ctx";
import { VehiclePosition } from "../../../operators/models";
import { CoverageItem } from "../../components/coverage-circles/CoverageItem";
import { useGetProviderCoverage, useGetVehicles } from "../../services";

const contentStyle = {
  padding: ".5rem",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
} as React.CSSProperties;

export const ProviderActiveVehiclesPage = () => {
  const user = useSelector(selectUser) as Supplier;
  const [map, setMap] = useState<google.maps.Map>();

  const { data: coverages = [], isLoading: isLoadingCoverages } =
    useGetProviderCoverage();

  const { data: supplierVehicles = [], isLoading: isLoadingVehicles } =
    useGetVehicles(user?.supplier_id);

  const { leaveVehiclesPositions, requestVehiclesPositions, vehiclePositions } =
    useEventsSocketCtx();

  const customCenter = useMemo(() => {
    const lat = coverages[0]?.coverage_lat;
    const lng = coverages[0]?.coverage_lng;
    return lat && lng ? { lat: +lat, lng: +lng } : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverages.length]);

  const vehiclesToShow = useMemo<VehiclePosition[]>(() => {
    const supplierVehiclesIds = supplierVehicles.map((v) => v.vehicle_id);
    return (
      vehiclePositions?.filter((v) =>
        supplierVehiclesIds.includes(v.vehicleId)
      ) || []
    );
  }, [supplierVehicles, vehiclePositions]);

  const onMapLoaded = (mapLoaded: google.maps.Map) => setMap(mapLoaded);

  useEffect(() => {
    requestVehiclesPositions();
    return () => leaveVehiclesPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO: delete this when is validated that is working
    // eslint-disable-next-line no-console
    console.log("provider vehicles", supplierVehicles);
    // eslint-disable-next-line no-console
    console.log("provider vehicles to show", vehiclesToShow);
  }, [vehiclesToShow, supplierVehicles]);

  return (
    <PageContainer>
      <PageTitle>Vehículos activos</PageTitle>
      <GlobalContentBox
        isLoading={isLoadingCoverages || isLoadingVehicles}
        style={{ height: "100%" }}
        contentStyle={contentStyle}
      >
        <CoverageSelector coverages={coverages} map={map} />
        <Map
          zoom={10}
          height="100%"
          center={customCenter}
          onLoaded={onMapLoaded}
        >
          {coverages.map((coverage) => (
            <CoverageItem coverage={coverage} key={coverage.coverage_id} />
          ))}
          {vehiclesToShow?.map(({ lat, lng, type }) => (
            <Marker
              icon={type === "AMBULANCE" ? ambulanceImg : securityImg}
              key={`${lat}-${lng}`}
              position={{ lat: +lat, lng: +lng }}
            />
          ))}
        </Map>
      </GlobalContentBox>
    </PageContainer>
  );
};
