import { useMyNavigate } from "../../../../shared/hooks";
import { getEventTypeInfo } from "../../../events/helpers";
import { ActiveEvent } from "../../models";
import * as S from "./OperatorActiveEvents.styles";

interface Props {
  event: ActiveEvent;
}

export const ActiveEvensItem = ({ event }: Props) => {
  const navigate = useMyNavigate();

  const { Icon } = getEventTypeInfo(event);
  const { color } = getEventTypeInfo(event);
  const { text } = getEventTypeInfo(event);

  const handleClick = () => {
    navigate({ name: "Detalles del evento", params: { eventId: event._id } });
  };

  return (
    <S.ActiveEventItem onClick={handleClick}>
      <S.IconContainer color={color}>
        <Icon />
      </S.IconContainer>
      <S.InfoContainer>
        <span className="ticket-description">
          {event.ticket.slice(0, 10).toUpperCase()} {event.affiliate_fullname}{" "}
          {event.affiliate_surname}
        </span>
        <span>{text}</span>
      </S.InfoContainer>
    </S.ActiveEventItem>
  );
};
