import styled from "styled-components";

export const img = styled.img`
  width: 30%;
  min-width: 180px;
  object-fit: cover;
  border-radius: 1rem;
  margin-bottom: 2rem;
`;

export const container = styled.div`
  flex: 1;
  background-color: #fff;
  padding: 3rem;
  width: 50%;
  margin: 2rem 0;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 94%;
    padding: 1rem;
  }
`;

export const title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 1rem;
`;

export const containerSteps = styled.div`
  margin: 2rem 0;
`;

export const desc = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 1rem;
`;

export const subtitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 1rem;
`;

export const listDecimal = styled.ol`
  list-style-type: decimal;
  margin-left: 2rem;
`;

export const item = styled.li`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 1rem;
`;
