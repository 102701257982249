import styled from "styled-components";

interface Props {
  height?: string;
}

export const GlobalContentBoxWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: ${({ height }) => height};
`;

export const GlobalContentBoxTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray.primary};
`;

export const GlobalContentBoxContent = styled.div<{ isLoading?: boolean }>`
  padding: 1rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;

  ${({ isLoading }) =>
    isLoading &&
    `
        display: flex;
        justify-content: center;
        align-items: center;
    `}
`;
