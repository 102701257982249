import { Provider } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastOptions } from "react-toastify/dist/types";
import { ThemeProvider } from "styled-components";
import { EventsSocketProvider } from "./modules/events/context/events-socket-ctx";
import store from "./redux/store";
import { AppRouter } from "./router";
import { GlobalStyle } from "./shared/styles";
import theme from "./shared/theme";
import { NotificationSoundContainer } from "./shared/components/ui/notification/Notifications";

const toastStyle: React.CSSProperties = {
  borderRadius: "1rem",
  fontSize: ".8rem",
};

const toastOptions: ToastOptions = {
  position: "bottom-center",
  autoClose: 3000,
  theme: "dark",
  style: toastStyle,
  transition: Slide,
  hideProgressBar: true,
};

const App = () => {
  return (
    <Provider store={store}>
      <EventsSocketProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <AppRouter />
          <ToastContainer {...toastOptions} />
          <NotificationSoundContainer />
        </ThemeProvider>
      </EventsSocketProvider>
    </Provider>
  );
};

export default App;

declare global {
  interface Window {
    _env_: any;
  }
}

declare global {
  interface Window {
    _env_: any;
  }
}

declare global {
    interface Window {
        _env_:any;
    }
}
