import { AiOutlineAlert } from "react-icons/ai";
import { CgFileDocument } from "react-icons/cg";
import { IoNewspaperOutline } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { UserRole } from "../../../shared/enums";
import { CreateEventPage } from "../pages/create-event";
import { DashboardPage } from "../pages/dashboard";
import { EventDetailsPage } from "../pages/event-details/EventDetailsPage";
import { ReportsListPage } from "../pages/reports-list";

const EventsRoutes = [
  {
    path: "/create-event",
    name: "Crear evento",
    showInMenu: true,
    Icon: AiOutlineAlert,
    Component: () => <CreateEventPage />,
    rolesAllowed: ["Operador"] as UserRole[],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    Icon: RxDashboard,
    showInMenu: true,
    Component: () => <DashboardPage />,
    rolesAllowed: ["Operador", "Gerente"] as UserRole[],
  },
  {
    path: "/event-details/:eventId",
    name: "Detalles del evento",
    Component: () => <EventDetailsPage />,
    rolesAllowed: ["Operador"] as UserRole[],
  },
  {
    path: "/historic",
    name: "Historial",
    showInMenu: true,
    Icon: IoNewspaperOutline,
    Component: () => <ReportsListPage pageType="historic" />,
    rolesAllowed: [
      "Gerente",
      "Operador",
      "Proveedor",
      "Cliente",
      "Empresa",
    ] as UserRole[],
  },
  {
    path: "/reports",
    name: "Reportes",
    showInMenu: true,
    Icon: CgFileDocument,
    Component: () => <ReportsListPage pageType="reports" />,
    rolesAllowed: [
      "Gerente",
      "Operador",
      "Proveedor",
      "Cliente",
      "Empresa",
    ] as UserRole[],
  },
] as const;

export { EventsRoutes };
