import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";
import { useClickOutside } from "react-haiku";
import { setIsUpdatePasswordModal } from "../../../../redux/reducers/ui-reducer";
import { useGetUserScore } from "../../../../redux/rtk-query";
import { useAppDispatch } from "../../../../redux/store";
import { ActionsCellBox } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { UserDetailsCard } from "../../../../shared/components/user-info/user-details-card";
import { useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { If } from "../../../../shared/utilities/If";
import { OpenOptionsButton } from "../../../users/components/client-details-card/ClientDetailsCard.styles";
import { useMyProfileCtx } from "../../context/my-profile-ctx";
import { useUploadProfilePhoto } from "../../services";
import { ProfileInfoForm } from "../profile-info-form";
import { UpdatePasswordModal } from "../update-password";

export const ProfileDetailsCard = () => {
  const dispatch = useAppDispatch();

  const actionsRef = React.useRef<HTMLDivElement>(null);
  const [optionsOpen, toggleOptions, setOptionsOpen] = useToggle();

  const { toggleEditMode, profile, formRef } = useMyProfileCtx();

  const { data: score } = useGetUserScore(profile || skipToken);
  const [uploadPhoto, { isLoading }] = useUploadProfilePhoto({
    fixedCacheKey: "profile-photo",
  });

  const picture = useMemo(
    () =>
      profile?.user_photography
        ? `${profile.user_photography}?${new Date().getTime()}`
        : null,
    [profile?.user_photography]
  );

  useClickOutside(actionsRef, () => setOptionsOpen(false));

  const onChangePhoto = React.useCallback(
    async (file: File) => {
      await uploadPhoto({ file }).unwrap();
      // window.location.reload()
    },
    [uploadPhoto]
  );

  const onOpenModal = () => {
    toggleOptions();
    dispatch(setIsUpdatePasswordModal(true));
  };

  return (
    <GlobalContentBox style={{ position: "relative" }}>
      <UserDetailsCard
        score={score}
        canUpdatePhoto
        editTitle="Editar perfil"
        onChangePhoto={onChangePhoto}
        toggleEditMode={toggleEditMode}
        isLoadingImg={isLoading}
        profilePhoto={picture}
      />
      <ProfileInfoForm ref={formRef} />
      <If showIf={!optionsOpen}>
        <OpenOptionsButton
          extraStyles={ActionsCellStyles}
          onClick={toggleOptions}
        >
          <div />
          <div />
          <div />
        </OpenOptionsButton>
      </If>
      <If showIf={optionsOpen}>
        <OpenOptionsButton extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </OpenOptionsButton>
      </If>
      <If showIf={optionsOpen}>
        <ActionsCellBox ref={actionsRef} top="2rem" right="1.5rem">
          <div onClick={onOpenModal}>Editar contraseña</div>
        </ActionsCellBox>
      </If>
      <UpdatePasswordModal />
    </GlobalContentBox>
  );
};
