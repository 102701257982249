import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  selectIsCreateClientModal,
  setIsCreateClientModal,
} from "../../../../../redux/reducers/ui-reducer";
import { selectServices } from "../../../../../redux/rtk-query";
import { useAppDispatch } from "../../../../../redux/store";
import { MyInput } from "../../../../../shared/components/inputs";
import { PhoneInput } from "../../../../../shared/components/inputs/PhoneInput";
import { MyModal } from "../../../../../shared/components/my-modal";
import { MySelect } from "../../../../../shared/components/my-select";
import { Button } from "../../../../../shared/components/ui/button";
import { CloseModal } from "../../../../../shared/components/ui/close-modal";
import { selectUser } from "../../../../auth/redux";
import { NewClientFormProps, NewClientFormShape } from "../../../schemas";
import { useCreateClient } from "../../../services";
import * as S from "./CreateClientModal.styles";

type Keys = keyof NewClientFormProps;

const excessOptions = [
  { value: true, label: "Si" },
  { value: false, label: "No" },
];

export const CreateClientModal = () => {
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const isOpen = useSelector(selectIsCreateClientModal);
  const { data: services = [] } = useSelector(selectServices);

  const [createClient, { isLoading: isCreating }] = useCreateClient();

  const methods = useForm<NewClientFormProps>({
    resolver: yupResolver(NewClientFormShape),
    defaultValues: {
      client_services: [{ serviceId: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "client_services" as never,
  });

  const handleClose = () => {
    dispatch(setIsCreateClientModal(false));
  };

  const onSubmit = (data: NewClientFormProps) => {
    if (user?.role_name !== "Gerente") return;
    const phone = `${data.country_code}${data.client_phone}`;
    createClient({
      manager_id: user.manager_id,
      services: data.client_services || [],
      ...(data.client_rfc !== "" ? { rfc: data.client_rfc } : {}),
      user: {
        phone,
        email: data.client_email,
        fullname: data.client_fullname,
        password: data.client_password,
      },
    })
      .unwrap()
      .then(handleClose);
  };

  return (
    <MyModal isOpen={isOpen} onRequestClose={handleClose}>
      <S.CreateClientFormWrapper
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <CloseModal onClick={handleClose} />
        <h2>Crear Empresa</h2>
        <div className="inputs-container">
          <MyInput<Keys> name="client_fullname" label="Nombre completo" />
          <MyInput<Keys> name="client_email" label="Correo electrónico" />
          <PhoneInput<Keys> name="client_phone" label="Teléfono" />
          <MyInput<Keys> name="client_rfc" label="RFC" />
          <MyInput<Keys>
            name="client_password"
            label="Contraseña"
            type="password"
          />
          <MyInput<Keys>
            name="client_confirmPassword"
            label="Confirmar contraseña"
            type="password"
          />
          {fields.map((field, index) => (
            <div key={field.id} className="service-inputs">
              <MySelect
                get="service_id"
                values={services}
                label="Tipo de servicio"
                placeholder="Seleccione un servicio"
                name={`client_services.${index}.serviceId`}
                show={["service_name", "service_description"]}
              />
              <MyInput
                name={`client_services.${index}.service_quantity` as never}
                label="Cantidad"
              />
              <MyInput
                name={`client_services.${index}.service_value` as never}
                label="Valor"
              />
              <MySelect
                name={`client_services.${index}.pay_excess_consumption`}
                label="Paga excedente"
                values={excessOptions}
                get="value"
                show="label"
                placeholder=""
              />
              <FaTimes
                onClick={() => {
                  index > 0 && remove(index);
                }}
              />
            </div>
          ))}
        </div>
        <div className="buttons-container">
          <Button
            xl
            secondary
            type="button"
            onClick={handleClose}
            disabled={isCreating}
          >
            Cancelar
          </Button>
          <Button
            xl
            onClick={() => append({})}
            type="button"
            disabled={isCreating}
          >
            Nuevo servicio
          </Button>
          <Button xl loading={isCreating}>
            Crear
          </Button>
        </div>
      </S.CreateClientFormWrapper>
    </MyModal>
  );
};
