import { FaMapMarkedAlt } from "react-icons/fa";
import { UserRole } from "../../../shared/enums";
import { ManagerCoveragesPage } from "../pages/manager-coverages";

const ManagerRoutes = [
  {
    path: "/manager-coverages",
    name: "Coberturas",
    Component: () => <ManagerCoveragesPage />,
    showInMenu: true,
    Icon: FaMapMarkedAlt,
    rolesAllowed: ["Gerente"] as UserRole[],
  },
] as const;

export { ManagerRoutes };
