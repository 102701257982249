import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectIsCreateOperatorModal,
  setIsCreateOperatorModal,
} from "../../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { MyInput } from "../../../../../shared/components/inputs";
import { PhoneInput } from "../../../../../shared/components/inputs/PhoneInput";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { Client, Company } from "../../../../../shared/types";
import { selectUser } from "../../../../auth/redux";
import {
  CreateOperatorFormProps,
  CreateOperatorFormShape,
} from "../../../schemas";
import { useCreateOperator } from "../../../services";
import { CreateOperatorBody } from "../../../services/requests.interfaces";
import * as S from "./CreateOperatorModal.styles";

type Keys = keyof CreateOperatorFormProps;

export const CreateOperatorModal = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser) as Company | Client;
  const isOpen = useSelector(selectIsCreateOperatorModal);

  const [createOperator, { isLoading }] = useCreateOperator();
  const methods = useForm<CreateOperatorFormProps>({
    resolver: yupResolver(CreateOperatorFormShape),
  });

  const onClose = () => {
    dispatch(setIsCreateOperatorModal(false));
  };

  const onSubmit = (data: CreateOperatorFormProps) => {
    const birthday = data.operator_birthday.toISOString();
    const phone = `${data.country_code}${data.operator_phone}`;
    const entity_id =
      user.role_name === "Empresa" ? user.company_id : user.client_id;
    const body: CreateOperatorBody = {
      birthday,
      entity_id,
      role_name: user.role_name,
      ...(data.operator_rfc !== "" ? { rfc: data.operator_rfc } : {}),
      ...(data.operator_curp !== "" ? { rfc: data.operator_curp } : {}),
      user: {
        phone,
        email: data.operator_email,
        surname: data.operator_surname,
        fullname: data.operator_fullname,
        password: data.operator_password,
      },
    };
    createOperator(body).unwrap().then(onClose);
  };

  const afterClose = () => {
    methods.reset();
  };

  return (
    <BaseModal
      width="40rem"
      isOpen={isOpen}
      title="Crear Operador"
      onRequestClose={onClose}
      onAfterClose={afterClose}
    >
      <S.CreateOperatorFormWrapper
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MyInput<Keys> name="operator_fullname" label="Nombres" />
        <MyInput<Keys> name="operator_surname" label="Apellidos" />
        <MyInput<Keys> name="operator_email" label="Correo Electrónico" />
        <PhoneInput<Keys> name="operator_phone" label="Teléfono" />
        <MyInput<Keys> name="operator_rfc" label="RFC" />
        <MyInput<Keys> name="operator_curp" label="CURP" />
        <MyInput<Keys>
          name="operator_birthday"
          label="Fecha de Nacimiento"
          type="date"
        />
        <MyInput<Keys>
          name="operator_password"
          label="Contraseña"
          type="password"
        />
        <MyInput<Keys>
          name="operator_confirmPassword"
          label="Confirmar Contraseña"
          type="password"
        />
        <PageButtons
          show
          isLoading={isLoading}
          proceedText="Crear operador"
          className="buttons-container"
        />
      </S.CreateOperatorFormWrapper>
    </BaseModal>
  );
};
