import { Button } from "../button";
import * as S from "./PageButtons.styles";

interface Props {
  xl?: boolean;
  show?: boolean;
  animate?: boolean;
  className?: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  cancelText?: string;
  cancelType?: string;
  proceedText: string;
  proceedType?: string;
  cancelBgColor?: string;
  proceedBgColor?: string;

  onCancel?: () => void;
  onProceed?: () => void;
}

export const PageButtons = ({
  xl,
  show,
  animate,
  className,
  isLoading,
  fullWidth,
  cancelText,
  cancelType,
  proceedText,
  proceedType,
  cancelBgColor,
  proceedBgColor,
  onCancel,
  onProceed,
}: Props) => {
  if (!show) return null;

  return (
    <S.PageButtonsWrapper
      fullWidth={fullWidth}
      className={`page-buttons ${className}`}
    >
      <Button
        secondary
        animate={animate}
        onClick={onCancel}
        className="cancel-button"
        disabled={isLoading}
        bgColor={cancelBgColor}
        type={cancelType || "button"}
        xl={xl === undefined ? true : xl}
      >
        {cancelText || "Cancelar"}
      </Button>
      <Button
        animate={animate}
        onClick={onProceed}
        loading={isLoading}
        className="proceed-button"
        bgColor={proceedBgColor}
        type={proceedType || "submit"}
        xl={xl === undefined ? true : xl}
      >
        {proceedText}
      </Button>
    </S.PageButtonsWrapper>
  );
};
